import { render, staticRenderFns } from "./Allcontracts.vue?vue&type=template&id=6cff1098&scoped=true"
import script from "./Allcontracts.vue?vue&type=script&lang=js"
export * from "./Allcontracts.vue?vue&type=script&lang=js"
import style0 from "./Allcontracts.vue?vue&type=style&index=0&id=6cff1098&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cff1098",
  null
  
)

export default component.exports