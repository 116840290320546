import { render, staticRenderFns } from "./downLoadApp.vue?vue&type=template&id=2d99185c&scoped=true"
import script from "./downLoadApp.vue?vue&type=script&lang=js"
export * from "./downLoadApp.vue?vue&type=script&lang=js"
import style0 from "./downLoadApp.vue?vue&type=style&index=0&id=2d99185c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d99185c",
  null
  
)

export default component.exports