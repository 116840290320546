<template>
  <div>
    <NavigateBar :title="'我的测评'" @handleBack="goOff" />
    <div class="PingTestItem" v-for="(item,index) in classComment" :key="index">
      <div class="pingTestTopBox">
        <div class="pingTestTitle">{{item.name}}</div>
        <div class="" style="color:#9697a5;"> {{item.modify_time}}</div>
      </div>
      <div class="pingTestContent">
        {{item.comment}}
      </div>
    </div>
  </div>
</template>

<script>
import NavigateBar from "../myHomework/navigateBar.vue";
export default {
    data(){
        return {
            classComment:[]
        }
    },
     components: {
    NavigateBar,
  },
    created(){
       this.myClassComment()
    },
    methods:{
        goOff () {
          this.$router.go(-1)
        },
        myClassComment(){
            const data = {
 
            }
            this.$api.post('/stu/course/myClassComment', data)
            .then(res => {
            if (res.data.code === 200 && res.data.data) {
                this.classComment = res.data.data.list
            }
            })
        }
    }
};
</script>

<style scoped>
.tit {
  width: 100%;
  height: 44px;
  background-color: #ffffff;
}

.tit h1 {
  font-size: 17px;
  font-weight: 600;
  color: #101010;
  margin: 10px 150px 0;
}
.PingTestItem {
  margin:10px;
  display: block;
  border-radius: 4px;
  padding: 10px;
  background-color: #f7f8fa;
}

.pingTestTopBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pingTestTitle {
  font-size: 14px;
  font-weight: bold;
  color: #323240;
}
.pingTestContent {
  margin-top: 5px;
  font-size: 13px;
  color: #9697a5;
}
::v-deep .van-nav-bar__title{
  font-size: 17px;
  font-weight: 600;
  color: #101010;
}
::v-deep .van-nav-bar .van-icon{
  color: #323233;
}
::v-deep .van-hairline--bottom::after{
  border-bottom-width: 0px;
}
</style>