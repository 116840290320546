<template>
    <div>
        <div style="background: rgb(246, 246, 246);min-height: 812px;">
            <div class="hetongclass">
                <div style="display: flex;">
                    <van-icon class="fanhui" name="arrow-left" color="#202020" size="28" @click="fanhui" />
                    <div class="hetongguanl">合同管理</div>
                </div>
            </div>
            <div class="sousuo">
                <van-search v-model="value" shape="round" placeholder="搜索" @input="onSearch" />
            </div>
            <div style="margin-top: 3px;">
                <van-tabs @click="onClick" v-model="active" sticky class="xiaotan">
                    <van-tab v-for="(item, index) in tablist" :title="item" :key="index">
                        <div class="wenjianhezi">
                            <div class="wenjiano" v-for="(item,index) in suoyuhtlsit.contract" :key="index">
                        <router-link :to="{path:'FileDetails',query:{id:item.c_id,shuzulist: item}}">
                            <div class="hetongbh">
                                <p class="shijianbh" style="margin-top: 10px;margin-left: 10px;">合同编号：{{item.number}}</p>
                                <p class="shijianbh" style="margin-top: 10px;margin-right: 10px;">{{item.create_time}}</p>
                            </div>
                            <div class="gekaixian"></div>
                            <div class="xieyilist">
                            <p class="shanghai">{{item.name}}</p>
                            <div class="shdaita" v-if="item.status == 1">待我签</div>
                            <div class="shdaita" style="color: #2CC797;border: 0.5px solid #2CC797;" v-if="item.status == 2">已完成</div>
                            <div class="shdaita" style="color: #FF9A40;border: 0.5px solid #FF9A40;" v-if="item.status == 3">已过期</div>
                            <div class="shdaita" style="color: #FF9A40;border: 0.5px solid #FF9A40;" v-if="item.status == 4">已撤回</div>
                            <div class="shdaita" style="color: #FF5227;border: 0.5px solid #FF5227;" v-if="item.status == 5">已拒签</div>
                            </div>
                            <p class="makabaka" style="margin-top: 8px;margin-left: 10px;">发起人：{{item.real_name}}</p>
                            <p class="makabaka" style="margin-top: 8px;margin-left: 10px;">接收人：{{item.nick_name}}</p>
                        </router-link>
                    </div>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "allContracts",
    data() {
        return {
            value: '',
            tablist: ['待我签', '已完成', '全部'],
            active: 2,
            suoyuhtlsit: []
        }
    },
    created () {
        if (this.$route.query.setid === 1) {
           this.active = 0
        } else if (this.$route.query.setid === 2) {
           this.active = 1
        } else if (this.$route.query.setid === 3) {
           this.active = 2
        }
        this.contractList()
    },
    mounted() {
        setTimeout(() => {
            document.title = ''
        }, 1000)
    },
    methods: {
        contractList(){
            this.status = ''
            if(this.active == 2) {
                this.status = ''
            } else if(this.active == 1) {
                this.status = 2
            } else if(this.active == 0) {
                this.status = 1
            }
            const data = {
                // token: 'wx_35a7610c10d7b1ccaca6f560745f88e6_2_1491_22506957',
                status: this.status,
                keywords: this.value
            }
            this.$api.post('/user/app/contractList', data)
                .then(res=>{
                    if(res.data.code == 200) {
                        // console.log('333333-------', res.data.data)
                        this.suoyuhtlsit = res.data.data
                    }
                })
        },
        onClick(name, title) {
            this.contractList()
          console.log(name + 1, title)
        },
        fanhui() {
            this.$router.go(-1)
        },
        onSearch() {
            this.contractList()
        }
    }
}
</script>
<style scoped>
p {
    margin: 0;
    padding: 0;
}
.wenjianhezi {
    background: rgb(246, 246, 246);
}
.makabaka {
    font-size: 12px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
}
.shdaita {
    width: 40px;
    height: 18px;
    border: 0.5px solid #1872ed;
    border-radius: 2px;
    text-align: center;
    line-height: 18px;
    font-size: 10px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #1872ed;
    margin-top: 10px;
    margin-left: 6px;
    }
.shanghai {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #202020;
    margin-top: 10px;
    margin-left: 10px;
}
.xieyilist {
    display: flex;
}
.gekaixian {
    width: 100%;
    height: 0px;
    border: 0.6px solid #f0f4f8;
    margin-top: 10px;
}
.shijianbh {
    font-size: 10px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #999999;
}
.hetongbh {
    display: flex;
    justify-content: space-between;
}
.wenjiano {
    width: 343px;
    height: 130px;
    background: #ffffff;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 12px;
}
.xiaotan >>> .van-tabs__line {
    background-color: #1872ED;
}
.sousuo {
    width: 100%;
    height: 51px;
    background: #ffffff;
    border-top: 0.6px solid #f0f4f8;
    border-bottom: 0.6px solid #f0f4f8;
}
.hetongguanl {
    font-size: 17px;
    font-family: PingFang SC, PingFang SC-700;
    font-weight: 700;
    color: #202020;
    margin-left: 110px;
    line-height: 44px;
}
.fanhui {
    margin-left: 17px;
    line-height: 44px;
}
.hetongclass {
    width: 100%;
    height: 44px;
    background: #fff;
}
</style>