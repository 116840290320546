<!-- 素材资料配置项 -->
<template>
    <div class='material-previewer padding_top component-container' :style="cmpStyle">
        <!-- tab -->
        <van-tabs v-model:active="tabActive" :background="'transparent'" @change="tabChange" shrink>
            <van-tab v-for="item in component.tab_list" :key="item.id" :title="item.tab_name" ></van-tab>
        </van-tabs>
        <!-- 素材内容 -->
        <section class="course-content">
            <!-- 一行展示两个 -->
            <ul class="course-list" :style="gridStyle">
                <li class="course-item" v-for="item in currentList" :key="item.id" @click="gotoDetail(item)">
                    <img :src="item.coverpic || item.pic_url || item.cover || item.img" width="100%" height="100">
                    <div class="info">
                        <p :class="[ component.material_name_type === '1' ? 'title line-1' : 'title line-2 h-38' ]"
                            :style="titleStyle">{{ item.name }}</p>
                        <p class="time" v-if="component.class_time_show !== '0'">{{ item.create_time }}</p>
                        <div class="footer">
                            <span class="size line-1">{{ item.size || 0}}M</span>
                            <div class="btn">点击获取</div>
                        </div>
                    </div>
                </li>
            </ul>
            <!-- 查看更多 -->
            <div class="look-more" v-if="showMoreBtn" @click="toCoursePage">
                <div class="btn">
                    查看更多
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import { deepClone, gotoPageByConfig } from '@/utils'

    export default {
        name: 'MaterialPreviewer',
        props: ['component'],
        computed: {
            cmpStyle() {
                const bg = this.component.bg_type === '1' ? 'transparent' : this.component.bg_color
                return {
                    backgroundColor: bg
                }
            },
            gridStyle() {
                return {
                    gridGap: `${this.component.material_margin}px`
                }
            },
            titleStyle() {
                const bold = this.component.font_weight === '1' ? 'normal' : 'bold'
                return {
                    fontWeight: bold,
                    fontSize: `${this.component.class_name_size}px`
                }
            },
        },
        watch: {
            component: {
                handler() {
                    this.autoFocurTab()
                },
                deep: true
            }
        },
        data() {
            return {
                tabActive: 0,
                selectedTab: null,
                currentList: [],
                showMoreBtn: false,
                defaultList: [
                    {
                        id: Math.random(),
                        img: 'https://img2.baidu.com/it/u=1361506290,4036378790&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500',
                        title:'此处显示课程名称此处显示课程名称此处显示课程名称此处显示课程名称此处显示课程名称此处显示课程名称',
                        start_time: '文件大小:20M',
                    },
                    {
                        id: Math.random(),
                        img: 'https://img2.baidu.com/it/u=1361506290,4036378790&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500',
                        title:'此处显示课程名称',
                        start_time: '文件大小:20M',
                    }
                ]
            }
        },
        mounted(){
            if(this.component.tab_list.length > 0) {
                this.setDate(this.component.tab_list[0])
            }
        },
        methods: {
            tabChange(index) {
                const item = this.component.tab_list[index]
                this.setDate(item)
            },
            setDate(info) {
                this.selectedTab = info.id
                this.currentList = this.getCourseByLimit(info)
            },
            getCourseByLimit({ show_type, show_limit, material_list }) {
                this.showMoreBtn = false
                // 如果没有添加数据，则显示默认数据源
                if(material_list.length === 0) {
                    return this.defaultList
                }
                // 如果当前tab添加了数据，则需要根据限制展示类型判断展示多少个
                if(show_type === '1' || material_list.length < show_limit) {
                    return material_list
                }
                this.showMoreBtn = true
                const dep = deepClone(material_list)
                return dep.splice(0, show_limit)
            },
            autoFocurTab() {
                let curItem = null
                const { hover_tab_index, tab_list } = this.component
                if(hover_tab_index + 1  > tab_list.length) {
                    const len = tab_list.length
                    curItem = tab_list[len-1]
                } else {
                    curItem = tab_list[hover_tab_index]
                }
                this.selectedTab = curItem.id
                this.currentList = this.getCourseByLimit(curItem)
            },
            gotoDetail({ isLive, id, ds_id, courseType, material_type, link }) {
                gotoPageByConfig({
                    id,
                    type: courseType,
                    isLive,
                    detail_id: ds_id || id,
                    url: link,
                    material_type
                })
            },
            toCoursePage() {
                console.log(this.component.tab_list[this.tabActive])
                this.$store.dispatch('course/setSubTitle', this.component.tab_list[this.tabActive]?.tab_name)
                this.$store.dispatch('course/setType', this.class_show_type)
                this.$store.dispatch('course/setClassNameTye', this.class_name_type)
                this.$store.dispatch('course/setClassTagType', this.class_tag_type)
                this.$store.dispatch('course/setClassTagName', this.class_tag_name)
                this.$store.dispatch('course/setCourseList', this.component.tab_list[this.tabActive]?.material_list)
                this.$router.push({ name: 'MoreCourse' })
            }
        }
    }
</script>
<style lang='less' scoped>
ul,
li,
p {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.line-1 {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #999999;
}
.line-2 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.h-38 {
    height: 38px;
}
.padding_top {
    padding-top: 10px;
}
.material-previewer {
    padding: 0 10px;
    font-size: 14px;
    ::v-deep .van-tab {
        .van-tab__text {
            font-size: 16px;
            word-break: keep-all;
        }
        &.van-tab--active {
            font-weight: 600;
            .van-tab__text {
                color: #0f72f9;
            }
        }
    }
    .course-content {
        margin-top: 15px;
        .course-list {
            display: grid;
            justify-content: center;
            justify-items: stretch;
            flex-wrap: wrap;
            align-items: stretch;
            grid-gap: 13px;
            grid-template-columns: repeat(2, 1fr);
            .course-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-radius: 10px;
                overflow: hidden;
                background-color: #ffffff;
                box-shadow: 1px 2px 10px #ddd;
                .info {
                    padding: 10px 5px;
                }
                .title {
                    width: 100%;
                }
                .time {
                    font-size: 12px;
                    color: #a3a3a3;
                    margin: 5px 0 10px;
                    padding-bottom: 8px;
                    border-bottom: 1px solid #F4F6F8;
                }
                .footer {
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;
                    margin-top: 10px;
                    .size {
                        width: 100%;
                        line-height: 24px;
                        color: #999999;
                    }
                    .btn {
                        width: 64px;
                        height: 24px;
                        color: #fff;
                        flex-shrink: 0;
                        text-align: center;
                        line-height: 24px;
                        border-radius: 15px;
                        background: linear-gradient(104deg, #73aaff 0%, #0053ff 100%);
                    }
                }
            }
        }
    }
    .look-more {
        margin-top: 10px;
        .btn {
            width: 120px;
            height: 32px;
            border: 1px solid #0f72f9;
            border-radius: 16px;
            text-align: center;
            color: #0f72f9;
            line-height: 32px;
            margin: auto;
            font-size: 12px;
            i {
                margin-left: -3px;
            }
        }
    }
    ::v-deep .van-tab {
        flex: inherit;
        justify-content: left;
    }
    ::v-deep .van-tabs__line {
        background-color: #0f72f9;
    }
}
</style>