/**
 * 描述：初始化所有axios请求实例
**/
import Vue from 'vue'
import commApiRequest from './commApiRequest'
import { getLiveApi, getOnlineApi, getDataApi, getLiveHyApi, getOnlineNotFixApi,getTenghuiApi, getScrmApi } from '@/utils/useProxyApi'

export default function initSystemAxiosInstance() {

    const liveApi = getLiveApi()
    Vue.prototype.$liveApi = commApiRequest(liveApi)

    const onlineApi = getOnlineApi()
    Vue.prototype.$api = commApiRequest(onlineApi)

    const dataApi = getDataApi()
    Vue.prototype.$dataApi = commApiRequest(dataApi)

    const liveHyApi = getLiveHyApi()
    Vue.prototype.$subApi = commApiRequest(liveHyApi)

    const onlineNotFixApi = getOnlineNotFixApi()
    Vue.prototype.$onlineApi = commApiRequest(onlineNotFixApi)
    
    const tenghuiApi= getTenghuiApi()
    Vue.prototype.$tenghuiApi = commApiRequest(tenghuiApi)

    const scrmApi = getScrmApi()
    Vue.prototype.$scrmApi = commApiRequest(scrmApi)
}
