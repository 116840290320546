<template>
  <div class="wrap">
    <NavigateBar :title="'观看数据'" @handleBack="handleBack" />
    <div class="header">
      <div class="search-box" v-if="false">
        <van-search
          v-model="keyword"
          shape="round"
          @search="handleSearchHomework"
          placeholder="请输入关键词搜索"
        />
      </div>
    </div>
    <div class="main" ref="main">
      <div class="homework-item" v-for="item of homeworkList" :key="item.id">
        <div class="main-con">
          <div class="homework-item-left">
            <img :src="item.cover" alt="封面" />
          </div>
          <div class="homework-item-right">
            <div class="homework-item-title" :title="item.course_name">
              {{ item.course_name }}
            </div>
            <div class="homework-item-chapter" :title="item.class_name">
              班期：{{ item.class_name }}
            </div>
            <div class="work-detail" @click="toDeatilPage(item)">
              <span>查看详情</span>
            </div>
          </div>
        </div>
        <!-- 完成率 -->
        <div class="complete_rate_con">
          <div class="progress-container">
            <div class="progress-bar" :style="`width:${item.complete_rate}%`">
              <span class="progress-value"></span>
            </div>
          </div>
          <!-- 显示文字 -->
          <span class="complete-text">{{ item.complete_rate }}%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigateBar from '../myHomework/navigateBar.vue';
import Vue from 'vue';
import { getHost } from '../../utils/useProxyApi';
import { Search, Progress } from 'vant';
Vue.use(Search);
Vue.use(Progress);

export default {
  props: {},
  data() {
    return {
      value: '',
      homeworkList: [],
      keyword: '',
      page: 1,
      pageSize: '',
      insertmoblie: 0,
    };
  },
  components: {
    NavigateBar,
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  created() {
    this.insertmoblie = this.$route.query.insertmoblie;
    if (this.$route.query.moblie) {
      this.moblieType = this.$route.query.moblie;
    }
    if (this.$route.query.insertmoblie == 1) {
      localStorage.setItem('token', this.$route.query.token);
    }
    this.getHomeworkList();
  },
  computed: {},
  watch: {},
  methods: {
    toDeatilPage(item) {
      // 跳转查看详情页
      const query = this.$route.query;
      this.$router.push({
        path: '/viewDataInfo',
        query: {
          ...query,
          classId: item.class_id,
        },
      });
    },
    async getHomeworkList() {
      const data = {
        page: this.page,
        page_nums: 10,
      };
      //  if(this.keyword){
      //    data.keyword = this.keyword
      //  }
      // https://test-school.tenghuiketang.com ${getHost()}
      const res = await this.$tenghuiApi.post(
        `${getHost()}/studentPersonalCenter/getWatchDataList`,
        data
      );
      this.pageSize =
        res.data.data.total > 10 ? Math.ceil(res.data.data.count / 10) : 1;
      this.homeworkList = [...this.homeworkList, ...res.data.data.list];
    },
    handleScroll() {
      let dom = window.document.documentElement || window.document.body;
      //文档内容实际高度（包括超出视窗的溢出部分）
      let scrollHeight = Math.max(dom.scrollHeight, dom.scrollHeight);
      //滚动条滚动距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //窗口可视范围高度
      let clientHeight =
        dom.innerHeight || Math.min(dom.clientHeight, dom.clientHeight);
      if (clientHeight + scrollTop + 100 >= scrollHeight) {
        if (this.page >= this.pageSize) return;
        this.page++;
        this.getHomeworkList();
      }
    },
    handleSearchHomework() {
      this.homeworkList = [];
      this.page = 1;
      this.getHomeworkList();
    },

    handleBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
  background: #f6f6f6;
}

.header {
  box-sizing: border-box;
  padding: 0 10px;
  background: #ffffff;
}
.search-box {
  ::v-deep .van-search {
    padding: 0;
    border-radius: 18px;
  }
  ::v-deep .van-search__content {
    background-color: #f6f6f6;
  }
  ::v-deep .van-icon-search {
    font-weight: 700;
  }

  ::v-deep input[type='search']::placeholder {
    color: #7e8180;
  }
}
.main {
  box-sizing: border-box;
  padding: 10px;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  .homework-item {
    width: 100%;
    height: 124px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 57, 135, 0.06);
    box-sizing: border-box;
    padding: 12px 10px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .main-con {
      width: 100%;
      height: 76px;
      display: flex;
      position: relative;
      .homework-item-left {
        width: 115px;
        height: 76px;
        background: #d9d9d9;
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
      .homework-item-right {
        margin-left: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 4px;
        box-sizing: border-box;
        .homework-item-title {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-400;
          font-weight: 400;
          color: #202020;
          max-width: 210px;
        }
        .homework-item-chapter {
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-400;
          font-weight: 400;
          color: #666666;
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .work-detail {
          width: 64px;
          height: 24px;
          background: linear-gradient(133deg, #73aaff 3%, #0053ff 91%);
          border-radius: 20px;
          font-size: 12px;
          font-weight: 400;
          color: #fefffe;
          line-height: 24px;
          text-align: center;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }

    .homework-item-grade {
      font-size: 14px;
      color: #7e8180;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
::v-deep .van-icon-search:before {
  content: '';
  background: url('../../assets/images/serch_button.png') no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
}
::v-deep .van-icon-search {
  top: 2px;
}
.complete_rate_con {
  display: flex;
  align-items: center;
  .complete-text {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    text-align: LEFT;
    color: #666666;
    margin-left: 10px;
  }
}
.progress-container {
  width: 290px;
  background: rgba($color: #dedede, $alpha: 0.45);
  border-radius: 6px;
  overflow: hidden; /* 确保进度条不会超出容器 */
}

.progress-bar {
  height: 8px;
  background: #97c3ff;
  border-radius: 6px;
  transition: width 0.5s ease; /* 进度变化时的动画效果 */
  overflow: hidden; /* 确保进度条不会超出容器 */
  position: relative; /* 用于定位进度文字 */
}

.progress-bar::before {
  // content: attr(style);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #4caf50; /* 进度条的颜色 */
  border-radius: 4px; /* 圆角边框 */
  color: white; /* 进度文字的颜色 */
  text-align: center; /* 水平居中 */
  line-height: 20px; /* 垂直居中 */
}

.progress-value {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%); /* 水平居中 */
  color: white; /* 进度文字的颜色 */
  font-size: 14px; /* 字体大小 */
}
</style>
