<template>
  <div class="view-info">
    <!-- 头部 -->
    <NavigateBar title="查看详情" @handleBack="$router.go(-1)"></NavigateBar>
    <!-- 主体内容 -->
    <div class="view-main" @scroll="handleScroll">
      <van-collapse v-model="activeNames" class="table-con">
        <van-collapse-item
          class="chapter-item"
          v-for="(item, index) in homeworkList"
          :key="index"
          :name="item.id"
        >
          <template slot="title">
            <span style="width: 90%">{{
              `第${index + 1}章 ${item.name}`
            }}</span>
            <i :class="activeFn(item.id) != -1 ? 'downIcon' : 'upIcon'"></i>
          </template>
          <!-- 节 -->
          <van-collapse-item
            class="session-item"
            v-for="(list, i) in item.chapterList"
            :key="list.chapter_id"
            :name="list.chapter_id"
          >
            <template slot="title">
              <span style="width: 90%">{{ `${i + 1}、${list.name}` }}</span>
              <i
                :class="activeFn(list.chapter_id) != -1 ? 'downIcon' : 'upIcon'"
              ></i>
            </template>
            <InfoList
              v-for="(node, nodeIndex) in list.videoList"
              :key="nodeIndex"
              :itemObj="node"
            />
          </van-collapse-item>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Collapse, CollapseItem } from 'vant';
import NavigateBar from '../myHomework/navigateBar';
import InfoList from './InfoList';
import { getHost } from '../../utils/useProxyApi';

Vue.use(Collapse);
Vue.use(CollapseItem);

export default {
  components: {
    NavigateBar,
    InfoList,
  },
  data() {
    return {
      activeNames: ['1'],
      page: 1,
      classId: '',
      homeworkList: [],
    };
  },
  created() {
    this.classId = this.$route.query.classId;
    this.getHomeworkList();
  },
  computed: {},
  methods: {
    activeFn(data) {
      return this.activeNames.indexOf(data);
    },
    handleScroll() {
      let dom = window.document.documentElement || window.document.body;
      //文档内容实际高度（包括超出视窗的溢出部分）
      let scrollHeight = Math.max(dom.scrollHeight, dom.scrollHeight);
      //滚动条滚动距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //窗口可视范围高度
      let clientHeight =
        dom.innerHeight || Math.min(dom.clientHeight, dom.clientHeight);
      if (clientHeight + scrollTop + 100 >= scrollHeight) {
        if (this.page >= this.pageSize) return;
        this.page++;
        this.getHomeworkList();
      }
    },
    getHomeworkList() {
      // 异步更新数据
      const data = {
        page: this.page,
        page_nums: 10,
        class_id: this.classId,
      };
      // https://test-school.tenghuiketang.com ${getHost()}
      this.$tenghuiApi
        .post(`${getHost()}/studentPersonalCenter/getMobileWatchDataInfo`, data)
        .then((res) => {
          if (res && res.data.code == 200) {
            this.pageSize =
              res.data.data.total > 10
                ? Math.ceil(res.data.data.total / 10)
                : 1;
            this.homeworkList = [...this.homeworkList, ...res.data.data.list];
            this.homeworkList.forEach((item, index) => {
              item.id = `${index + 1}`;
            });
            // 对节数据处理
            this.homeworkList.forEach((ele) => {
              const chapterList = [];
              // 合并节
              ele.chapter_video_list.forEach((list) => {
                if (!chapterList.some((c) => c.chapter_id == list.chapter_id)) {
                  const videoList = ele.chapter_video_list.filter(
                    (i) => i.chapter_id === list.chapter_id
                  );
                  chapterList.push({
                    name: list.name,
                    chapter_id: list.chapter_id,
                    videoList,
                  });
                }
              });
              ele.chapterList = chapterList;
            });
            console.log(this.homeworkList, '观看数据');
          }
        })
        .catch((err) => {
          // this.$message.error(err.message);
        });
    },
  },
};
</script>

<style scoped>
.view-info {
  width: 100%;
  height: 100%;
  background: #f6f6f6;
}
.view-main {
  margin-top: 12px;
  height: calc(100% - 60px);
  overflow-y: auto;
}
.view-main ::v-deep .van-collapse .van-collapse-item__content {
  /* background: #f6f6f6 !important; */
  padding: 0px 0px 0px 16px;
}
.view-main ::v-deep .van-collapse .session-item .van-collapse-item__content {
  /* background: #f6f6f6 !important; */
  padding: 10px 26px 10px 10px;
}

.view-main ::v-deep .table-con .van-collapse-item .van-icon-arrow {
  /* 隐藏原箭头 */
  display: none;
}
/* 添加自定义图标 */
.view-main ::v-deep .table-con .van-collapse-item .downIcon {
  margin-right: 5px;
  width: 14px;
  height: 14px;
  display: inline-block;
  background: url('../../assets/img/up.png') no-repeat;
}
.view-main ::v-deep .table-con .van-collapse-item .upIcon {
  margin-right: 5px;
  width: 14px;
  height: 14px;
  display: inline-block;
  background: url('../../assets/img/up.png') no-repeat;
  transform: rotate(-180deg);
}
.view-main ::v-deep .table-con .chapter-item {
  border-bottom: 1px solid #f6f6f8;
}
.view-main ::v-deep .table-con .chapter-item .van-cell__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-400;
  font-weight: 400;
  color: #20242f;
}
.view-main ::v-deep .table-con .chapter-item .session-item .van-cell__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-400;
  font-weight: 400;
  color: #202020;
}
.view-main ::v-deep .table-con .van-cell::after {
  border-bottom: none;
}
.view-main
  ::v-deep
  .table-con
  .chapter-item
  .van-collapse-item__content
  .van-collapse-item--border::after {
  border-top: none;
}
</style>
