<template>
  <div>
    <NavigateBar :title="'我的笔记'" @handleBack="handleBack"/>
    <div class="container">
       <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="content" v-for="item in noteList" :key="item.id" @click.stop="toDetail(item)">
          <img class="img" v-if="!item.is_video_delete" :src="item.cover_url" alt="">
          <img class="img" v-else src="../../assets/images/note-lapse.png" alt="">
          <div class="right">
             <div class="title">{{item.name}}</div>
             <div class="bottom">
                <div class="time">{{item.update_at}}</div>
                <div class="delete" @click.stop="deleteNote(item)">删除笔记</div>
             </div>
          </div>
        </div>
       </van-list>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import NavigateBar from '../myHomework/navigateBar.vue';
export default {
  data(){
    return{
       noteList:[],
       loading:false,
       finished:false,
       page:1
    }
  },
  components:{
    NavigateBar
  },
  created(){
    this.getList()
  },
  methods:{
        async deleteNote(item){
        let ids=[]
        ids.push(item.id)
        const data={
          ids
        }
        const res=await this.$api.post('/stu/course/deleteNote',data)
        if (res.data.code==200) {
          this.getList()
          Toast('删除成功')
        }
        console.log('[<-- res -->]',res);
        
    },
    toDetail(item){
      console.log('[<-- item -->]',item);
        this.$router.push({name:'NoteDetail',query:{course_id:item.course_id,chapter_id:item.chapter_id,video_id:item.video_id,
        file_id:item.file_id,duration:item.duration,
        view_time:item.view_time,is_video_delete:item.is_video_delete}})
    },
    handleBack(){
      this.$router.go(-1)
    },
    onLoad(){
       this.getList()
    },
     async getList(){
      this.loading=true
       const data={
        page:this.page,
        page_num:10
       }
       const res=await this.$api.post('/stu/course/getNoteList',data)
       console.log('[<-- res--noteList -->]',res);
       if (res.data.code==200) {
         if (this.page==1) {
          this.noteList=[]
         }
         if (res.data.data.list.length>=10) {
            this.page++
         }else{
            this.finished=true
         }
         this.loading=false
         this.noteList.push(...res.data.data.list)
       }
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  margin: 0.2rem;
  width: 9.6rem;
  height: 100vh;
  border-radius: 10px;
.content{
  padding: 0.3rem 0.3rem 0.5rem;
  display: flex;
  justify-content: start;
  .img{
    width: 120px;
    height: 88px;
    display:inline-block;
    margin-right: 10px;
    border-radius: 10px;
  }
  .right{
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    width: 6rem;
    .title{
      font-weight: 400;
      color: #202020;
      font-size: 14px;
      width: 5.5rem;
      word-wrap: break-word;
      overflow-wrap: break-word; 
      white-space: normal;
    }
    .bottom{
      display: flex;
      justify-content: space-between;
      align-items: end;
      .time{
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        text-align: center;
      }
      .delete{
        width: 64px;
        height: 24px;
        background: #ffffff;
        border: 1px solid #1872ed;
        border-radius: 20px;
        font-size: 12px;
        font-weight: 400;
        color: #1872ed;
        text-align: center;
        line-height: 24px;
      }
    }
  }
}
}
</style>