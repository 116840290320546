<!-- 商品组件 -->
<template>
    <div class='category-nav-previewer padding_top component-container' :style="cmpStyle">
        <van-tabs v-model:active="tabActive" :background="'transparent'" @change="tabChange" shrink>
            <van-tab v-for="item in component.tab_list" :key="item.id" :title="item.tab_name"></van-tab>
        </van-tabs>
        <!-- 课程内容 -->
        <section class="course-content">
            <!-- 一行展示一个 -->
            <ul class="course-list-1" v-if="component.class_show_type === '2'">
                <li class="course-item" v-for="item in currentList" :key="item.id" :style="liStyle" @click="gotoDetail(item)">
                    <img :src="item.coverpic || item.pic_url || item.cover" width="90" height="90">
                    <div class="live-status" v-if="item.isLiving === 2">
                        <img src="@/assets/images/mofang/live_icon.gif" width="20">直播中
                    </div>
                    <div class="info">
                        <p :class="[ component.class_name_type === '1' ? 'title line-1' : 'title line-2' ]"
                            :style="titleStyle">{{ item.name }}</p>
                        <p class="time" v-if="component.class_time_show !== '0'">{{ item.create_time }}</p>
                        <p class="tag">{{ component.class_tag_type === '1' ? component.class_tag_name : '' }}</p>
                        <div class="footer">
                            <div class="price">{{ item.price ? `￥${item.discount_price || item.price}` : '' }}</div>
                            <div class="btn">{{ item.live_type === '5' ? '立即购买' : '查看详情' }}</div>
                        </div>
                    </div>
                </li>
            </ul>
            <!-- 一行展示两个 -->
            <ul class="course-list-2" :style="gridStyle" v-else>
                <li class="course-item" v-for="item in currentList" :key="item.id" @click="gotoDetail(item)">
                    <img class="cover" :src="item.coverpic || item.pic_url || item.cover" width="100%">
                    <div class="live-status" v-if="item.isLiving === 2">
                        <img src="@/assets/images/mofang/live_icon.gif" width="20">直播中
                    </div>
                    <div class="info">
                        <p :class="[component.class_name_type === '1' ? 'title line-1' : 'title line-2 h-38']"
                            :style="titleStyle">{{ item.name }}</p>
                        <p class="time" v-if="component.class_time_show !== '0'">{{ item.create_time }}</p>
                        <span class="tag line-1">{{ component.class_tag_type === '1' ? component.class_tag_name : '' }}</span>
                        <div class="footer">
                            <div class="price">{{ item.price ? `￥${item.discount_price || item.price}` : '' }}</div>
                            <div class="btn">{{ item.live_type === '5' ? '立即购买' : '查看详情' }}</div>
                        </div>
                    </div>
                </li>
            </ul>
            <!-- 查看更多 -->
            <div class="look-more" v-if="showMoreBtn" @click="toCoursePage">
                <div class="btn">
                    查看更多
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { deepClone, gotoPageByConfig } from '@/utils'

    export default {
        name: 'categoryNavPreviewer',
        props: ['index', 'component'],
        computed: {
            ...mapGetters({
                org_id:'user/getOrgId'
            }),
            cmpStyle() {
                const bg = this.component.bg_type === '1' ? 'transparent' : this.component.bg_color
                return {
                    backgroundColor: bg
                }
            },
            liStyle() {
                return {
                    marginBottom: `${this.component.class_margin}px`
                }
            },
            titleStyle() {
                const bold = this.component.font_weight === '1' ? 'normal' : 'bold'
                return {
                    fontWeight: bold,
                    fontSize: `${this.component.class_name_size}px`
                }
            },
            gridStyle() {
                return {
                    gridGap: `${this.component.class_margin}px`
                }
            }
        },
        watch: {
            component: {
                handler() {
                    this.autoFocurTab()
                },
                deep: true
            }
        },
        data() {
            return {
                showMoreBtn: false,
                selectedTab: null,
                currentList: [],
                tabActive: 0,
                defaultList: [
                    {
                        id: Math.random(),
                        coverpic: 'https://img2.baidu.com/it/u=1361506290,4036378790&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500',
                        name:'此处显示课程名称此处显示课程名称此处显示课程名称此处显示课程名称此处显示课程名称此处显示课程名称',
                        create_time: '此处显示时间',
                    },
                    {
                        id: Math.random(),
                        coverpic: 'https://img2.baidu.com/it/u=1361506290,4036378790&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500',
                        name:'此处显示课程名称',
                        create_time: '此处显示时间',
                    }
                ]
            }
        },
        mounted(){
            if(this.component.tab_list.length > 0) {
                this.setDate(this.component.tab_list[0])
            }
        },
        methods: {
            tabChange(index) {
                const item = this.component.tab_list[index]
                this.setDate(item)
            },
            setDate(info) {
                this.selectedTab = info.id
                this.currentList = this.getCourseByLimit(info)
                this.currentList = this.currentList.map(item => {
                    return {
                        ...item,
                        isLiving: 1
                    }
                })
                this.getLivingStatus()
            },
            getCourseByLimit({ show_type, show_limit, class_list }) {
                this.showMoreBtn = false
                // 如果没有添加数据，则显示默认数据源
                if(class_list.length === 0) {
                    return this.defaultList
                }
                // 如果当前tab添加了数据，则需要根据限制展示类型判断展示多少个
                if(show_type === '1' || class_list.length < show_limit) {
                    return class_list
                }
                this.showMoreBtn = true
                const dep = deepClone(class_list)
                return dep.splice(0, show_limit)
            },
            autoFocurTab() {
                let curItem = null
                const { hover_tab_index, tab_list } = this.component
                if(hover_tab_index + 1  > tab_list.length) {
                    const len = tab_list.length
                    curItem = tab_list[len-1]
                } else {
                    curItem = tab_list[hover_tab_index]
                }
                this.selectedTab = curItem.id
                this.currentList = this.getCourseByLimit(curItem)
            },
            gotoDetail({ isLive, id, ds_id, live_type, courseType, course_id }) {
                gotoPageByConfig({
                    id,
                    ds_id,
                    isLive,
                    live_type,
                    course_id,
                    type: courseType,
                    detail_id: ds_id || id
                })
            },
            toCoursePage() {
                const newList = this.component.tab_list[this.tabActive]?.class_list.map(item => {
                    return { isLiving: 1, ...item, }
                })
                this.$store.dispatch('course/setSubTitle', this.component.tab_list[this.tabActive]?.tab_name)
                this.$store.dispatch('course/setType', this.class_show_type)
                this.$store.dispatch('course/setClassNameTye', this.class_name_type)
                this.$store.dispatch('course/setClassTagType', this.class_tag_type)
                this.$store.dispatch('course/setClassTagName', this.class_tag_name)
                this.$store.dispatch('course/setCourseList', newList)
                this.$router.push({ name: 'MoreCourse' })
            },
            /**
             * 描述：获取直播状态
            **/
            getLivingStatus() {
                this.currentList.forEach(async({ live_type, id, ds_id }) => {
                    let options = {
                        org_id: this.org_id,
                        ids: JSON.stringify([id || ds_id])
                    }
                    // live_type: 1：直播 2：班课直播间 3：伪直播  4：班课课程 5：商品
                    // type: 1 直播 2 伪直播 3 课程
                    if(live_type !== '5') {
                        if(live_type === '1' || live_type === '2') {
                            options.type = 1
                        } else if(live_type === '3') {
                            options.type = 2
                        } else if(live_type === '4') {
                            options.type = 3
                        }
                        const { data: { code, data:resData } } = await this.$api.post('/stu/getLiveStatus', options)
                        // const { data: { code, data:resData } } = await this.$api.post('https://online.tenghuiketang.com/onlineapi/stu/getLiveStatus', options)
                        if(code === 200) {
                            this.updateLiveItem(resData)
                        }
                    }
                })
            },
            updateLiveItem(data) {
                for(const attr in data) {
                    const updateItem = this.currentList.find(item => item.id === +attr || item.ds_id === +attr)
                    if(updateItem) {
                        updateItem.isLiving = data[attr]
                    }
                }
            }
        }
    }
</script>
<style lang='less' scoped>
ul,
li,
p {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.line-1 {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.line-2 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.h-38 {
    height: 38px;
}
.padding_top {
    padding-top: 10px;
}
.category-nav-previewer {
    padding: 10px 10px 0;
    font-size: 14px;
    ::v-deep .van-tab {
        flex: inherit;
        justify-content: left;
        .van-tab__text {
            font-size: 16px;
            word-break: keep-all;
        }
        &.van-tab--active {
            font-weight: 600;
            .van-tab__text {
                color: #0f72f9;
            }
        }
    }
    ::v-deep .van-tabs__nav--line.van-tabs__nav--complete {
        padding-left: 0;
    }
    .course-content {
        margin-top: 15px;
    }
    .course-list-1 .course-item, .course-list-2 .course-item{
        .live-status {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 56px;
            height: 18px;
            top: 0;
            left: 0;
            background: url(../../../assets/images/mofang/live_status_bg.png) no-repeat;
            background-size: 100% 100%;
            font-size: 12px;
            color: #ffffff;
            img {
                width: 10px;
                margin-right: 4px;
            }
        }
    }
    .course-list-1 {
        margin: 0;
        padding: 0;
        .course-item {
            display: flex;
            position: relative;
            list-style-type: none;
            margin-bottom: 10px;
            box-shadow: 1px 2px 10px #ddd;
            padding: 10px 8px;
            border-radius: 5px;
            align-items: center;
            background-color: #ffffff;
            &:last-child {
                margin: 0 !important;
            }
            img {
                border-radius: 5px;
            }
            .info {
                display: flex;
                width: 100%;
                flex-direction: column;
                justify-content: space-between;
                .title {
                    min-height: 30px;
                    margin-right: 20px;
                }

                margin-left: 10px;
                .time {
                    color: #999;
                    font-size: 12px;
                }
                .tag {
                    font-size: 12px;
                    margin: 5px 0;
                    color: #f33f27;
                }
            }
            .footer {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
                .price {
                    color: #f33f27;
                }
                .btn {
                    width: 64px;
                    height: 24px;
                    color: #fff;
                    text-align: center;
                    line-height: 24px;
                    border-radius: 15px;
                    background: linear-gradient(104deg, #73aaff 0%, #0053ff 100%);
                }
            }
        }
    }
    .course-list-2 {
        display: grid;
        justify-content: center;
        justify-items: stretch;
        flex-wrap: wrap;
        align-items: stretch;
        grid-gap: 13px;
        grid-template-columns: repeat(2, 1fr);
        .course-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 1px 2px 10px #ddd;
            background-color: #ffffff;
            img.cover {
                max-height: 120px; 
            }
            .info {
                padding: 15px 5px 10px;
            }
            .title {
                width: 100%;
            }
            .time {
                font-size: 12px;
                color: #a3a3a3;
                margin: 12px 0 15px;
            }
            .tag {
                width: 100%;
                font-size: 12px;
                color: #f33f27;
            }
            .footer {
                display: flex;
                justify-content: space-between;
                padding-top: 8px;
                font-size: 12px;
                align-items: center;
                border-top: 1px solid #f4f6f8;
                .price {
                    width: 100%;
                    color: #f33f27;
                }
                .btn {
                    width: 64px;
                    height: 24px;
                    color: #fff;
                    flex-shrink: 0;
                    text-align: center;
                    line-height: 24px;
                    border-radius: 15px;
                    background: linear-gradient(104deg, #73aaff 0%, #0053ff 100%);
                }
            }
        }
    }
    .look-more {
        margin-top: 10px;
        .btn {
            width: 120px;
            height: 32px;
            border: 1px solid #0f72f9;
            border-radius: 16px;
            text-align: center;
            color: #0f72f9;
            line-height: 32px;
            margin: auto;
            font-size: 12px;
            i {
                margin-left: -3px;
            }
        }
    }
    ::v-deep .van-tabs__line {
        background-color: #0f72f9;
    }
}
</style>