<template>
    <div>
        <div class="shenfent"><img style="height: 52px;" src="../../../assets/images/shenfenrenzr.png" alt=""></div>
        <div class="renzhengzilyi"><span class="yanzheng">您正在进行个人认证，认证过程中将收集您的如下信息：</span></div>
        <div class="renzhengzil">
            <li class="xingming" style="margin-top: 16px;">姓名、证件号</li>
            <li class="xingming" style="margin-top: 10px;">人脸图像或视频<span style="color: #999999;">（仅人脸识别获取）</span></li>
            <li class="xingming" style="margin-top: 10px;">本人开户的手机号<span style="color: #999999;">（仅手机号认证获取）</span></li>
        </div>
        <div class="shiyongxy">
            <van-checkbox v-model="checked" icon-size="16px"></van-checkbox>
            <p style="margin-left: 4px;">已经阅读并同意<span class="yingsi" @click="shiyongtiao(1)">《使用协议》</span>及<span class="yingsi" @click="shiyongtiao(2)">《隐私条款》</span></p>
        </div>
        <div class="kaishirz" @click="kaishilist()">
            开始认证
        </div>
        <van-popup class="wgxtk" v-model="showlist">
            <div style="text-align: center;">
                <p class="tishi">提示</p>
                <p class="zixiyuedu">请您仔细阅读用户《使用协议》及《隐私条款》</p>
            </div>
            <div class="quxiaotk">
                <div class="quxiaohz" style="border-right: 1px solid #f0f0f0;" @click="showlist = false">取消</div>
                <div class="quxiaohz" style="color: #FF5227;" @click="showlist = false">已阅读</div>
            </div>
        </van-popup>
    </div>
</template>
<script>
export default {
    name: "PersonalAuthentication",
    data() {
        return {
            checked: false,
            showlist: false
        }
    },
    methods: {
        kaishilist() {
            if(this.checked == false) {
                this.showlist = true;
                return
            } else {
                console.log('跳转===========')
                this.$router.push({
                    path: "/Realnameauthentication",
                    query: {
                        zhilist: 123
                    }
                })
            }
        },
        shiyongtiao(item) {
            this.$router.push({
                path: "/Agreementlist",
                query: {
                    zhilist: item
                }
            })
        }
    }
}
</script>
<style scoped>
.quxiaohz {
    width: 148px;
    height: 48px;
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    text-align: center;
    line-height: 48px;
    color: #666666;
}
.quxiaotk {
    width: 297px;
    height: 48px;
    border-top: 1px solid #f0f0f0;
    margin-top: 30px;
    display: flex;
}
.zixiyuedu {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #666666;
    margin-left: 28px;
    margin-right: 27px;
    margin-top: 20px;
}
.tishi {
    font-size: 16px;
    font-family: Noto Sans SC, Noto Sans SC-500;
    font-weight: 500;
    color: #202020;
    margin-top: 27px;
}
.wgxtk {
    width: 297px;
    height: 185px;
    background: #ffffff;
    border-radius: 8px;
}
.kaishirz  {
    width: 343px;
    height: 42px;
    background: #1872ed;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 16px;
    font-size: 16px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 42px;
}
.yingsi {
    color: #1872ED;
}
.shiyongxy {
    display: flex;
    width: 343px;
    margin: 0 auto;
    margin-top: 130px;
}
.xingming {
    font-size: 12px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #202020;
    margin-left: 10px;
}
.renzhengzilyi {
    width: 303px;
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
}
.renzhengzil {
    width: 323px;
    height: 107px;
    background: #f7faff;
    border-radius: 2px;
    margin: 0 auto;
    margin-top: 20px;
    overflow: hidden;
}
.yanzheng {
    font-size: 16px;
    font-family: Noto Sans SC, Noto Sans SC-500;
    font-weight: 500;
    color: #202020;
    text-align: center;
}
.shenfent {
    text-align: center;
    margin-top: 83px;
}
* {
    margin: 0;
    padding: 0;
}
</style>