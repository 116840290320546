<template>
	<div style="margin-top:40px">
		<div class="answer big">
			<span class="mr40" :class="{ green: question.row === 1, red: question.row !== 1 }" v-show="question.type !== 4">{{ question.row === 1 ? '回答正确' : '回答错误' }}</span>
			<!-- <span  class="text_anawer" >正确答案：{{question.answer}}</span> -->
			<span v-if="question.type == 3">正确答案：{{question.answer == 1?'正确':'错误'}}</span>
      <span v-else-if="question.answer">正确答案：{{question.answer.split('').sort().join('')}}</span>
		</div>
		<div v-if="question.type == 4" style="font-size: 16px;font-weight: 400;color: #1872ed;margin-left: 10px;margin-bottom: 20px;">本题得分：{{question.user_score}}分</div>
		<div class="parse">
			<div class="parse-tit flex flex-middle big">
				<span />
				题目解析
			</div>
			<div class="parse-cont">{{ question.analysis?question.analysis:'暂无解析' }}</div>
			<!-- <div class="refer flex" v-show="question.link">
				<span>参考:</span>
				<span class="link flex-1" @click="copy">{{ question.link }}</span>
			</div> -->
		</div>
    <div class="parse" v-show="comment && cur === total">
			<div class="parse-tit flex flex-middle big">
				<span />
				老师评语
			</div>
			<div class="parse-cont">{{ comment }}</div>
			<!-- <div class="refer flex" v-show="question.link">
				<span>参考:</span>
				<span class="link flex-1" @click="copy">{{ question.link }}</span>
			</div> -->
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return{
			optionTxtList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
		}
	},
	props: {
		indexanawer: {
			type: String,
			default: ''
		},
		question: {
			type: Object,
			default: () => {}
		},
		comment:{
			type:String,
			default:''
		},
		cur:{
			type:Number,
			default:()=>{return 0}
		},
		total:{
			type:Number,
			default:()=>{return 0}
		}
	},
	// onmouted() {
	// 	let num = this.indexanawer
	// 	console.log(this)
	// 	this.answer = this.optionTxtList[num]
	// },
	methods: {
		copy() {
			
		}
	}
};
</script>

<style lang="scss" scoped>
@import url('../../../../css/flex.scss');
	.text_anawer{
		// display: flex;
		width: 100%;
		word-break: break-all;
	}
.answer {
	font-size: 16px;
	padding: 0 16px 10px;
	color: #202020;
  font-weight: 600;
	.mr40 {
		margin-right: 20px;
	}
	.green {
		color: #0bb65d;
	}
	.red {
		color: #f34718;
	}
}
.parse {
	padding: 12px 16px 0px;
  font-size: 16px;
	&-tit {
		color: #202020;
		padding-bottom: 2px;
    font-weight: 600;
		> span {
			width: 4px;
			height: 16px;
			background: #10A675;
			border-radius: 2px;
			margin-right: 10px;
		}
	}
	&-cont {
		color: #888;
    background-color: #fff;
    box-sizing: border-box;
    padding: 6px 16px;
    margin-top: 6px;
	}
	.refer {
		color: #888;
		padding-top: 14px;
		.link {
			color: #0c7fff;
			background-color: rgba(12, 127, 255, 0.1);
			padding: 1px 3px;
			margin-left: 4px;
			word-break: break-all;
		}
	}
}
</style>
