import { render, staticRenderFns } from "./Realnameresult.vue?vue&type=template&id=13a99e56&scoped=true"
import script from "./Realnameresult.vue?vue&type=script&lang=js"
export * from "./Realnameresult.vue?vue&type=script&lang=js"
import style0 from "./Realnameresult.vue?vue&type=style&index=0&id=13a99e56&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13a99e56",
  null
  
)

export default component.exports