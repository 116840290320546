<template>
    <div class="paymenTdetails">
        <div class="fanhui">
            <van-icon class="back" @click="fanhuilist" name="arrow-left" color="#181818" size="26" />
            <div class="querending">确认订单</div>
        </div>
        <div class="chanping">
            <div><img class="shangpt" :src="cover" alt=""></div>
            <div>
                <div class="ershiyi">{{name}}</div>
                <div v-if="discount_price == null" class="qianshu"><span style="font-size: 12px;">￥</span>{{price}}</div>
                <div v-if="discount_price != null" class="qianshu"><span style="font-size: 12px;">￥</span>{{discount_price}}</div>
            </div>
        </div>
        <div v-if="ext != null" class="zidingyi">
            <div style="margin-bottom: 17.5px;">
                <div class="tixing"><span style="color: #ff5227;">*</span>课程提醒发送至</div>
                <div v-for="(item, index) in ext" :key="index">
                    <van-field class="shuruys" v-model="form[`custom${item.id}`]['value']" :label="item.name" :placeholder="`请输入${ item.name }`" input-align="right"/>
                </div>
                <!-- <button @click="zhifuzhong()">支付</button> -->
            </div>
        </div>
        <div class="zhifufangshi">
            <div class="zhifufangshiyi">支付方式</div>
            <div style="margin-top: 15px;">
                  <div class="paybx" style="margin-bottom: 25px;" v-show="liveType != 'class'" v-if="platform == 'wx'||!platform">
                    <div class="wxpaybx">
                      <img src="../assets/images/weixintu.png" class="logot" mode=""/>
                      <span style="margin-left: 8px;font-size: 12px;">微信支付</span>
                    </div>
                    <div  @click="qcheck">
                      <img v-if="!wxcheck" class="checkin" src="../assets/images/wk.png" mode=""/>
                        <img v-else class="checkin" src="../assets/images/xz.png" mode=""/>
                    </div>
                  </div>
                  <div class="paybx" v-if="platform == 'ali'||!platform">
                    <div class="wxpaybx">
                      <img src="../assets/images/zhifubaotu.png" class="logot" mode=""/>
                      <span style="margin-left: 8px;font-size: 12px;">支付宝支付</span>
                    </div>
                    <div @click="qcheck1">
                      <img v-if="!apilcheck" class="checkin" src="../assets/images/wk.png" mode=""/>
                        <img v-else class="checkin" src="../assets/images/xz.png" mode=""/>
                    </div>
                </div>
              </div>
              <!-- <div class="qrpaybx">
                <div class="qrpay"  @click="quzhifu">
                  确认支付
                </div>
              </div> -->
        </div>
        <div class="lijizhifu">
            <div style="display: flex;width: 188px;height: 52px;">
                <div class="hejiyi">合计:</div>
                <div v-if="discount_price == null" class="hejiqian">￥{{price}}</div>
                <div v-if="discount_price != null" class="hejiqian">￥{{discount_price}}</div>
            </div>
            <div class="lijifuqian" @click="payMoney">立即支付</div>
        </div>
        <div class="dialogpay" v-if="coyshow">
        <div class="paytitle">
          支付宝支付
        </div>
        <div>
          <img  class="payclose" @click="close2" src="../assets/images/cc.png" />
            <div class="tzbx1">
            <div  class="tzbx">
              <img  class="tzbxs" src="../assets/images/copy.png" />
              <div class="coybx">
              <div style="font-size: 16px;"> 复制链接</div>
              <div class='coy' style="font-size: 16px;"> 点击右侧按钮复制链接</div>
              </div>
            </div>
              <div style="font-size: 16px;" class="copy1" @click="copy">
                复制
              </div>
            </div>
        </div>
        <div class="bzt">
          <img src="../assets/images/xj.png" class="logot logot1" mode=""/>
        </div>
          <div class="tzbx">
          <div >
            <img class="tzbxs" src="../assets/images/llq.png" mode=""/>
          </div>
            <div class="coybx">
              <div style="font-size: 16px;">在浏览器粘贴链接</div>
              <div class='coy' style="font-size: 16px;">打开手机浏览器，粘贴链接并使用</div>
            </div>
          </div>
        </div>
        <van-popup v-model="showlist" style="width: 240px;height: 240px;">
          <div>
            <div style="text-align: center;margin-top: 20px;">长按屏幕识别二维码支付</div>
            <div style="width: 160px;height: 160px;margin: 0 auto;margin-top: 20px;" id="qrcodeDom">
          </div>
        </div>
        </van-popup>
    </div>
</template>
<script>
import { Toast } from 'vant';
import { Notify } from "vant";
import { debounce } from '@/utils';
import QRCode from 'qrcodejs2'

export default {
    name: "paymenTdetails",
    data() {
        return {
            cover:'',
            name: '',
            price: '',
            ext: [],
            form: {},
            wxcheck:1,
            apilcheck:0,
            platform:'',
            liveType: "open",
            coyshow:false,
            discount_price:'',
            opid: '',
            uid: '',
            zhifubaolj: '',
            pay_type: null,
            showlist: false
        }
    },
    created() {
      this.platform = this.$route.query.platform || ''
      if(this.platform === 'ali') {
        this.wxcheck = 0
        this.apilcheck = 1
      }
    },
    mounted() {
      console.log('gsssss=====', this.$route.query.kechengidzf)
        this.beforeCreate()
        this.infolist()
    },
    methods: {
        // 判断是否在支付宝浏览器
    isInAliPay(){
       if (/AlipayClient/.test(window.navigator.userAgent)) {
          //判断是否是支付宝浏览器打开
          return true
        }
      },
    close2(){
        // console.log(888);
        this.coyshow=false
        // this.maskshow=false
        // this.closeBtnClicked()
        // this.maskshow=false
      },
    copy(){
        var that=this
        // let fans=JSON.parse(localStorage.getItem(localStorage.getItem('current_app_Id')))
         
        // console.log() ,'>>>>>>>>>'); // &header=${fans.header} &nick=${fans.nick}
        if(this.discount_price == null) {
          this.jine = this.price
        } else if(this.discount_price != null) {
          this.jine = this.discount_price
        }
        let copy = this.zhifubaolj
        this.$copyText(copy).then(
          () => {
            Notify({ type: 'success', message: '复制成功',background: '#4fc08d', })
            // Toast('复制成功！');
            });
      },
    getCache(appIdTmp) {
        //判断缓存是否存在
        try {
          let fans = JSON.parse(localStorage.getItem(appIdTmp));
          this.fans = fans
          if (fans) {
            return true;
          }
        } catch (e) {
          localStorage.removeItem(appIdTmp);
          return false;
        }
        return false;
    },  
        payMoney: debounce(function(){
            this.quzhifu()
        }, 500),
        quzhifu(){
          if(this.ext != null) {
            for(var i in this.form) {
                    if(i){
                        if(this.form[i].required == 1 && !this.form[i].value){
                          Toast.fail(`请填写${this.form[i].name}`);
                        return
                        }
                    }
                    }
          }

            // const customOption = []
            // this.ext.forEach(item => {
            //     customOption.push({ option_id: item.id, value: this.form['custom' + item.id] })
            // })
            // console.log(JSON.stringify(customOption))
            // const zidingyi = []
            // customOption.forEach(tanx => {
            //     if(tanx.value == "") {
            //         zidingyi.push(1)
            //     }
            // })
            // console.log(JSON.stringify(zidingyi[0]))
            // if(zidingyi[0] == '1') {
            //     console.log('拦截了')
            //     Toast.fail('请填写课程提醒');
            //     return
            // }
            // console.log('成功')
        // if (!this.platform) {
          if (!this.apilcheck && this.wxcheck){
            // 微信支付
            this.submitOrder()
          }else{
            // 支付宝
            const extObj = {}
            for(let i in this.form) {
            extObj[this.form[i].id] = this.form[i].value
            }
            console.log(extObj)
            let data = {
                goods_id:  this.$route.query.kechengidzf,
            }
            if(localStorage.getItem('tengface_fans_id')) {
                data.fans_id = localStorage.getItem('tengface_fans_id')
            }
            data = { ...data,...extObj }
            this.$liveApi.post('/liveapi/api/goods/submitOrder', data)
            .then(res=>{
                if(res.data.code == 200) {
                  this.coyshow=true
                  this.paydatile=false
                  this.confirm=false
                  this.maskshow2=false
                  const flag = 3
                  this.handlePayRequest(res.data.data.money, res.data.data.order_no, flag)
                }
            })
          }
        // }else{
        //   this.submitOrder()
        // }
      },
      async submitOrder(){
        const extObj = {}
        for(let i in this.form) {
        extObj[this.form[i].id] = this.form[i].value
        }
        console.log(extObj)
        let data = {
            goods_id:  this.$route.query.kechengidzf,
        }
        if(localStorage.getItem('tengface_fans_id')) {
                data.fans_id = localStorage.getItem('tengface_fans_id')
            }
        data = { ...data,...extObj }
        this.$liveApi.post('/liveapi/api/goods/submitOrder', data)
        .then(res=>{
            if(res.data.code == 200) {
              const flag = 1
              this.handlePayRequest(res.data.data.money, res.data.data.order_no, flag)
            }
        })
      
      }, 
      handlePayRequest(money, order_no, flag) {
            let data = {
                money: money,
                orderno:order_no,
                opid: this.opid,
                uid: this.uid,
                flag: flag,
            }
            if(localStorage.getItem('tengface_fans_id')) {
                data.fansid = localStorage.getItem('tengface_fans_id')
            }
            this.$onlineApi.post('/payapi/pcCartPay', data)
            .then(res=>{
                if(res.data.code == 200) {
                   if (this.pay_type == 2) {
                    if(flag == 1) {
                      window.location.href = res.data.data
                    } else {
                      this.zhifubaolj = res.data.data
                    }
                   } else {
                    this.showlist = true
                    if (this.qrcode) {
                      this.qrcode.clear();
                      this.qrcode.makeCode(url);
                    } else {
                      this.$nextTick(()=>{
                          this.transQrcode(res.data.data)
                      })
                    }
                    
                   }
                }
            })
        },
      
        transQrcode(item){
          this.qrcode =new QRCode('qrcodeDom',{
                width: 160,
                height:160,
                text: item,  //需要转换的链接
            // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
            // background: '#f0f',   // 背景色
            // foreground: '#ff0'    // 前景色
            })
        },

    qcheck(){
    // console.log(e);
        this.wxcheck=1,
          this.apilcheck=0
      },
      qcheck1(){
    // console.log(e);
        this.wxcheck=0,
          this.apilcheck=1
      },
    close1(){
        this.paydatile=false
      },
        // zhifuzhong() {
        //     const customOption = []
        //     this.ext.forEach(item => {
        //         customOption.push({ option_id: item.id, value: this.form['custom' + item.id] })
        //     })
        //     console.log(JSON.stringify(customOption))
        //     const zidingyi = []
        //     customOption.forEach(tanx => {
        //         if(tanx.value == "") {
        //             zidingyi.push(1)
        //         }
        //     })
        //     console.log(JSON.stringify(zidingyi[0]))
        //     if(zidingyi[0] == '1') {
        //         console.log('拦截了')
        //         Toast.fail('失败文案');
        //         return
        //     }
        //     console.log('成功')
        // },
        infolist() {
            const data = {
                id: this.$route.query.kechengidzf,
                ds_id: this.$store.state.user.org_id
            }
            if(localStorage.getItem('tengface_fans_id')) {
          data.fans_id = localStorage.getItem('tengface_fans_id')
      }
            this.$liveApi.post('/liveapi/api/goods/info', data)
            .then(res=>{
                if(res.data.code == 200) {
                  this.pay_type = res.data.data.pay_type
                  this.cover = res.data.data.cover
                  this.name = res.data.data.name
                  this.price = res.data.data.price
                  this.discount_price = res.data.data.discount_price
                  if (res.data.data.ext != null) {
                    this.ext = JSON.parse(res.data.data.ext)
                  } else {
                    this.ext = res.data.data.ext
                  }
                  this.opid = res.data.data.opid
                  this.uid = res.data.data.uid
                    if(this.ext != null) {
                    this.ext.forEach(item => {
                        this.$set(this.form, 'custom' + item.id, {value:'',required:item.required, name:item.name, id:item.id})
                    })
                  }
                }
            })
        },
        beforeCreate() {
          //  setAttribute('style', 'background-color:#f5f6f7')
           document.querySelector('body').style.background = '#f5f6f7'
        },
        fanhuilist() {
            this.$router.go(-1)
        }
    }
}
</script>
<style lang="less" scoped>
.zhifufangshiyi {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #202020;
    margin-top: 12px;
    margin-left: 14px;
    }
.bzt{
  position: relative;
}
.copy1{
  background: red;
  color: white;
  width: 60px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  margin-top: 10px;
  border-radius: 20px;
}
.coybx{
  margin-left: 10px;
}
.tzbxs{
  width: 19px;
  height: 19px;
  position: relative;
  top: -8px;
}
.tzbx{
  padding: 0 10px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.tzbx1{
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
}
.payclose{
  position: absolute;
  right: 20px;
  top: 15px;
  width: 20px;
  height: 20px;
}
.paytitle{
  width: 100%;
  text-align: center;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  margin-top: 18px;
}
.dialogpay{
  position: fixed;
  width: 100%;
  /* height: 610rpx; */
  /* border-radius: 20rpx; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  bottom: 0;
  background-color: white;
  z-index: 9999999;
}
.lijifuqian {
    width: 100%;
    height: 52px;
    background: #ff3700;
    font-size: 16px;
    font-family: PingFangSC-Medium;
    text-align: center;
    color: #ffffff;
    line-height: 52px;
}
.hejiqian {
    font-size: 16px;
    font-family: PingFangSC-Medium;
    color: #ff3700;
    line-height: 52px;
    font-weight: 600;
    }
.hejiyi {
    font-size: 13px;
    font-family: PingFangSC-Regular;
    color: #777777;
    line-height: 52px;
    margin-left: 15px;
    margin-right: 9px;
}
.lijizhifu {
    width: 100%;
    height: 52px;
    background: #ffffff;
    border-radius: 4px;
    position: fixed;
    bottom: 0;
    display: flex;
}
.paybx{
  padding: 0 14px;
  height: 24px;
  /* margin-bottom:15px ; */
  display: flex;
  justify-content: space-between;
}
.wxpaybx{
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.logot{
  width: 24px;
  height: 24px;
}
.checkin{
  width: 20px;
  height: 20px;
}
.qrpaybx{
  display: flex;
  margin-top: 240px;
  margin-bottom: 10px;
  justify-content: center;
}
.qrpay{
  width: 351px;
  line-height: 22px;
  color: #FFFFFF;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  background:#08C160;
  padding:13px 0 ;
}
.zhifufangshi {
    width: 355px;
    height: 143px;
    background: #ffffff;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 10px;
    overflow: hidden;
}
.shuruys {
    border-bottom: 0.5px solid #e9e9e9;
    width: 330px;
    margin-left: 10px;
}
.tixing {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #202020;
    margin-left: 20px;
    margin-top: 12px;
}
.zidingyi {
    width: 355px;
    height: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 10px;
    overflow: hidden;
}
.qianshu {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    color: #ff3700;
    margin-top: 29px;
    margin-left: 10px;
}
.ershiyi {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
    margin-top: 16px;
    margin-left: 10px;
}
.shangpt {
    width: 72px;
    height: 72px;
    background: rgba(0,0,0,0.00);
    border-radius: 4px;
    margin-top: 16px;
    margin-left: 14px;
}
.chanping {
    display: flex;
    width: 355px;
    height: 104px;
    background: #ffffff;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 14px;
}

.fanhui {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 44px;
  background-color: #fff;
  .back {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 44px;
    margin-left: 5px;
  }
  .querending {
    font-size: 17px;
    font-family: PingFangSC-Semibold;
    color: #101010;
    line-height: 44px;
    font-weight: 600;
  }
}
</style>