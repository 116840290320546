/**
 * 描述：整个系统文件上传所有的文件种类
**/

export const FileUpladType = {
    // 合同上传目录
    CONTRACT: 'contract',
    // 作业上传目录
    HOMEWORK: 'homework',
    // 与课程相关上传目录（笔记）
    COURSE: 'course',
    // 用户头像上传目录
    AVATAR: 'avator'
}