<template>
 <div class="wrap">
  <div class="header">
    <NavigateBar :title="'作业明细'" @handleBack="handleBack"/>
    <van-tabs v-model="active" color="#1872ed" @change="tabChange">
      <van-tab name="1">
        <template #title> 未完成 <span v-if="showTotal && active == 1" style="margin-left:3px" :style="active == 1?'color:#FF4229;':''">{{showTotal }}</span></template>
      </van-tab>
      <van-tab name="2">
        <template #title> 已完成</template>
      </van-tab>
      <van-tab name="3">
        <template #title> 草稿箱</template>
      </van-tab>
    </van-tabs>
  </div>
  <div class="line"></div>
  <div class="main">
    <div class="homework-item" v-for="item of homeworkDetailList" :key="item.qt_id">
      <div class="homework-item-left">
        <div class="homework-item-title">{{ item.name }} <span class="is-late">{{isLate(item.create_time,item.end_time)}}</span></div>
        <div style="display:flex;color:#7E8180;margin-top:10px">
          <div class="homework-item-chapter">题目数：<span>{{item.sum}}</span></div>
          <div class="homework-item-grade">班期：<span>{{className }}</span></div>
        </div>
        <div class="limit-time">
          截止：<span>{{ item.end_time }}</span>
        </div>
      </div>
      <div class="homework-item-right">
        <div class="unfinish" v-if="active == 2" :style="item.status == 1?'color:#FF4229':'color:#7E8180'">
          {{item.status == 1?'待批阅':'已批阅' }}
        </div>
        <div class="work-detail" @click="toQuestionPaperPage(item,'do')" v-if="active == 1">写作业</div>
        <div class="work-detail" @click="toQuestionPaperPage(item,'check')" v-else-if="active == 2 && item.status == 2">{{item.status == 1?'查看详情':'查看评语'}}</div>
        <div class="work-detail" @click="toQuestionPaperPage(item,'edit')" v-else-if="active == 2 && item.status == 1">修改作业</div>
        <div class="work-detail" @click="toQuestionPaperPage(item,'edit')" v-else-if="active == 3 && item.status == 1">写作业</div>
      </div>
    </div>
  </div>
 </div>
</template>
<script>
import NavigateBar from './navigateBar.vue';
import Vue from 'vue';
import { Tab, Tabs } from 'vant';

Vue.use(Tab);
Vue.use(Tabs);
export default {
   data() {
      return {
        active:'1',
        courseId:'',
        classId:'',
        chapterId:'',
        sectionId:'',
        sectioName:'',
        chapterName:'',
        className:'',
        courseName:'',
        homeworkDetailList:[],
        total:'',
        showTotal:0,
        pageSize:0,
        page:1,
        query:{}
      }
   },
   created(){
    const {active='1', showTotal=0} = JSON.parse(sessionStorage.getItem('cacheHomework') || '{}')
    this.active = active
    this.showTotal = showTotal
    const {course}=this.$route.query
    if(course){
        this.query = JSON.parse(decodeURIComponent(course))
        const {
          course_id,
          class_id,
          chapter_id,
          section_id,
          section_name,
          chapter_name,
          class_name,
          course_name} = this.query
          this.courseId = course_id,
          this.classId = class_id,
          this.chapterId = chapter_id,
          this.sectionId = section_id,
          this.sectioName = section_name
          this.chapterName = chapter_name
          this.className = class_name
          this.courseName= course_name
          this.getHomeWorkDetailList()
    }
   },
   mounted(){
    window.addEventListener('scroll',this.handleScroll,true)
    },
    beforeDestroy(){
      window.removeEventListener("scroll",this.handleScroll,true)
    },
   components:{
    NavigateBar
  },
   computed:{
    isLate(){
      return function(create_time,end_time){
        if(new Date(create_time).getTime()>new Date(end_time).getTime()){
          return '迟交'
        }else{
          return ''
        }
      }
    }
   },
   methods:{
    toQuestionPaperPage(item,type){
      const payload = {...this.query,...item,...{type}}
      sessionStorage.setItem('cacheHomework',JSON.stringify({active:this.active,showTotal:this.showTotal}))
      this.$router.push({path:'/questionPaper',query: {payload:encodeURIComponent(JSON.stringify(payload))}})
    },
    async getHomeWorkDetailList(){
      const data = {
        page:this.page,
        page_nums:10,
        course_id:this.courseId,
        class_id:this.classId,
        chapter_id:this.chapterId,
        section_id:this.sectionId
      }
      if(this.active == 2) {
        data.flag = 0
      }else if (this.active == 3) {
        data.flag = 1
      }
      const api = this.active == 1?'/user/index/not':'/user/index/already'
      const res = await this.$api.post(api,data)
      if(res.data.code == 200){
        this.homeworkDetailList = [...this.homeworkDetailList, ...res.data.data.chapter_question]
        if(this.active == 1){
          this.showTotal = res.data.data.incomplete
          this.total = res.data.data.incomplete
        }else if(this.active == 2){
          this.total = res.data.data.compete
        }else{
          this.total = res.data.data.drafts
        }
        this.pageSize = (this.total>10?Math.ceil(this.total/10):1)
      }
    },
    handleScroll(){
      let dom = window.document.documentElement || window.document.body
      //文档内容实际高度（包括超出视窗的溢出部分）
      let scrollHeight = Math.max(dom.scrollHeight, dom.scrollHeight);
      //滚动条滚动距离
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      //窗口可视范围高度
      let clientHeight = dom.innerHeight || Math.min(dom.clientHeight,dom.clientHeight);
      if(clientHeight + scrollTop >= scrollHeight){
        if(this.page >= this.pageSize ) return
        this.page++
        this.getHomeWorkDetailList()
      }
    },
    tabChange(){
      this.page = 1
      this.homeworkDetailList =[]
      this.getHomeWorkDetailList()
    },
    handleBack(){
      this.$router.go(-1)
    }
   },
}
</script>
<style lang="scss" scoped>
.wrap{
  width: 100%;
  height: 100%;
  background: #F6F6F6;
}
 .header{
    box-sizing: border-box;
    padding: 0 10px;
    background: #ffffff;
    padding-bottom: 7px;
    ::v-deep .van-tab--active{
      color: #101010 !important;
      font-weight: 600;
    }
    ::v-deep .van-tab{
      color: #7E8180;
      font-weight: 600;
    }
  }
  .line{
      width: 100%;
      height: 1px;
      background: #dddddd;
    }

  .main{
    box-sizing: border-box;
    padding: 10px;
    background: #f6f6f6;
    .homework-item{
      background: #ffffff;
      box-sizing: border-box;
      padding: 12px;
      border-radius: 10px;
      display: flex;
      margin-bottom: 10px;
      .homework-item-left{
        span{
          color: #202020;
        }
        .is-late{
          display: inline-block;
          font-size: 12px;
          background: #d9001b;
          color: #ffffff;
          padding: 0 5px;
          border-radius: 2px;
          font-weight: 400;
        }
      }
      .homework-item-right{
        margin-left: auto;
        display: flex;
        flex-direction: column;
        .unfinish{
          font-size: 12px;
          font-weight: 400;
          text-align: right;
          color: #FF4229;
          line-height: 20px;
          .num{
            color: #FF4229;
          }
        }
        .work-detail{
          width: 64px;
          height: 24px;
          background: linear-gradient(133deg,#73aaff 3%, #0053ff 91%);
          border-radius: 16px;
          font-size: 12px;
          font-weight: 400;
          color: #fefffe;
          line-height: 24px;
          text-align: center;
          margin-top: auto;
        }
      }
      .homework-item-title{
        font-size: 16px;
        font-weight: 600;
        color: #202020;
        line-height: 22px;
        font-size: 16px;
        max-width: 256px;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap
      }
      .limit-time{
        margin-top: 10px;
        font-size: 14px;
        color: #7e8180
      }
      .homework-item-chapter{
        // margin: 0 0 4px 0;
        font-size: 14px;
        color: #7e8180
      }
      .homework-item-grade{
        font-size: 14px;
        color: #7e8180;
        margin-left:33px;
        max-width: 150px;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
      }
    }
  }
</style>
