<template>
    <div style="background: #f6f6f6;overflow: hidden;">
        <div class="dangqianwj">
            <van-icon class="fanhui" name="arrow-left" color="#202020" size="28" @click="fanhui" />
            <div class="dangqianhz"><p class="shanghwj">{{ signInfo.name }}</p></div>
        </div>
        <div ref="pageWrapper" class="dangqiantu">
            <ul id="contractImgs">
                <li class="page" v-for="(item, index) in contractPictureList" :key="index">
                    <!-- <img class="hetongt" :src="`${item}?${new Date()}`" crossorigin="anonymous" @load="loadComplete(index)"> -->
                    <img class="hetongt" :src="`${item}`" crossorigin="anonymous" @load="loadComplete(index)">
                    <!-- 渲染公司方签署区域 -->
                    <template v-for="widget in currentPageComponySigns(index)">
                        <div v-if="widget.value":key="widget.id" class="widget area company-chapter" :style="[widgetStyles(widget)]">
                            <img :src="`${widget.value}?${new Date()}`" width="100px" crossorigin="anonymous" @click="chapterPreview(widget)">
                        </div>
                    </template>
                    <!-- 渲染当前页组件 -->
                    <template v-for="widget in currentPageWidgets(index)">
                        <!-- 个人签署区域组件 -->
                        <div v-if="widget.widget_type === 1 && !isSubmiting" :key="widget.id" class="widget area" :class="{ 'edit-disabled':isSubmiting }"
                            :style="[widgetStyles(widget), widgetFocus(widget)]" @click="handleSignClick(widget)">
                            <img v-if="widget.value" :src="`${widget.value}?${new Date()}`" :style="singImageStyles" crossorigin="anonymous" >
                            <span v-else>签署区域</span>
                        </div>
                        <!-- 日期组件 -->
                        <template v-if="widget.widget_type === 2">
                            <div v-if="signInfo.initiator === widget.label" :key="widget.id" class="widget date" :style="widgetStyles(widget)">{{ widget.value }}</div>
                            <!-- 个人方日期组件可以编辑 -->
                            <div v-else :key="widget.id" class="widget person date" :class="{ 'edit-disabled':isSubmiting }" 
                                :style="[widgetStyles(widget), widgetFocus(widget)]" @click="changeCurrentWidget(widget)">{{ widget.value || '签字自动生成日期'}}</div>
                            </template>
                        <!-- 文本组件 -->
                        <template v-if="widget.widget_type === 4">
                            <div v-if="signInfo.initiator === widget.label" :key="widget.id" class="widget text" :style="widgetStyles(widget)">
                                <span>{{ widget.value }}</span>
                            </div>
                            <!-- 个人方文本可以编辑 -->
                            <div v-else class="widget person text"  :class="{ 'edit-disabled':isSubmiting }" 
                                :style="[widgetStyles(widget), widgetFocus(widget)]" @click="showInuptPop(widget)">
                                <span v-if="!(isSubmiting && !widget.value)">{{ widget.value || widget.prompt }}</span>
                            </div>
                        </template>
                        <!-- 身份证 -->
                        <template v-if="widget.widget_type === 5">
                            <div v-if="signInfo.initiator === widget.label" :key="widget.id" class="widget id-card" :style="widgetStyles(widget)">
                                <span>{{ widget.value }}</span>
                            </div>
                            <!-- 个人方身份证可以编辑 -->
                            <div v-else :key="widget.id" class="widget person id-card"  :class="{ 'edit-disabled':isSubmiting }"
                                :style="[widgetStyles(widget), widgetFocus(widget)]" @click="showInuptPop(widget)">
                                <span v-if="!(isSubmiting && !widget.value)">{{ widget.value || widget.prompt }}</span>
                            </div>
                        </template>
                    </template>
                </li>
            </ul>
            <div>{{ fileTransObj }}</div>
        </div>
        <!-- 底部签署操作按钮 -->
        <div class="zuixiafang" v-if="this.signInfo.status === 1">
            <div class="juzhongjq">
                <p class="juqians" @click="showPopup">拒签</p>
                <div class="chakanqs" @click="submitContract">完成签署</div>
            </div>
        </div>
        <!-- 右侧快捷工具 -->
        <div class="moyelist">
            <div v-if="this.signInfo.status === 1" class="heziclass" @click="jumpNextWidget">
                <div><img src="../../../assets/images/dingwei.png" alt=""></div>
                <p class="linggangyi">{{ currentWidgetIndex }}/{{ personWidgets.length }}</p>
            </div>
            <div class="heziclass" @click="scrollToPage()">
                <div><img src="../../../assets/images/xiazai.png" alt=""></div>
                <p class="linggangyi">末页</p>
            </div>
            <div class="heziclass" style="border: none;" @click="downloadContractPdf">
                <div><img src="../../../assets/images/xiazaimo.png" alt=""></div>
                <p class="linggangyi">下载</p>
            </div>
        </div>
        <!-- 用户签章列表 -->
        <van-popup v-model="showSignPopSatus" position="bottom" :style="{ height: '50%' }">
            <div class="gerenqianm">
                <img class="jiahaotu" @click="tiaoqianming" src="../../../assets/images/tianjia.png" alt="">
                <p class="gerqm">个人签名</p>  
            </div>
            <div class="morenzhao" v-for="(item, index) in signList" :key="index">
                <div class="moren" v-if="item.default === 2">默认</div>
                <div class="zhaoyilu" @click="setUserSign(item)">
                    <img class="qianmingtu" :src="item.address" alt="">
                    <img v-if="item.default !== 2" class="shanchugq" src="../../../assets/images/shanchugq.png"
                        @click.stop="deleteUserSign(item)">
                </div>
            </div>
        </van-popup>
        <!-- 拒签弹框 -->
        <van-popup class="chehui" v-model="show">
            <div class="juqiants">
                <p class="juqianzi">执行[拒签] 操作，您拒绝签署本文件，本次签署将终止，请确认是否执行本操作?</p>
            </div>
            <div class="yuanying">
                请填写拒签原因<span style="color: #999999;">（必填）</span>
            </div>
            <div style="margin-top: 15px;">
                <van-field
                class="shuruk"
                v-model="refuseMessage"
                rows="6"
                autosize
                type="textarea"
                placeholder="请输入留言"
                />
            </div>
            <div class="anniukuang">
                <div class="quxiaoanniu" @click="show = false">取消</div>
                <div class="quedinganniu" @click="refuseSign">确定</div>
            </div>
        </van-popup>
        <!-- 同意签署短信验证 -->
        <van-popup class="sms-popup" v-model="showSmsPopup">
            <div class="sms-wrapper">
                <p class="title">短信验证</p>
                <div class="sms-content">
                    <p class="phone">接收手机号: {{ phoneEncrypt }}</p>
                    <div class="input-conent">
                        <input type="text" placeholder="请输入验证码" v-model="smsCode" >
                        <button :class="{ disabled : isSending }" @click="getSmsCode">
                            {{ isSending ? `${countdown}S重新发送` : '发送验证码' }}
                        </button>
                    </div>
                </div>
                <div class="footer">
                    <p class="cancel" @click="cancelSign">取消</p>
                    <p class="confirm" @click="confirmSign">确认</p>
                </div>
            </div>
        </van-popup>
        <!-- 输入文案 -->
        <van-popup class="input-popup" v-model="showInputTextPopup">
            <div class="input-wrapper">
                <p class="title">{{ currentWidgetPlaceholder || '请输入内容' }}</p>
                <div class="input-content">
                    <van-field
                        v-model="inputText"
                        rows="4"
                        autosize
                        type="textarea"
                        maxlength="50"
                        placeholder="请把内容控制在50字以内"
                    />
                </div>
                <div class="input-footer">
                    <button class="cancel" @click="cancelInput">取消</button>
                    <button class="confirm" @click="updateInputToWidget">确认</button>
                </div>
            </div>
        </van-popup>
        <!-- 加载laoding -->
        <van-popup class="loading-popup" v-model="isShowLoading">
             <van-loading color="#0094ff" vertical>
                {{ loadingText }}
            </van-loading>
        </van-popup>
    </div>
</template>

<script>
import jsPdf from 'jspdf'
import { ImagePreview, Toast } from 'vant'
import { mapGetters } from 'vuex'
import html2canvas from 'html2canvas'
// import uploadFile from '@/utils/cosUpload'
import { privateCosUpload } from '@/utils/privateCosUpload'
import { htmlToPng, dataURLtoBlob, blobToFile, getSystemDate, convertResToBlob } from '@/utils/index'

export default {
    beforeRouteLeave(to, form, next) {
        if(to.name !== 'SignaturePage') {
            this.$store.dispatch('contract/setContractImgs', [])
            this.$store.dispatch('contract/setWidgetSetting', [])
        } else {
            this.$store.dispatch('contract/setContractImgs', this.contractPictureList)
        }
        next()
    },
    data() {
        return {
            show: false,
            refuseMessage: '',
            signList: [],
            signInfo: {},
            smsCode: '',
            userPhone: '',
            countdown: 0,
            isSending: false,
            timer: null,
            inputText: '',
            isShowLoading: false,
            showInputTextPopup: false,
            isSubmiting: false,
            showSignPopSatus: false,            
            showSmsPopup: false,
            currentWidgetId: 0,
            currentWidgetIndex: 1,
            currentWidgetPlaceholder: '',
            contractPictureList: [],
            currentPageWidth: null,
            currentPageHeight: null,
            contract_template_set: [],
            fileTransObj: '',
            contract_sets: [],
            completeImgIndexs: [],
            jumpTimer: null,
            loadingText: '',
            // 默认为手绘签章
            isSignOpen: true,
            isDefaultSign: true,
            companySignImgs: []
        }
    },
    watch: {
        completeImgIndexs(val) {
            // 合同图片全部加载完，开始渲染组件，只有待签状态才渲染组件
            if(val.length === this.contractPictureList.length && this.signInfo.status === 1) {
                this.setPosition()
            }
        }
    },
    computed: {
        ...mapGetters({
			org_id:'user/getOrgId',
            contractImgs: 'contract/geContractImgs',
            widgetSetting: 'contract/getWidgetSetting'
		}),
        phoneEncrypt() {
            return this.userPhone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
        },
        personWidgets() {
            return this.contract_template_set.filter(item => item.label !== this.signInfo.initiator)
        },
        currentPageWidgets() {
            return (page) => {
                return this.contract_template_set.filter(item => Number(item.page) === (page + 1))
            }
        },
        currentPageComponySigns() {
            return (page) => {
                return this.companySignImgs.filter(item => Number(item.page) === (page + 1))
            }
        },
        widgetFocus() {
            return ({ id }) => {
                return {
                    borderStyle: `${ this.currentWidgetId === id ? 'solid' : 'dashed' }`,
                    boxShadow: `${ this.currentWidgetId === id ? '0px 0px 14px 2px rgba(11,90,198,0.22)' : 'none'}`,
                    zIndex: `${ this.currentWidgetId === id ? '99' : '9'}`
                }
            }
        },
        widgetStyles() {
            return ({ id, top, left, width, height, size, alignment }) => {
                const align = ['left', 'center', 'right']
                return {
                    top: `${top}px`,
                    left: `${left}px`,
                    // minWidth: `${width}px`,
                    height: `${height}px`,
                    fontSize: `${size || 14}px`,
                    // width: 'fit-content',
                    width: `${width}px`,
                    justifyContent: `${align[alignment-1]}`,
                    maxWidth: `calc(100% - ${left}px)`
                }
            }
        },
        imgStyles() {
            return ({ default: isDefault }) => {
                return {
                    transform: isDefault === 1 ? 'rotate(-90deg)' : 'rotate(0deg)'
                }
            }
        },
        /**
         * 描述：个人认证的签名图片高度需要设置成
        **/
        singImageStyles() {
            if(this.isDefaultSign) {
                return { height: '60px' }
            } else {
                return { width: '100px' }
            }
        }
    },
    async created() {
        await this.getUserSign()
        await this.getUserPhone()
        this.getDetail()
        this.getContractSignSetting()
    },
    mounted() {
        setTimeout(() => {
            document.title = ''
        }, 1000)
    },
    methods: {
        fanhui() {
            this.$router.go(-1)
        },
        showPopup() {
            this.show = true
        },
        /**
         * 描述：是否默认签名，默认签名是个人认证时自动生成的签名
        **/
        checkIsDefaultSign() {
            const defaultSignItem = this.signList.find(item => item.default === 2)
            const signItem = this.personWidgets.find(({ widget_type, value }) => widget_type === 1 && value)
            if(signItem) {
                this.isDefaultSign = signItem.value === defaultSignItem.address
            }
        },
        tiaoqianming() {
            this.$store.dispatch('contract/setWidgetSetting', this.contract_template_set)
            const { id } = this.personWidgets[this.currentWidgetIndex - 1]
            this.$router.push({
                path: "/SignaturePage",
                query: {
                    id,
                    contractId: this.$route.query.id,
                    signId: this.$route.query.sign_id,
                    initiator: this.signInfo.initiator
                }
            })
        },
        /**
         * 描述：点击个人签署区域时，如果开启了强制手绘，则直接跳转到手绘页面，未开启则显示签名列表
        **/
        handleSignClick(info) {
            this.changeCurrentWidget(info)
            if(this.isSignOpen) {
                this.tiaoqianming()
            } else {
                this.showSignList(info)
            }
        },
        showSignList(widget) {
            this.showSignPopSatus = true
            this.getUserSign()
        },
        showInuptPop(widget) {
            this.changeCurrentWidget(widget)
            const { value, prompt } = this.personWidgets[this.currentWidgetIndex - 1]
            this.inputText = value
            this.currentWidgetPlaceholder = prompt
            this.showInputTextPopup = true
            
        },
        /**
         * 描述：定位组件位置，去掉骑缝章和签章签署区域，因为发起合同时已经将公司签章和骑缝章合成到图片中
        **/
        setPosition() {
            this.currentPageWidth = document.querySelector('.page').offsetWidth
            this.currentPageHeight = document.querySelector('.page').offsetHeight
            this.contract_sets.forEach(widget => {
                let { limit_x, limit_y, limit_width, limit_height, real_height, real_weight, widget_type, label, value, type, size:font } = widget
                // 如果是个人方签署区域，可能存在模板会有公司签章的问题，需要置空
                if(widget_type === 1 && this.signInfo.initiator !== label) {
                    value = ''
                }
                // 过滤掉骑缝章组件和公司方的签章组件
                // if(!(widget_type === 3 || (widget_type === 1 && value))) {
                // 过滤掉骑缝章组件
                if(widget_type !== 3){
                    const top = limit_y/limit_height*this.currentPageHeight
                    const left = limit_x/limit_width*this.currentPageWidth
                    const width = this.currentPageWidth/limit_width*real_weight
                    const height = this.currentPageHeight/limit_height*real_height
                    const size = this.currentPageHeight/limit_height*font
                    const item = {
                        ...widget,
                        top,
                        left,
                        width,
                        height,
                        size,
                        value
                    }
                    if(this.signInfo.initiator !== label) {
                        // 个人方组件自动填充用户姓名、手机号、身份证
                        if(type === 1) {
                            item.value = this.signInfo.phone || item.value
                            item.prompt = '请输入手机号'
                        } else if(type === 2) {
                            item.value = this.signInfo.user_name || item.value
                            item.prompt = '请输入姓名'
                        } else if(type === 3) {
                            item.value = this.signInfo.card || item.value
                            item.prompt = '请输入身份证'
                        }
                    }
                        // 保存公司方的签章
                    if(this.signInfo.initiator === label && widget_type === 1) {
                        this.companySignImgs.push(item)
                    } else {
                        this.contract_template_set.push(item)
                    }
                }
            })
            this.initByStorage()
            this.currentWidgetId = this.personWidgets[0].id
        },
        /**
         * 描述：获取合同详情
        **/
        async getDetail() {
            const { id, sign_id } = this.$route.query
            if(!id || !sign_id) {
                Toast('页面参数错误，请重新从合同列表进入')
                return
            }
            const { data: { code, data: resData } } = await this.$api.post('/user/app/contractInfo', {
                id,
                sign_id
            })
            if(code === 200) {
                const { sign, contract_set, contract_picture } = resData
                this.signInfo = sign
                this.contract_sets = contract_set
                if(this.contractImgs.length > 0) {
                    this.contractPictureList = this.contractImgs
                    return
                }
                if(contract_picture.length > 0) {
                    // 如果提前转码了合同，直接使用自动转码的图片
                    this.contractPictureList = contract_picture.map(item => {
                        return item.route
                    })
                    return
                }
                // const { data: { data: privateContractUrl } } = await this.$api.post('/user/app/cosSign', {
                //     address: sign.address || sign.contract
                // })
                const url = sign.address || sign.contract
                // 签署完成显示签署后的合同文件
                this.contractPdfTransforImgs(url)
            }
       },
        /**
         * 描述：获取个人签章 
        **/
        async getUserSign() {
            const { data: { code, data: resData } }  = await this.$api.post('/user/app/signatureList')
            if(code === 200) {
                this.signList = resData
            }
        },
        /**
         * 描述：删除个人签名
        **/
        async deleteUserSign({ id }) {
            const { data: { code } } = await this.$api.post('/user/app/cutSignature', { id })
            if(code === 200) {
                Toast('删除成功')
                this.getUserSign()
            } else {
                Toast('删除失败')
            }
        },
        /**
         * 描述：用户确认签名
        **/
        setUserSign({ address, default: isDefault }) {
            for(let i = 0, len = this.contract_template_set.length; i < len; i++) {
                const item = this.contract_template_set[i]
                if(item.widget_type === 1) {
                    item.value = address
                    item.default = isDefault
                } else if(item.widget_type === 2 && item.label !== this.signInfo.initiator) {
                    item.value = getSystemDate()
                }
            }
            this.showSignPopSatus = false
            this.checkIsDefaultSign()
        },
        /**
         * 描述：完成签署
        **/
        async submitContract() {
            // await this.createPdfFileByFile()
            // return false
            if(!this.checkWidget()) {
                return
            }
            this.showSmsPopup = true
        },
        cancelSign() {
            this.smsCode = ''
            this.showSmsPopup = false
        },
        async confirmSign() {
            if(!this.smsCode) {
                Toast('请输入验证码')
                return
            }
            if(this.smsCode.length !== 4) {
                Toast('验证码长度为4位')
                return
            }
            if(this.isSubmiting) {
                return
            }
            this.showSmsPopup = false
            this.isSubmiting = true
            this.changeLoadingModal(true, '正在解析合同文件')
            try {
                // await this.findIsEditPagesAndReplace()
                const url = await this.createPdfFileByFile()
                await this.saveContractList(url)
                this.isSubmiting = false
            } catch(error) {
                console.log('error: ', error);
                Toast('合同签署失败')
            } finally {
                this.isSubmiting = false
                this.changeLoadingModal(false)
            }
        },
        /**
         * 描述：校验个人方组件是否已填写完毕，公司方不需要校验
        **/
        checkWidget() {
            for(let i = 0,len = this.contract_template_set.length; i < len; i++) {
                const { must, value, label, widget_type } = this.contract_template_set[i]
                if(this.signInfo.initiator !== label && must === 2 && !value) {
                    Toast('请将需要签署的信息补充完整')
                    return false
                }
                if(this.signInfo.initiator !== label && widget_type === 1 && !value) {
                    Toast('请点击签署区域添加您的签名')
                    return false
                }
            }
            return true
        },
        /**
         * 描述：对已编辑的合同页截图，上传到oss，并替换原始合同页
        **/
        async findIsEditPagesAndReplace() {
            // const pageElms = document.querySelectorAll('li.page')
            // let pages = []
            // this.contract_template_set.forEach(widget => {
            //     const { page, widget_type } = widget
            //     // 骑缝章所在页码不需要生成截图
            //     if(widget_type !== 3 ) {
            //         pages.push(page)
            //     }
            // })
            // // 页码去重
            // pages = new Array(...new Set(pages))
            // while(pages.length > 0) {
            //     const pageIndex = Number(pages.pop())
            //     const elm = pageElms[pageIndex - 1]
            //     const fileBase64 = await htmlToPng(elm)
            //     const blob = dataURLtoBlob(fileBase64)
            //     const file = blobToFile(blob, `${this.signInfo.number}_${pageIndex}.png`)
            //     const url = await uploadFile(file)
            //     this.contractPictureList[pageIndex - 1] = url
            // }
        },
        /**
         * 描述：拼接签名参数
        **/
        getSignParams() {
            // A4z纸张大小是595*842，放心签那边是以该尺寸的纸张大小进行签署的
            const defaultWidth = 595
            const defaultHeight = 842
            // 100是个人章的图片偏移误差，不处理会导致签署y轴位置偏移过大
            // const defaultSkew = 80/window.devicePixelRatio
            const defaultSkew = 100/window.devicePixelRatio
            // 20是公司章的图片跟署区域组件的内边距，不处理会导致签署位置偏移过大
            const defaultCompanySkew = 20/window.devicePixelRatio
            // 获取个人方签章位置及页码
            const personSigns = this.personWidgets.filter(item => item.widget_type === 1).map(({ top, left, height, limit_x, limit_y, limit_width, page, value, real_height }) => {
                return {
                    // xy_qsqy_x: Math.floor(limit_x/limit_width*this.currentPageWidth),
                    // xy_qsqy_y: Math.floor(this.currentPageHeight - top),
                    // 下面的548和775是后台添加合同模板时合同的宽高
                    xy_qsqy_x: Math.floor(defaultWidth/548*Number(limit_x) + defaultSkew),
                    xy_qsqy_y: Math.floor(defaultHeight - (defaultHeight/775*Number(limit_y)) - (defaultHeight/775*Number(real_height)) - defaultSkew),
                    xy_qsqy_page: page,
                    signature_address: value
                }
            })
            // 获取公司方签章位置及页码
            const companySigns = this.contract_sets
                .filter(item => item.label === this.signInfo.initiator && item.widget_type === 1)
                .map(({ limit_x, limit_width, limit_y, limit_height, real_height, page }) => {
                    const height = this.currentPageHeight/limit_height*real_height
                    return {
                        // 公司章xy在页面初始化时没有计算，在这里需要单独单独计算（height/2 是只计算组件高度的一半，否则位置会偏高）
                        // qsqy_x: Math.floor(limit_x/limit_width*this.currentPageWidth),
                        // qsqy_y: Math.floor(this.currentPageHeight - limit_y/limit_height*this.currentPageHeight),
                        qsqy_x: Math.floor(defaultWidth/548*Number(limit_x)),
                        // qsqy_y: Math.floor(defaultHeight - (defaultHeight/775*Number(limit_y)) - (defaultHeight/775*Number(real_height)) + defaultCompanySkew),
                        qsqy_y: Math.floor(defaultHeight - (defaultHeight/775*Number(limit_y)) - (defaultHeight/775*Number(real_height)) + defaultCompanySkew),
                        qsqy_page: page
                    }
                })
            return { personSigns, companySigns }
        },
        /**
         * 描述：保存最新的合同文件列表
        **/
        async saveContractList(url) {
            const { personSigns, companySigns } = this.getSignParams()
            this.changeLoadingModal(true, '正在保存合同文件')
            const { id } = this.$route.query
            // 从组件中获取签章i
            const { value } = this.personWidgets.find(item => item.widget_type === 1)
            const { data: { code, message } } = await this.$api.post('/user/app/contractComplete', {
                id,
                file: url,
                phone: this.userPhone,
                code: this.smsCode,
                signature_address: value,
                student_json: JSON.stringify(personSigns),
                company_json: JSON.stringify(companySigns)
            })
            if(code === 200) {
                Toast('签署成功')
                this.transforContractPdfToImgs(id)
                this.$router.replace({ name: 'FileDetails', query: { id } })
            } else {
                Toast(message || '签署失败')
            }
            this.isSubmiting = false
            this.changeLoadingModal(false)
        },
        /**
         * 描述：图片加载完成回调
        **/
        loadComplete(index) {
            if(!this.completeImgIndexs.some(item => item === index)) {
                this.completeImgIndexs.push(index)
            }
        },
        /**
         * 描述：当前active组件
        **/
        changeCurrentWidget({ id }) {
            this.currentWidgetId = id
            this.currentWidgetIndex = this.personWidgets.findIndex(item => item.id === id) + 1
        },
        /**
         * 描述：跳转下一个需要填写的组件
        **/
        jumpNextWidget() {
            if(this.currentWidgetIndex === this.personWidgets.length) {
                this.currentWidgetIndex = 1
            } else {
                this.currentWidgetIndex++
            }
            const widget = this.personWidgets[this.currentWidgetIndex - 1]
            const { id, page, value, widget_type, prompt } = widget
            this.currentWidgetId = id
            this.currentWidgetPlaceholder = prompt
            this.scrollToPage(page)
            if(this.jumpTimer) {
                clearTimeout(this.jumpTimer)
                this.jumpTimer = null
            }
            this.jumpTimer = setTimeout(() => {
                this.inputText = value
                if(widget_type === 1) {
                    // this.getUserSign()
                    // this.showSignPopSatus = true
                    this.handleSignClick(widget)
                } else if(widget_type === 4 || widget_type === 5){
                    this.showInputTextPopup = true
                }
            }, 1000)
        },
        /**
         * 描述：跳转到末页
         * * @param   : page {number} 页码，没传则跳转到末页
        **/
        scrollToPage(page) {
            let scrollHeight = 0
            if(page) {
                const currentPageElm = document.querySelectorAll('.page')[page - 1]
                scrollHeight = currentPageElm.offsetTop - document.querySelector('.dangqianwj').offsetHeight
            } else {
                scrollHeight = this.$refs.pageWrapper.scrollHeight - this.$refs.pageWrapper.clientHeight
            }
            this.$refs.pageWrapper.scrollTo({ top: scrollHeight, left: 0, behavior: 'smooth' })
        },
        /**
         * 描述：获取用户手机号
        **/
        async getUserPhone() {
            const { data: { code, data:resData } } = await this.$api.post('/user/index/getUserInfo')
            if(code === 200) {
                this.userPhone = resData.phone
            }
        },
        /**
         * 描述：获取短信验证码
        **/
        async getSmsCode() {
            if(this.isSending) {
                return
            }
            this.smsCountDown()
            const data = {
                phone: this.userPhone,
                area: 86,
                org_id: this.org_id
            }
            const { data: { code } } = await this.$api.post('/common/index/sendSms', data)
            if(code === 200) {
                Toast('验证码发送成功')
            } else {
                Toast('验证码发送失败，请检查网络')
            }
        },
        /**
         * 描述：短信倒计时
        **/
        smsCountDown() {
            this.isSending = true
            this.countdown = 60
            this.timer = setInterval(() => {
                if(this.countdown > 0) {
                    this.countdown--
                } else {
                    this.isSending = false
                    clearInterval(this.timer)
                    this.timer = null
                }
            }, 1000)
        },
        /**
         * 描述：拒签合同
        **/
        async refuseSign() {
            if(!this.refuseMessage) {
                Toast('请填写拒签理由')
                return
            }
            if(this.refuseMessage.length > 50) {
                Toast('拒签理由限制50个字')
                return
            }
            const { id, sign_id } = this.$route.query
            const { data: { code } } = await this.$api.post('/user/app/contractResist', {
                id,
                sign_id,
                reason: this.refuseMessage
            })
            if(code === 200) {
                Toast('拒签成功')
                this.$router.replace({ name: 'FileDetails', query: { id } })
            } else {
                Toast('拒签失败')
            }
        },
        /**
         * 描述：初始化缓存里面的数据
        **/
        initByStorage() {
            if(!this.widgetSetting) {
                return
            }
            this.widgetSetting.forEach(widget => {
                const index = this.contract_template_set.findIndex(template => template.id === widget.id)
                this.contract_template_set[index].value = widget.value
                if(widget.widget_type === 1) {
                    this.contract_template_set[index].default = widget.default
                }
            })
            this.checkIsDefaultSign()
        },
        downloadContractToPdf() {
            // html2canvas(document.querySelector('#contractImgs'), {
            //     useCORS: true,
            //     allowTaint: true
            // }).then(async (canvas) => {
            //     let contentWidth = canvas.width
            //     let contentHeight = canvas.height
            //     let pageHeight = contentWidth / 592.28 * 841.89
            //     let leftHeight = contentHeight
            //     let position = 0
            //     let imgWidth = 595.28
            //     let imgHeight = 592.28 / contentWidth * contentHeight
            //     let pageData = canvas.toDataURL('image/jpeg', 1.0)
            //     let PDF = new jsPdf('', 'pt', 'a4')
            //     if (leftHeight < pageHeight) {
            //         PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
            //     } else {
            //         while (leftHeight > 0) {
            //             PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            //             leftHeight -= pageHeight
            //             position -= 841.89
            //             if (leftHeight > 0) {
            //                 PDF.addPage()
            //             }
            //         }
            //     }
            //     PDF.save(`${this.signInfo.name}.pdf`)
            // })
        },
        /**
         * 描述：构建HtmlImageElement，然后下载
        **/
        createImgElmAndDownload() {
            // try {
            //     const PDF = new jsPdf('p', 'px', 'a4')
            //     // const imgWidth = 595.28
            //     // const imgHeight = 841.89
            //     var imgWidth = PDF.internal.pageSize.getWidth()
            //     var imgHeight = PDF.internal.pageSize.getHeight()
            //     // let position = 0
            //     this.contractPictureList.forEach((route, index) => {
            //         console.log(index);
            //         const imgElm = new Image()
            //         imgElm.src = `${route}?${new Date()}`
            //         imgElm.crossOrigin = 'anonymous'
            //         // imgElm.style.width = '100%'
            //         // imgElm.style.height = '841px'
            //         PDF.addImage(imgElm, 'JPEG', 0, 0, imgWidth, imgHeight)
            //         // // position -= 841.89
            //         if(this.contractPictureList.length > (index + 1)) {
            //             PDF.addPage()
            //         }
            //     })
            //     PDF.save(`${this.signInfo.name}.pdf`)
            // } catch(error) {
            // }
        },
        cancelInput() {
            this.inputText = ''
            this.showInputTextPopup = false
        },
        updateInputToWidget() {
            const { id } = this.personWidgets[this.currentWidgetIndex - 1]
            const widget = this.contract_template_set.find(item => item.id === id)
            widget.value = this.inputText
            this.cancelInput()
        },
        /**
         * 描述：生成PDF文件并上传到oss
        **/
        createPdfFileByFile() {
            return new Promise((resolve, reject) => {
                try {
                    this.$nextTick(async () => {
                        this.changeLoadingModal(true, '正在生成合同文件')
                        // const PDF = await this.getPdfFileInstance()
                        const PDF = await this.buildContractFileUrl()
                        this.changeLoadingModal(true, '正在上传合同文件')
                        const fileStream = PDF.output('datauristring')
                        const blobObj = dataURLtoBlob(fileStream)
                        const fileObj = blobToFile(blobObj, `${this.signInfo.name}.pdf`)
                        const { url } = await privateCosUpload(fileObj)
                        resolve(url)
                    })
                } catch(error) {
                    console.log('error: ', error)
                    Toast('合同文件生成失败')
                    reject(error)
                } finally {
                    this.isSubmiting = false
                    this.changeLoadingModal(false)
                }
            })
        },
        /**
         * 描述：生成PDF截图文件流对象
        **/
        getPdfFileInstance() {
            return new Promise((resolve, reject) => {
                try {
                    const imgsElm = document.querySelector('#contractImgs')
                    html2canvas(imgsElm, {
                        useCORS: true,
                        allowTaint: true,
                        // 固定放大倍率，否则部分ios机型会不兼容，生成的canvas空白
                        scale: 2
                    }).then(async (canvas) => {
                        let contentWidth = canvas.width
                        let contentHeight = canvas.height
                        // let pageHeight = contentWidth / 592.28 * 841.89
                        // let leftHeight = contentHeight
                        let position = 0
                        let imgWidth = 595.28
                        let imgHeight = 592.28 / contentWidth * contentHeight
                        let pageData = canvas.toDataURL('image/jpeg', 1.0)
                        let PDF = new jsPdf('', 'pt', 'a4')
                        // if (leftHeight < pageHeight) {
                        //     PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                        // } else {
                        //     while (leftHeight > 0) {
                        //         PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                        //         leftHeight -= pageHeight
                        //         position -= 841.89
                        //         if (leftHeight > 0) {
                        //             PDF.addPage()
                        //         }
                        //     }
                        // }
                        for(let i = 0, len = this.contractPictureList.length; i < len; i++) {
                            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                            if(i < len - 1) {
                                position -= 841.89
                                PDF.addPage()
                            }
                        }
                        resolve(PDF)
                    })
                } catch (error) {
                    reject(error)
                }
            })
        },
        /**
         * 描述：合同pdf地址转图片列表
        **/
        contractPdfTransforImgs(url) {
            this.changeLoadingModal(true, '正在加载合同文件')
            this.$api.post('/user/app/changePic', {
                file: decodeURIComponent(url)
            }).then(({ data: { code, data: resData } }) => {
                if(code === 200) {
                    this.contractPictureList = resData
                }
                this.changeLoadingModal(false)
            })
        },
        /**
         * 描述：转码合同图片，不需处理后台响应
        **/
        transforContractPdfToImgs(id) {
            this.$api.post('/user/app/contractFinalUpload', { c_id: id })
        },
        /**
         * 描述：下载文件流
        **/
        async downloadContractPdf() {
            this.changeLoadingModal(true, '正在生成下载文件')
            // if(this.signInfo.status === 2) {
                const { data: { code, data: privateContractUrl } } = await this.$api.post('/user/app/cosSign', {
                    address: decodeURIComponent(this.signInfo.address || this.signInfo.contract)
                })
                if(code !== 200 ) {
                    Toast('获取合同文件失败')
                    return
                }
                this.changeLoadingModal(false)
                window.location.href = privateContractUrl
                // return
            // }
            // this.$api({
            //     method: "get",
            //     url: address,
            //     headers: {
            //         "content-type": "application/json; charset=utf-8",
            //     },
            //     responseType: "blob",
            // }).then(res => {
            //     convertResToBlob(res.data, `${this.signInfo.name}.pdf`)
            //     this.changeLoadingModal(false)
            // })
        },
        /**
         * 描述：生成合同文件
        **/
        buildContractFileUrl() {
            return new Promise(async (resolve, reject) => {
                // const { clientWidth, clientHeight } = document.querySelector('#contractImgs').querySelector('.page')
                // 796*1138 是A4纸张大小在屏幕没有缩放时显示的px大小，使用该尺寸通过canvas绘制的合同图片在转成pdf时大小是一致的
                const clientWidth = 796
                const clientHeight = 1138
                // 实例化jspdf插件, ， API参数具体解释参考：https://www.jianshu.com/p/43d69b8ff8e8
                // const pdFInstance = new jsPdf('p', 'px', [clientWidth, clientHeight])
                const pdFInstance = new jsPdf('p', 'mm', 'a4')
                // 循环合同，查询当前页是否具有组件，有组件则需要使用canvas重新生成图片，无组件直接使用原图片即可
                for(let i = 0, len = this.contractPictureList.length; i < len; i++) {
                    const widgets = this.currentPageWidgets(i)
                    const page = this.contractPictureList[i]
                    const pageElm = await this.drawPageByCanvas(page, widgets, clientWidth, clientHeight)
                    const pageData = pageElm.toDataURL('image/jpeg', 1)
                    await this.imageLoad(pageData, (imgElm) => {
                        const { width, height } = imgElm
                        // document.body.appendChild(imgElm)
                        // pdFInstance.addImage(imgElm, 'JPEG',  0, 0, width/2, height/2, '', 'NONE', 0)
                        // pdFInstance.addImage(imgElm, 'JPEG',  0, 0, clientWidth, clientHeight, '', 'NONE', 0)
                        pdFInstance.addImage(imgElm, 'JPEG',  0, 0)
                        pdFInstance.addPage()
                    })
                }
                // 删除最后一页留白
                const lastPage = pdFInstance.internal.getNumberOfPages()
                pdFInstance.deletePage(lastPage)
                resolve(pdFInstance)
            })
        },
        /**
         * 描述：使用canvas绘制当前有组件的合同页，没有组件的直接使用合同图片即可
         *       canvas画布大小整体放大2倍，防止图片像素绘制重叠出现过于模糊的问题
         *      参考：https://blog.csdn.net/harry_yaya/article/details/105964081
         * @return  : {string} canvas的base64图片
        **/
        drawPageByCanvas(contractImgUrl, widgets, width, height) {
            return new Promise((resolve, reject) => {
                try {
                    const imgElm = new Image()
                    imgElm.crossOrigin = 'anonymous'
                    imgElm.style.width = `${width}px`
                    imgElm.style.height = `${height}px`
                    imgElm.onload = () => {
                        const canvasElm = document.createElement('canvas')
                        // canvasElm.width = width*2
                        // canvasElm.height = height*2
                        canvasElm.width = width
                        canvasElm.height = height
                        const canvasCxt = canvasElm.getContext('2d')
                        // canvasCxt.drawImage(imgElm, 0, 0, width*2, height*2)
                        canvasCxt.drawImage(imgElm, 0, 0, width, height)
                        widgets.forEach(({ top, left, size, value, widget_type, limit_x, limit_y }) => {
                            // 只绘制文本组件、身份证组件、日期组件
                            if(widget_type === 2 || widget_type === 4 || widget_type === 5) {
                                size = size === 0 ? 14 : size
                                canvasCxt.font = `${size*2}px Helvetica`
                                // this.drawText(canvasCxt, value, left*2, top*2, width*2)
                                this.drawText(canvasCxt, value, limit_x, limit_y, width)
                            }
                        })
                        resolve(canvasElm)
                    }
                    imgElm.src = `${contractImgUrl}`
                } catch (error) {
                    reject(error)
                }
            })
        },
        /**
         * 描述：canvas绘制文字，需要处理文字过长换行的情况
        **/
        drawText(content, text, x, y, width) {
            if(!content || !text) {
                return
            }
            // 处理y轴偏移量，画布绘制的y轴起点与document存在偏差
            // 595px*842px是A4纸张的像素大小
            // 548px*775px是珊瑚后台设置合同模板的像素大小
            // y = y + 35
            y = 1138/775*Number(y) + 35
            x = 796/548*Number(x)
            let index = 0
            let lineStr = ''
            let strLineWidth = 0
            const lineStrList = []
            const maxLen = width - x
            // 循环判断绘制当前字符时是否会超出canvas最大宽度
            while(index < text.length) {
                const str = text.slice(index, index + 1)
                const { width } = content.measureText(str)
                strLineWidth +=  width
                if(strLineWidth > maxLen) {
                    strLineWidth = width
                    lineStrList.push(lineStr)
                    lineStr = str
                } else {
                    lineStr = lineStr + str
                }
                index++
            }
            // 添加末尾字符
            if(lineStr) {
                lineStrList.push(lineStr)
            }
            // 绘制文字，数组的每一项绘制为单独的一行
            while(lineStrList.length > 0) {
                const str = lineStrList.shift()
                content.fillText(str, x, y)
                // 40是换行时下一行距上一行的偏移量
                y = y + 40
            }
        },
        imageLoad(url, callback) {
            return new Promise((resolve, reject) => {
                try {
                    const img = new Image()
                    img.src = url
                    img.onload = function() {
                        callback(img)
                        resolve()
                    }
                } catch(error) {
                    console.log(error)
                    reject()
                }
            })
        },
        /**
         * 描述：是否显示laoding弹框
         * @param   : status {boolean} , text {string}
        **/
        changeLoadingModal(status, text) {
            this.isShowLoading = status
            if(status) {
                this.loadingText = text
            } else {
                this.loadingText = ''
            }
        },
        /**
         * 描述：获取是否强制手绘签名权限
        **/
       getContractSignSetting() {
            this.$api.post('/user/index/config').then(({ data: { code, data } }) => {
                if(code === 200) {
                    const signPower = data.find(({ key }) => key === 'contract_sign_must_write')
                    if(signPower) {
                        this.isSignOpen = signPower.value === 'true'
                    }
                }
            })
       },
       chapterPreview({ value }) {
            ImagePreview([value])
       }
    },
    beforeDestroy() {
        if(this.timer) {
            clearInterval(this.timer)
            this.timer = null
        }
    }
}
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
}
.dangqiantu {
    .page {
        .widget {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            &.area, &.person {
                background: #f8faff;
                color: #73aaff;
                border: 1px dashed #1872ed;
                word-break: break-all;
            }
            // &.area img{
            //     transform: rotate(-90deg);
            // }
            &.edit-disabled {
                background: inherit;
                color: #000000;
                border-color: transparent;
            }
            .person-input {
                width: 100%;
                height: 100%;
                border: none;
                padding: 0 2px;
            }
            &.date {
                font-size: 12px !important;
            }
            &.company-chapter {
                background: transparent;
                border: none;
                box-shadow: none;;
            }
        }
    }
}
.qianmingtu {
    // width: 100%;
    height: 100%;
    // transform: rotate(270deg);
}
.quedinganniu {
    width: 52px;
    height: 30px;
    background: linear-gradient(120deg,#73aaff 30%, #0053ff 100%);
    border-radius: 4px;
    font-size: 12px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    margin-left: 16px;
}
.quxiaoanniu {
    width: 52px;
    height: 30px;
    background: #dfe3e8;
    border-radius: 4px;
    font-size: 12px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #202020;
    line-height: 30px;
    text-align: center;
}
.anniukuang {
    display: flex;
    width: 120px;
    margin: 0 auto;
    margin-top: 16px;
}
.shuruk {
    width: 295px;
    height: 161px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    margin: 0 auto;
}
.yuanying {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #202020;
    margin-top: 16px;
    margin-left: 20px;
}
.juqianzi {
    font-size: 12px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #fff;
    margin-left: 20px;
    margin-right: 15px;
    margin-top: 14px;
}
.juqiants {
    width: 335px;
    height: 56px;
    background: #1872ed;
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
}
.chehui {
    width: 335px;
    height: 330px;
    background: #ffffff;
    border-radius: 8px;
}
.shanchugq {
    position: absolute;
    top: 10px;
    right: 0;
    width: 24px;
    height: 24px;
    float: right;
    margin-top: 16px;
    margin-right: 16px;
}
.morenzhao {
    border-bottom: 1px solid #e5e5e5;
    height: 140px;
    /* display: flex; */
    overflow: hidden;
}
.moren {
    width: 50px;
    height: 22px;
    background: #ffedde;
    border-radius: 0px 0px 10px 0px;
    font-size: 12px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    text-align: center;
    line-height: 22px;
    color: #ff9a40;
    position: absolute;
}
.zhaoyilu {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.jiahaotu {
    float: right;
    margin-right: 16px;
    margin-top: 16px;
}
.tianjiaan {
    float: right;
}
.gerqm {
    font-size: 16px;    
    font-weight: 500;
    color: #000000;
}
.gerenqianm {
    width: 100%;
    height: 55px;
    background: #fff;
    text-align: center;
    border-bottom: 1px solid #e5e5e5;
    line-height: 55px;
}
.juqians {
    font-size: 17px;
    font-family: Noto Sans SC, Noto Sans SC-500;
    font-weight: 500;
    color: #666666;
    margin-top: 20px;
}
.chakanqs {
    width: 280px;
    height: 45px;
    background: #1872ed;
    border-radius: 4px;
    font-size: 17px;
    font-family: Noto Sans SC, Noto Sans SC-500;
    font-weight: 500;
    color: #ffffff;
    line-height: 45px;
    text-align: center;
    margin-left: 30px;
    margin-top: 10px;
}
.juzhongjq {
    display: flex;
    width: 345px;
    margin: 0 auto;
}
.zuixiafang {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 65px;
    background: #ffffff;
    margin-top: 10px;
    box-shadow: 0 10px 10px 10px #ccc;
}
.dangqiantu {
    height: calc(100vh - 109px);
    background: rgb(246, 246, 246);
    padding: 10px 0;
    margin-top: 44px;
    box-sizing: border-box;
    overflow: auto;
    .page {
        position: relative;
    }
}
.dibuqs {
    width: 100%;
    height: 65px;
    background: #ffffff;
    margin-top: 10px;
}
.linggangyi {
    font-size: 12px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #666666;
}
.heziclass {
    text-align: center;
    border-bottom: 1px solid #d9d7d7;
    width: 27px;
    margin: 0 auto;
    margin-top: 6px;
    padding-bottom: 4px;
}
.moyelist {
    width: 36px;
    // height: 173px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.16); 
    position: fixed;
    z-index: 999;
    right: 16px;
    top: 368px;
}
.hetongt {
    width: 100%;
}
.dangqianhz {
  overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.fanhui {
    position: absolute;
    left: 0;
    margin-left: 17px;
    line-height: 44px;
}
.xiangqing {
    font-size: 17px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #1872ed;
    margin-left: 9px;
}
.shanghwj {
    font-size: 17px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #000000;
    text-align: center;
}
.dangqianwj {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 44px;
    background: #fff;
    display: flex;
    line-height: 44px;
    justify-content: center;
    box-shadow: 0 10px 10px -10px #ccc;
}
.sms-popup {
    width: 300px;
    height: 215px;
    background: #ffffff;
    border-radius: 8px;
    .sms-wrapper {
        font-size: 16px;
        .title {
            font-weight: bold;
            padding: 10px 16px;
        }
        .sms-content {
            padding: 26px 16px 30px;
            .phone {
                text-align: center;
                margin-bottom: 16px;
            }
            .input-conent {
                display: flex;
                input {
                    width: 155px;
                    height: 36px;
                    padding: 6px 10px;
                    box-sizing: border-box;
                    border: 1px solid #eaeaea;
                    border-radius: 5px;
                    border-right: none;
                    background: #ffffff;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
                button {
                    width: 110px;
                    height: 36px;
                    background: #1872ed;
                    border-radius: 0px 5px 5px 0px;
                    color: #fff;
                    border: 1px solid #eaeaea;
                    &.disabled {
                        background: #cdcdcd;
                    }
                }
            }
        }
        .footer {
            display: flex;
            border-top: 1px solid #eaeaea;
            .cancel, .confirm {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: 39px;
            }
            .cancel {
                border-right: 1px solid #eaeaea;
            }
            .confirm {
                color: #1872ED;
            }
        }
    }
}
.input-popup {
    width: 300px;
    // height: 207px;
    border-radius: 8px;
    background: #ffffff;
    font-size: 16px;
    .input-wrapper {
        .title {
            font-weight: bold;
            padding: 10px 16px;
        }
        ::v-deep .van-cell.van-field {
            padding: 6px 16px !important;
        }
        ::v-deep .van-field__control {
            padding: 4px 10px;
            border: 1px solid #eaeaea;
        }
        .input-footer {
            display: flex;
            justify-content: center;
            margin-top: 14px;
            margin-bottom: 20px;
            .cancel, .confirm {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 64px;
                height: 36px;
                border-radius: 4px;
                border: none;
            }
            .cancel {
                color: #202020;
                background: #dfe3e8;
                margin-right: 20px;
            }
            .confirm {
                color: #ffffff;
                background: linear-gradient(113deg, #73aaff 0%, #0053ff 100%);
            }
        }
    }
}
.loading-popup {
    background: transparent;
}
</style>