<template>
    <div style="min-width: 340px;">
        <header>
        <!-- <div></div> -->
        <div class="header2">
            <img src="../../assets/img/ico_fanhui.png" alt="">
            <a href="javaScript:;" @click="paixuc">
                <p>全部课程</p>
                <img src="../../assets/img/ico_shaixuan.png" alt="">
            </a>
            <span>产品经理</span>
        </div>
        <!-- 点击 -->
        <div v-show="paixulist" class="dian" id="xian" style="display: none;">
            <ul>
                <li>
                    <p>
                        <span>免费课程</span>
                        <img src="../../assets/img/ico_xuanzhong.png" alt="">
                    </p>
                </li>
                <li>
                    <p>
                        <span style="color: #000000;">免费课程</span>
                    </p>
                </li>
                <li>
                    <p>
                        <span style="color: #000000;">免费课程</span>
                    </p>
                </li>
                <li>
                    <p>
                        <span style="color: #000000;">免费课程</span>
                    </p>
                </li>
            </ul>
        </div>
    </header>
    <!-- 产品经理模块 -->
    <div class="bgc" id="bj"></div>
    <div class="cpjl">
        <ul>
            <li><a href="">产品经理</a></li>
            <li><a href="">设计创作</a></li>
            <li><a href="">IT互联网</a></li>
            <li><a href="">考试考证</a></li>
            <li><a href="">电商营运</a></li>
        </ul>
    </div>
    <!-- 选择 -->
    <div class="xuan">
        <ul>
            <li><a href="">全部</a></li>
            <li><a href="">入门产品经理</a></li>
            <li><a href="">初级产品经理</a></li>
            <li><a href="">中级产品经理</a></li>
            <li><a href="">高级产品经理</a></li>
        </ul>
    </div>
    <!-- 详细 -->
    <div class="subject">
        <ul>
            <li>
                <img src="../../assets/img/4.png" alt="">
                <span>高转化率文案实战</span>
                <p>共12节课</p>
            </li>
            <li>
                <img src="../../assets/img/4.png" alt="">
                <span>高转化率文案实战</span>
                <p>共12节课</p>
            </li>
            <li>
                <img src="../../assets/img/4.png" alt="">
                <span>高转化率文案实战</span>
                <p>共12节课</p>
            </li>
            <li>
                <img src="../../assets/img/4.png" alt="">
                <span>高转化率文案实战</span>
                <p>共12节课</p>
            </li>
            <li>
                <img src="../../assets/img/4.png" alt="">
                <span>高转化率文案实战</span>
                <p>共12节课</p>
            </li>
            <li>
                <img src="../../assets/img/4.png" alt="">
                <span>高转化率文案实战</span>
                <p>共12节课</p>
            </li>
            <li>
                <img src="../../assets/img/4.png" alt="">
                <span>高转化率文案实战</span>
                <p>共12节课</p>
            </li>
            <li>
                <img src="../../assets/img/4.png" alt="">
                <span>高转化率文案实战</span>
                <p>共12节课</p>
            </li>
        </ul>
    </div>
    </div>
</template>
<script>
export default {
  name: 'HelloWorld',
  data () {
    return {
      item: 1,
      paixulist: false
    }
  },
  methods: {
    paixuc () {
      if (this.paixulist === true) {
        this.paixulist = false
      } else {
        this.paixulist = true
      }
    }
  }
}
</script>
<style scoped>
   /* 公共类 */
* {
    padding: 0;
    margin: 0;
}
body {
    width: 100%;
    margin: 0 auto;
    background-color: #f6f6f6;
    font-weight: 600;/* 半粗体 Semibold */
    font-family: PingFangSC, PingFangSC-Semibold;
}
a {
    text-decoration: none;
}
li {
    list-style-type:none;
}
ul::-webkit-scrollbar {
    display: none;/*隐藏滚动条*/
}
/* 头部 */
header {
    height: 44px;
    margin: 0 auto;
    background-color: #fefefe;
}
.header2 {
    height: 44px;
    line-height: 44px;
    text-align: center;
    position: relative;
}
.header2 img {
    float: left;
    display: block;
    width: 22px;
    height: 22px;
    margin-left: 16px;
    margin-right: 63px;
    margin-top: 10px;
}
.header2 span {
    /* display: block;
    float: left; */
    /* height: 24px;
    width: 68px; */
    margin: 0 auto;
    /* margin-left: 30%; */
    padding-top: 9px;
    font-size: 17px;
    /* line-height: 24px; */
    font-family: PingFangSC;
}
.header2 a {
    display: block;
    float: right;
    margin-right: 4%;
    margin-top: 10px;
}
.header2 a p {
    display: block;
    float: left;
    margin-right: 6px;
    width: 57px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #10a675;
    line-height: 20px;
}
.header2 a img {
    margin: 0;
    float: right;
}
.dian {
    height: 200px;
    width: 100%;
    position: absolute;
}
.dian ul {
    background-color: #fff;
}
.dian li {
    height: 50px;
}
.dian li p {
    margin-left: 41%;
}
.dian li p span {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #10a675;
    margin: 0 auto;
}
.dian li img {
    vertical-align: middle;
}
/* 入门经理部分 */
.cpjl {
    background-color: #fefefe;
    width: 100%;
    height: 48px;
    border-bottom: 1px solid #dddddd;
    overflow-x: auto;
}
.cpjl ul {
    line-height: 48px;
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
}
.cpjl ul li {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 12px;
}
.cpjl ul li:first-child {
    margin-left: 16px;
}
.cpjl ul li a {
    color: #101010;
}
.cpjl ul li a:active {
    margin: 3px 0;
    width: 12px;
    height: 4px;
    background: linear-gradient(95deg,#61d1ac 4%, #10a675 95%);
    border-radius: 2px;
}
/* 详细部分 */
.subject {
    width: 94%;
    height: 100%;
    background-color: #fefefe;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 12px;
}
.subject ul {
    max-height: 664px;
    overflow: scroll;
}
.subject ul li {
    /* height: 159px; */
    width: 42.8%;
    float: left;
    margin-top: 16px;
}
.subject ul li:nth-child(n){
    padding-left: 5%;
}
.subject ul li:nth-child(2n){
    padding-left: 5%;
}
.subject ul li img{
    display: block;
    width: 100%;
}
.subject ul li span {
    width: 128px;
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #101010;
    line-height: 22px;
}
.subject ul li p {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: #888888;
    line-height: 17px;
}
/* 选择部分 */
.xuan {
    width: 100%;
    height: 36px;
    overflow-x: auto;
}
div::-webkit-scrollbar {
    display: none;/*隐藏滚动条*/
}
.xuan ul {
    list-style: none;
    display: flex;
    white-space: nowrap;
}
.xuan ul li {
    height: 26px;
    background: #ffffff;
    border-radius: 4px;
    float: left;
    margin-top: 10px;
    padding-left: 6px;
    padding-right: 6px;
    margin-right: 10px;
}
.xuan ul li a {
    width: 72px;
    height: 20px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: #101010;
    line-height: 20px;
}
.xuan ul li:first-child {
    margin-left: 12px;
    margin-right: 6px;
    background: linear-gradient(95deg,#61d1ac 4%, #10a675 95%);
}
.xuan ul li:first-child a {
    color: #ffffff;
}

/*  */
.bgc {
    display: none;
    position: absolute;
    top: 244px;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background: #000000;
}
</style>
