<template>
  <div style=" padding: 10px;">
    <h2 style="text-align: center;">服务协议</h2>
   <h3> 第一条【协议条款的确认和接纳】</h3>
   <div>
    1.1 欢迎注册、登录网校产品（以下或称“我们”）、使用网校服务（以下或称“服务”），请您仔细阅读以下全部内容（特别是粗体标注的内容）。本协议是您与湖南腾速科技有限公司（以下或称“我司”）之间关于网校服务的条款，内容包括本协议正文、本协议明确援引的其他协议及网校已经发布的或将来可能发布的各类协议和规则。所有协议内容为本协议不可分割的组成部分，与本协议正文具有同等法律效力。除另行明确声明外，您使用网校服务的行为将受本协议约束。
    1.2 如您选择“点击同意”本协议或者以其他方式开始使用网校服务，即表示已经与网校达成协议，并自愿接受本协议的所有内容。您确认，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力，确保有能力对您使用我司提供服务的一切行为独立承担责任。若您不具备前述主体资格或您是未满十八周岁的未成年人，请在您的监护人的陪同下阅读本服务条款，并在取得他们对您使用服务的行为，以及对本服务条款的同意之后，使用本服务；我司在依据法律规定或本协议约定要求您承担责任时，有权向您的监护人追偿。
    1.3 网校有权根据法律规范及运营的合理需要，不断修改和完善本协议，并在网校产品内进行公告。如您继续使用网校服务，即意味着同意并自愿遵守修改后的协议条款，亦或您有权终止使用。
    </div>
    <h3>第二条【帐号注册与使用】</h3>
    <div>
    2.1 您可浏览网校产品的课程信息，如您希望观看、学习课程，您需先登录您的帐号，或注册网校产品认可的帐号并登录。您注册登录的帐号是网校确认您身份的唯一依据。
    2.2 您充分了解并同意，您必须为自己注册帐号下的一切行为负责，包括但不限于用户所发表的任何内容以及由此产生的任何后果。用户应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。
    2.3 注册完成后，请您妥善保存有关帐号和密码，并对该帐号进行的所有活动和行为负责。如因您自身原因（包括但不限于转让帐号、与他人共用、自己泄露等）或您所用计算机或其他终端产品感染病毒或木马，而导致帐号密码泄漏、遗失或其他损失后果将由您独自承担。
    </div>
    <h3>第三条【付费课程及服务】</h3>
    <div>
        3.1 您应当通过我司官方提供的正当合法途径付费以获得课程全部服务，其他非官方途径付费的，均不享受我司保护，如我司发现用户未支付获取网校服务的，我司有权限制、中止或终止用户账号的登录和使用，停止向用户提供服务，且我司无需向用户退还已支付的任何费用。
        3.2 您在购买网校课程后，应当在课程服务有效期限内完成学习。如在有效期内要求延长服务期限，或课程服务已到期用户要求继续服务，我司均不予支持。
        3.3 您了解并同意，网校可能会基于自身原因（包括但不限于：更新课程内容、改进课程安排）不定期的对付费服务内容或功能进行更新而无需经过您的事先同意。
        3.4 您应使用网校正版课程资料，若用户出现包括但不限于违规倒卖课程、违规使用账号、在课程服务使用中反复使用侮辱语言、严重扰乱课堂秩序、威胁辱骂老师、恶意批量差评 、恶意骚扰客服、煽动用户退款等行为，一经证实，我司有权视您违反本协议行为的严重程度，对您采取单项或多项措施，详情参见本协议第七条【终止服务】。
    </div>
    <h3>
    第四条【善意合法使用】</h3>
    <div>
4.1 不会利用网校相关产品及服务进行非法活动、或进行可能侵犯他人权利或损害他人利益的活动；不会以非法方式获取或利用网校产品及服务其他用户的信息。
4.2 不会以技术方式攻击或破坏或改变网校产品及服务的部分或全部、或干扰其运行；不会以非法方式获取或使用网校产品及服务的任何软件、代码、内容或其他技术或商业信息；不会对网校产品及服务运行的任何程序进行反向工程、反向编译、反向汇编或改写。
4.3 不会未经许可使用网校的网站名称、公司名称、商标、商业标识、网页版式或内容、或其他由网校享有知识产权或权利的信息或资料；不会侵犯网校的商标权、著作权、专利权、技术秘密、其他知识产权或其他合法权利或权益。不会以任何方式贬损网校的商业声誉。
4.4 不会利用网校进行其他违背公序良俗的活动。您使用网校产品及服务的记录，可能被保存作为对您不利的证据。网校也可能将您违反法律或侵犯第三方权利或权益的记录报告给行政主管部门、司法机关。
4.5 您理解并保证您在网校产品中上传、发布或传输的信息、内容（包括您的账户名称等信息）应当遵守宪法、法律和行政法规，不得含有以下内容，否则，网校将立即停止传输该信息，采取消除等处置措施，防止信息扩散，保存有关记录，并向有关主管部门报告：
● 4.5.1 反对宪法确定的基本原则的；
● 4.5.2 危害国家统一、主权和领土完整的；
● 4.5.3 泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；
● 4.5.4 煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；
● 4.5.5 宣扬邪教、迷信的；
● 4.5.6 扰乱社会秩序，破坏社会稳定的；
● 4.5.7 诱导未成年人违法犯罪和渲染暴力、色情、赌博、恐怖活动的；
● 4.5.8 侮辱或者诽谤他人，侵害公民个人名誉、隐私、知识产权和其他合法权益的；
● 4.5.9 危害社会公德，损害民族优秀文化传统的；
● 4.5.10 有关法律、行政法规和国家规定禁止的其他内容。
4.6 如果您上传、发布或传输的信息、内容含有以上违反法律法规的信息或内容，或者侵犯任何第三方的合法权益，您将直接承担以上导致的一切不利后果。如因此给网校造成不利后果的，您应负责消除影响，并且赔偿网校因此导致的一切损失，包括且不限于财产损害赔偿、名誉损害赔偿、律师费、交通费等因维权而产生的合理费用。
</div>
<h3>
第五条 【个人信息及隐私条款】</h3>
<div>
我们注重保护用户个人信息及个人隐私，我们收集信息是为了向您提供更好的服务，并努力提高您的用户体验。详情请参看网校的《隐私政策》
</div>
<h3>
第六条 【有限责任及免责条款】</h3>
<div>
6.1 网校按照网校产品及服务的“现状”向用户提供产品及服务，网校不对网校产品及/或其功能、服务作出任何其他明示或暗示的保证。 对经由网校产品取得的任何产品、服务（含收费服务）或其他材料可能不符合您的期望；基于不同用户的个人特征和喜好，网校产品中可能存在一些让您不愉快、厌恶或难以接受的内容；网校不因前述情形向您承担任何责任。
6.2 用户在网校上进行的任何行为均是用户的个人行为，由于信息的海量性，网校无法对信息或内容的权属、合法性、合规性、真实性、科学性、完整权、有效性等进行审查。因用户行为或用户上传、存储、发布、传播的任何信息、内容等引发的任何争议、责任，或由此产生的任何直接、间接、偶然、特殊的损害，均由用户自行承担法律责任，网校不承担任何责任；如用户行为对网校造成损失或不良影响，网校保留追究相关用户法律责任的权利。 网校对用户所发布信息的删除或储存失败不承担任何法律责任。
6.3基于互联网的开放性属性，用户应知悉用户将信息、内容等上传到互联网上，可能会被第三方恶意组织或个人以非常规方式复制、转载、修改或进行其他使用（包括用于非法用途），用户必须充分意识到此类风险的存在。用户同意使用网校产品过程中所存在的上述风险将完全由用户自行承担，网校对此不承担任何责任。
</div>
<h3>
第七条【终止服务】</h3>
<div>
7.1 本协议或本协议项下网校所提供的产品使用及服务可在下述情形下部分或全部中止或终止：
● 7.1.1 因法律规定，或网校服从行政命令或司法判令的要求；
● 7.1.2 用户违反本协议；
● 7.1.3 网校认为应予终止的其他情形。
7.2 在一般情形下，网校会提前按照您提供的联系方式以电子邮件或短信或其他电子方式通知您产品使用及服务将中止或终止。 您应了解并同意，在紧急情况或特殊下，网校可不经通知即中止或终止产品使用及服务。在本协议或本协议项下网校提供的产品使用及服务中止或终止时，网校有权 ：
● 7.2.1 拒绝用户登录网校产品及服务；
● 7.2.2 删除用户信息；
● 7.2.3 删除用户购买的内容。
7.3 如您因违规被暂停部分或全部服务，终止部分服务、被限制/中止账号的登录和使用，对您已购买的课程费用，我司将不予退回；如用户被终止全部服务或终止账号登录的，我司无需向用户退还已支付的任何课程费用。
</div>
<h3>
第八条 【运行维护】</h3>
<div>
8.1 网校尽可能保证网校产品及服务的稳定运行。您应理解并同意，因系统维护、升级、调整，第三方原因如通讯设备故障、计算机病毒或黑客攻击、技术问题、网络、电脑故障、系统不稳定性、台风、地震、海啸、洪水、停电、战争、恐怖袭击、法律、政策、政府管制及其他各种不可抗力或网校无法合理控制的原因而引发的以下情况及因此导致的一切损失，网校不承担责任。
8.2 网校对账号上所有服务将尽力维护其安全性、方便性和适用性，但对非因网校的故意或重大过失，用户资料遭到未经授权的使用或修改不承担责任。 网校不对您在本协议项下服务中相关数据的删除或储存失败负责。
</div>
<h3>
第九条 【争议解决】</h3>
<div>
您和网校均同意，因本协议解释或执行引起的任何争议，双方应首先友好协商解决。协商不成时，争议将提交网校所在地法院诉讼解决。 请您再次确认您已全部阅读并充分理解上述协议。 如有任何疑问，请联系网校工作人员并作充分描述，网校将尽力解决。
</div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>