var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#f6f6f6","min-width":"420px"}},[_c('div',{staticClass:"tou"},[_c('div',{staticStyle:{"margin-top":"10px","margin-left":"16px"}},[_c('van-icon',{attrs:{"size":"22","name":"arrow-left"}}),_c('span',{staticClass:"gongkai"},[_vm._v("直播公开课")])],1)]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shim"},[_c('div',{staticStyle:{"float":"left","border-radius":"7px","margin-top":"12px"}},[_c('img',{staticClass:"santu",attrs:{"src":require("../assets/img/shimiao.png"),"alt":""}})]),_c('div',{staticStyle:{"float":"left","margin-top":"12px"}},[_c('p',{staticClass:"shoulian"},[_vm._v("十秒学会PS视频瘦脸")]),_c('div',{staticClass:"zbz"},[_c('img',{attrs:{"src":require("../assets/img/bofangzhong.png"),"alt":""}}),_c('span',{staticClass:"zzz"},[_vm._v("直播中")])]),_c('div',{staticClass:"jinru"},[_c('p',{staticClass:"jinzb"},[_vm._v("进入直播")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shima"},[_c('div',{staticStyle:{"float":"left","border-radius":"7px","margin-top":"12px"}},[_c('img',{staticClass:"santu",attrs:{"src":require("../assets/img/shimiao.png"),"alt":""}})]),_c('div',{staticStyle:{"float":"left","margin-top":"12px"}},[_c('p',{staticClass:"shoulian"},[_vm._v("LOGO造型 | 平面基础与设"),_c('br'),_vm._v("计语言免费公开课")]),_c('div',{staticClass:"zbz"},[_c('img',{attrs:{"src":require("../assets/img/bofangzhong.png"),"alt":""}}),_c('span',{staticClass:"zzz"},[_vm._v("直播中")])]),_c('div',{staticClass:"jinrue"},[_c('p',{staticClass:"jinzb"},[_vm._v("进入直播")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shima"},[_c('div',{staticStyle:{"float":"left","border-radius":"7px"}},[_c('img',{staticClass:"santu",attrs:{"src":require("../assets/img/shimiao.png"),"alt":""}})]),_c('div',{staticStyle:{"float":"left"}},[_c('p',{staticClass:"shoulian"},[_vm._v("平面构成与版式设计")]),_c('p',{staticClass:"shijian"},[_vm._v("10月12日 20:00 直播")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shima"},[_c('div',{staticStyle:{"float":"left","border-radius":"7px"}},[_c('img',{staticClass:"santu",attrs:{"src":require("../assets/img/shimiao.png"),"alt":""}})]),_c('div',{staticStyle:{"float":"left"}},[_c('p',{staticClass:"shoulian"},[_vm._v("让插画更有价值")]),_c('p',{staticClass:"shijian"},[_vm._v("10月12日 20:00 直播")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shima"},[_c('div',{staticStyle:{"float":"left","border-radius":"7px"}},[_c('img',{staticClass:"santu",attrs:{"src":require("../assets/img/shimiao.png"),"alt":""}})]),_c('div',{staticStyle:{"float":"left"}},[_c('p',{staticClass:"shoulian"},[_vm._v("数据指标拆解和精细分析高"),_c('br'),_vm._v("级算法进阶")]),_c('p',{staticClass:"shijian",staticStyle:{"margin-top":"27px"}},[_vm._v("10月12日 20:00 直播")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shima"},[_c('div',{staticStyle:{"float":"left","border-radius":"7px"}},[_c('img',{staticClass:"santu",attrs:{"src":require("../assets/img/shimiao.png"),"alt":""}})]),_c('div',{staticStyle:{"float":"left"}},[_c('p',{staticClass:"shoulian"},[_vm._v("高转化率文案实战")]),_c('p',{staticClass:"shijian"},[_vm._v("10月12日 20:00 直播")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shima"},[_c('div',{staticStyle:{"float":"left","border-radius":"7px"}},[_c('img',{staticClass:"santu",attrs:{"src":require("../assets/img/shimiao.png"),"alt":""}})]),_c('div',{staticStyle:{"float":"left"}},[_c('p',{staticClass:"shoulian"},[_vm._v("平面构成与版式设计")]),_c('p',{staticClass:"shijian"},[_vm._v("10月12日 20:00 直播")])])])
}]

export { render, staticRenderFns }