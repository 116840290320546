import { Quill } from "vue-quill-editor";

let BlockEmbed = Quill.import("blots/block/embed");
// const Link = Quill.import('formats/link')
class ImageBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    node.setAttribute("style", value.style);
    node.setAttribute("src", value.url);
    return node;
  }
  static value(node) {
    return {
      alt: node.getAttribute("style"),
      url: node.getAttribute("src"),
    };
  }
}
ImageBlot.blotName = "image";
ImageBlot.tagName = "img";

export default ImageBlot;
