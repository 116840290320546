/**
 * 描述：魔方网校点击更多课程需要把所有课程数据传递到二级页面
**/
const state = {
    sub_title: '',
    type: '1',
    class_name_type: '',
    class_tag_type: '',
    class_tag_name: '',
    course_list: [],
    init_course_list:[],
    all_course_list:[],
    searchKey:''
    
}

const getters = {
    getSubTitle() {
        return state.sub_title
    },
    getType:() => {
        return state.type
    },
    getClassNameType:() => {
        return state.class_name_type
    },
    getCourse:() => {
        return state.course_list
    },
    getClassTagType:() => {
        return state.class_tag_type
    },
    getClassTagName:() => {
        return state.class_tag_name
    },
    getAllCourseList:() => {
        return state.all_course_list
    }
}

const actions = {
    setSubTitle: ({ commit }, value) => {
        commit('SET_SUB_TITLE', value)
    },
    setType: ({ commit }, value) => {
        commit('SET_TYPE', value)
    },
    setClassNameTye: ({ commit }, value) => {
        commit('SET_CLASS_NAME_TYPE', value)
    },
    setCourseList: ({ commit }, value) => {
        commit('SET_COURSE_LIST', value)
    },
    setClassTagType: ({ commit }, value) => {
        commit('SET_CLASS_TAG_TYPE', value)
    },
    setClassTagName: ({ commit }, value) => {
        commit('SET_CLASS_TAG_NAME', value)
    }
}

const mutations = {
    SET_SUB_TITLE: (state,val) => {
        state.sub_title = val
    },
    SET_TYPE:(state, val) => {
        state.type = val
    },
    SET_CLASS_NAME_TYPE:(state, val) => {
        state.class_name_type = val
    },
    SET_COURSE_LIST:(state, val) => {
        state.course_list = val
    },
    SET_CLASS_TAG_TYPE:(state, val) => {
        state.class_tag_type = val
    },
    SET_CLASS_TAG_NAME:(state, val) => {
        state.class_tag_name = val
    },
    SAVE_ALL_COURSE:(state,val) =>{
        state.init_course_list = val
        if(!state.searchKey){
            state.all_course_list = []
        }
    },
    SEARCH_COURSE:(state,{key,activeName})=>{
        if(!key){
            state.all_course_list = []
        }
        if(key && activeName === 'a'){
            state.all_course_list = state.init_course_list.filter(item=>item.name.includes(key))
            console.log(state.init_course_list);
            console.log(state.all_course_list)
        }
        if(key && activeName === 'b'){
            state.all_course_list = state.init_course_list.filter(item=>item.name.includes(key) && item.isLive === '1' && item.courseType === '1')
        }
        if(key && activeName === 'c'){
            state.all_course_list = state.init_course_list.filter(item=>item.name.includes(key) && item.isLive === '0' && item.courseType === '1')
        }
        if(key && activeName === 'd'){
            state.all_course_list = state.init_course_list.filter(item=>item.name.includes(key) && item.courseType == '2')
        }

    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}