<template>
  <div class="nav-wrap">
    <van-nav-bar :title="title" left-arrow @click-left="back" />
  </div>
</template>
<script>
import { NavBar } from 'vant';
export default {
  data() {
    return {};
  },
  props: ['title'],
  created() {},
  computed: {},
  methods: {
    back() {
      this.$emit('handleBack');
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-nav-bar__title {
  font-size: 16px;
  font-weight: 600;
  color: #101010;
}
::v-deep .van-nav-bar .van-icon {
  color: #323233;
}
::v-deep .van-nav-bar {
  line-height: normal;
}
::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0px;
}
</style>
