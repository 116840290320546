import Vue from 'vue'

function register(components) {
    components.map(c => {
        if(c.name) {
            Vue.component(c.name, {
                ...c.definition
            })
        }
    })
}

/**
 * 描述：自动导入魔方网校页面组件
**/
const widgets = require.context('@/components/mofang/components', true, /\.vue$/)

const globalComponents = widgets.keys().map(item => {
    const component = widgets(item)
    return {
        name: component.default.name,
        definition: component.default
    }
})

register(globalComponents)
