import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store/store'
import defaultApi from './utils/defaultApi'
// import api from '@/api/onlineSchool'
import Vant from 'vant'
import common from './utils/common'
import VueClipBoard from 'vue-clipboard2'
import VConsole from 'vconsole';
// import commApiRequest from '@/api/commApiRequest'
import initSystemAxiosInstance from './api/index'

Vue.use(VueClipBoard)
import 'vant/lib/index.css'
import 'lib-flexible'
import '@/utils/auto-import'
import './router/permission'

Vue.use(Vant)
// Vue.prototype.$api = api
// Vue.prototype.$dataApi =  commApiRequest(process.env.VUE_APP_API_DATA)
// Vue.prototype.$liveApi =  commApiRequest(process.env.VUE_APP_API_ROOT)
// Vue.prototype.$onlineApi = commApiRequest(process.env.VUE_APP_API_ONLINE)
// Vue.prototype.$subApi  = commApiRequest(process.env.VUE_APP_API_SUBTITLES)
Vue.config.productionTip = false

// 正式环境根据设备类型 跳转终端
if(process.env.NODE_ENV == 'production'){
  if(!location.hash.includes('checkMobile')) {
    common.checkEquipment('h5')
  }
} else {
  new VConsole()
}

axios.get(`https://shkt-online-live-1317978474.cos.accelerate.myqcloud.com/web/config/requestApi/default.json?a=${new Date().valueOf()}`).then(({ status, data }) => {
  if (status === 200 && data) {
    getBaseUrl(data)
  }
  renderPage()
}).catch((error) => {
  getBaseUrl()
  renderPage()
})

function getBaseUrl(setting) {
  if (!setting) {
    setting = defaultApi
  }
  const { SHANHU_LIVE_API, SHANHU_DATA_API, SHANHU_ONLINE_API, SHANHU_LIVE_HY_API, SHANHU_SCRM_API } = setting
  Vue.prototype.SHANHU_LIVE_API = SHANHU_LIVE_API
  Vue.prototype.SHANHU_DATA_API = SHANHU_DATA_API
  Vue.prototype.SHANHU_ONLINE_API = SHANHU_ONLINE_API
  Vue.prototype.SHANHU_LIVE_HY_API = SHANHU_LIVE_HY_API
  Vue.prototype.SHANHU_SCRM_API = SHANHU_SCRM_API
  initSystemAxiosInstance()
}

function renderPage() {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
}

// new Vue({
//   router,
//   store,
//   render: h => h(App),
// }).$mount('#app')
