import { render, staticRenderFns } from "./Realnameauthentication.vue?vue&type=template&id=6fa8a1e5&scoped=true"
import script from "./Realnameauthentication.vue?vue&type=script&lang=js"
export * from "./Realnameauthentication.vue?vue&type=script&lang=js"
import style0 from "./Realnameauthentication.vue?vue&type=style&index=0&id=6fa8a1e5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fa8a1e5",
  null
  
)

export default component.exports