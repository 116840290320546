<template>
    <van-popup v-model="visible" round style="width: 280px; height:192px">
      <div class="wrap">
        <div class="title">
          <img src="@/assets/img/Warning.png">
          <span>提示</span>
        </div>
        <div class="msg">
          {{ msg }}
        </div>
        <div>
          <van-button @click="close" round color="linear-gradient(126deg,#73aaff 10%, #0053ff 90%)" style="width: 142px;height:30px">{{ confirm }}</van-button>
        </div>
      </div>
    </van-popup>
</template>

<script>
import Vue from "vue"
import { Popup, Button } from "vant"
Vue.use(Popup)
Vue.use(Button)
export default {
  name: "MyToast",
  props:{
    confirm:{
      type:String,
      default:'我知道了'
    }
  },
  data(){
    return {
      visible:false,
      msg:''
    }
  },
  methods:{
    show(msg){
      this.msg = msg
      this.visible = true
    },
    close(){
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .title{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    img{
        width: 20px;
      height: 20px;
      margin-right: 8px;
    }
    span{
      font-size: 16px;
      font-weight: 700;
    }
  }
  .msg{
    font-size: 14px;
    margin-bottom: 30px;
  }
}
</style>