<template>
  <div style=" padding: 10px;">
    <h2 style="text-align: center;">隐私协议</h2>
    <p><b>【更新日期】：</b>  2021年11月10日</p>
    <p><b>【生效日期】：</b>  2021年11月10日</p>
    <p>
我们注重保护用户个人信息及个人隐私。本隐私政策解释了用户（“您”）个人信息收集（以下或称“信息”）和使用的有关情况，本隐私政策适用于我们网校向您提供的所有相关服务（包括但不限于电子商务、网络社交等服务，以下称“网校服务”或“服务”），建议您完整地阅读本隐私权政策 （特别是粗体标注的内容） ，以帮助您了解维护自己隐私权的方式。 如果您不同意本隐私政策的任何内容，您应立即停止使用网校服务。当您使用网校提供的任一服务时，即表示您已同意我们按照本隐私政策来合法使用和保护您的个人信息。
</p>
<p>
本隐私协议包含以下内容：</p>
<p><b>一、您的个人信息收集</b></p>
<p><b>二、我们对您个人信息的管理和使用</b></p>
<p><b>三、您个人信息的分享</b></p>
<p><b>四、您个人信息的安全保护</b></p>
<p><b>五、访问和更新您的个人信息</b></p>
<p><b>六、Cookie 及其它同类技术的使用</b></p>
<p><b>七、你的权利</b></p>
<p><b>八、未成年人的个人信息保护</b></p>
<p><b>九、通知和修订</b></p>
<h3>
一、您个人信息的收集</h3>
<p>
1、 收集使用您个人信息的目的
我们收集信息是为了向您提供更好的服务，提高您使用网校提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，最终提高您的用户体验。</p>
<p>
2、 收集使用您个人信息的方式
（1）当您注册网校账户及您在使用相关网校服务时主动填写、提交及/或其他任何方式提供信息。
（2）在您访问网校网站或使用网校服务时，主动授权或开启权限后由系统自动接收并记录信息。</p>
<p>
3、 收集使用您个人信息的范围
为了向您提供更好的服务，并努力提高您的用户体验，我们收集信息的种类如下：
（1）您向我们提供的信息
当您注册网校账户及您在使用相关网校服务时填写、提交及/或其他任何方式提供的信息，包括您的姓名、性别、出生年月日、身份证号码、电话号码、电子邮箱、收货地址、微信、微博、QQ等社交账号、及相关附加信息（如您地址中的所在的省份和城市、邮政编码等）。您可以选择不提供某一或某些信息，但是这样可能使您无法使用网校的许多特色服务。请您理解，我们使用您提供的信息是为了回应您的要求，为您在网校学习或享受服务提供便利，完善网校网站以及与您进行信息沟通。另外，我们可能会将您所提供的信息与您的网校账户关联，用以识别您的身份。
（2）我们在您使用服务过程中获得的信息
为了提高服务质量和用户体验，我们会留存您使用服务以及使用方式的相关信息，这类信息包括：
a、您的设备信息。网校可能会读取您访问网校或使用网校服务时所使用的终端设备的信息，包括但不限于设备型号、设备识别码、SD卡数据、操作系统、分辨率、电信运营商等。
b、您的麦克风服务。网校基于麦克风的语音技术相关服务：您可在开启麦克风权限后使用麦克风实现录音功能，在这些功能中我们会收集您的录音内容来完成您的学习等需求。
c、您的短信服务。网校基于用户安装并主动授权后,我们会向系统申请“读取短信”权限，为您提供更为便捷的短信快捷登录服务，通过识别短信中验证码信息，为您提供快捷的登录服务。
d、您的行为信息。网校可能会记录您访问网校或使用网校时所进行的操作信息。
（3）来自第三方的信息
指在您注册网校账户和使用服务过程中，您授权网校可向网校的关联方、合作伙伴获取其所收集的相关信息。这些信息包括但不限于您的身份信息、行为信息、设备信息等，网校会将此类信息汇总，用于帮助网校向您提供更好的服务或更好的预防互联网欺诈。
</p>
<h3>
二、我们对您个人信息的管理和使用</h3>
<p>
为向您提供服务、提升我们的服务质量以及优化您的服务体验，我们会在符合法律规定下使用您的个人信息，并主要用于下列用途：
1、我们可能使用您的个人信息以验证身份、预防、发现、调查欺诈、危害安全、非法或违反与我们或其关联方协议、政策或规则的行为，以保护您、其他网校用户，或我们或其关联方的合法权益。
2、我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，以展示我们的产品或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
3、让您参与有关我们产品及服务的调查。
4、经您同意或授权的其他用途。
</p>
<h3>
三、您个人信息的分享</h3>
<p>
您的个人信息是我们为您提供服务的重要部分，我们会遵循法律规定对您的信息承担保密义务。除以下情形外，我们不会将您的个人信息披露给第三方：
1、征得您的同意或授权。
2、根据法律法规的规定或行政或司法机构的要求。
3、如您是知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能产生的权利纠纷。
4、只有共享您的信息，才能提供您需要的服务，或处理您与他人的纠纷或争议。
5、与授权合作伙伴共享；在法律法规允许的范围内，为维护网校的合作伙伴、您或其他网校的用户或者社会公益、财产或安全遭受损害，我们仅为实现本隐私协议中声明的目的与授权合同伙伴提供某些服务。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们会仅出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们将审慎评估合作方使用共享信息的目的，对合作方的安全保障能力进行综合评估，我们会对合作方获取信息的软件工具开发包、应用接口(API)进行严格的安全检测，以保护数据安全。我们已与合作方签订合同明确双方的义务与责任，明确我们的合作伙伴无权将共享的个人信息用与其他用途。对于无法签订合同的合作方在使用过程中对用户造成影响的，网校将承担全部责任。
</p>
<h3>
四、您个人信息的安全保护</h3>
<p>
网校严格保护您的个人信息安全。我们使用各种制度、安全技术和程序等措施来保护您的个人信息不被未经授权的访问、篡改、披露或破坏。如果您对我们的个人信息保护有任何疑问，请联系我们的官方客服。
在通过网校网站与第三方进行网上商品或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者社交账号等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是你的账户及密码发生泄露，请您立即联络我们，以便我们采取相应措施。
请您注意，在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息或匿名化处理；您可以在我们的产品中直接申请注销账户。您可通过以下方式自行操作：您可以登陆移动端，我-关于-账号注销，点击“账号注销”。您注销账户后，我们将停止为您提供产品与服务，并依据您的要求，除法律法规另有规定外，我们将删除您的个人信息。
</p>
<h3>
五、访问和更新您的个人信息</h3>
<p>
您可以在“个人中心”页面查阅您提交给网校的所有个人信息，你也可通过上述途径更新其他个人信息。
</p>
<h3>
六、Cookie 及其它同类技术的使用</h3>
<p>
1、Cookie的使用</p>
<p>
Cookie是由网页服务器存放在您的访问设备上的文本文件。指定给您的Cookie 是唯一的，它只能被将Cookie发布给您的域中的Web服务器读取。
网校使用 Cookie 来帮助您实现您的联机体验，使您在网校及其关联方获得更轻松的访问体验。例如，Cookie 会帮助您在后续访问网校网站时调用您的信息，简化记录您填写个人信息（例如一键登录等）的流程；保护您的数据安全等。
您有权接受或拒绝 Cookie。大多数浏览器会自动接受Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookie。如果选择拒绝 Cookie，那么您可能无法完全体验所访问的网校网站或某些服务的全部功能。
2、 网站信标和像素标签
除 Cookie 外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，网校向您发送的短信、电子邮件可能含有链接至网校网站内容的点击 URL。如果您点击该链接，网校则会跟踪此次点击，帮助我们了解您的产品和服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知推送的链接信息是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从网校的寄信名单中退订。
</p>
<h3>
七、你的权利</h3>
<p>
我们非常重视你对个人信息的管理，并尽全力保护你对于你个人信息的查询、访问、修改、撤回同意授权、建议反馈以及设置隐私功能等权利，以使你拥有充分的能力保障你的隐私和安全。
（一） 管理查询、访问、更正你的个人信息
进入个人中心—账户中心及更多，查询、访问个人信息（账号）及更正您的手机号
（二） 改变你授权同意范围或撤销授权
改变或撤回敏感信息权限 你可以通过的本网页删除信息、更改隐私设置以及在设备本身操作系统中关闭通知、麦克风等权限改变同意范围或撤回你的授权。 请你理解，特定的业务功能和服务将需要你的信息才能得以完成，当你撤回同意或授权后，我们无法继续为你提供撤回同意或授权所对应的功能和服务，也不再处理你相应的个人信息。但你撤回同意或授权的决定，不会影响公司此前基于你的授权而开展的个人信息处理。
（三） 访问隐私政策
你可以在登录/注册页面，查看本网校隐私政策的全部内容
（四） 停止运营向你告知
如我们停止运营，我们将及时停止收集你个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知你，并对所持有的你的个人信息进行删除或匿名化处理。
</p>
<h3>
八、未成年人的个人信息保护</h3>
<p>
网校非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用网校服务前，应事先取得您家长或法定监护人的书面同意。网校根据国家相关法律法规的规定保护未成年人的个人信息。
</p>
<h3>
九、通知和修订</h3>
<p>
1、为给您提供更好的服务，网校的业务将不时变化，本隐私政策也将随之调整。网校会通过在网校网站、移动端上发出更新版本并提醒您相关内容的更新，也请您访问网校以便及时了解最新的隐私政策。
2、如果您对于本隐私政策或在使用网校服务时对于您的个人信息或隐私情况有任何问题，请联系网校工作人员（电子邮箱： business@tengface.com ）并作充分描述，我们将尽力解决。
</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>