<template>
    <div>
      <div class="tit">
          <div class="tit tit_fh">
            <div class="img" style="float: left;width: 22px;height: 22px;padding: 15px 22px 0;">
            <a href="#"><img src="../../assets/images/ico_fanhui.png" alt=""></a>
            </div>
        <h1 style="width: 85px; margin: -10px 160px;">直播公开课</h1>
            <!-- <div class="img" style="float: left;width: 22px;height: 22px;padding-left:22px; padding-top: 10px;">
                <a href="#"><img src="../../assets/images/ico_fanhui.png" alt=""></a>
            </div>
            <h1 style="margin: -15px 150px;">直播公开课</h1> -->
        </div>
        <div class="course" style="height: 800px;">
            <div class="one">
            <img src="../../assets/images/2.png" alt="">
            <p>LOGO造型基础与设计语言</p>
            <span class="f" style="margin-top: 30px; color: #10a675;">
                <img src="../../assets/images/ico_bofangzhong.png" alt="" style="margin-top: -3px;">
                直播中
            </span>
             <router-link :to="{path:'zhibozhonga',query:{setid:1231231}}">
            <div><a href="#" class="f_a">进入直播</a></div>
            </router-link>
            </div>
            <div class="one">
            <img src="../../assets/images/2.png" alt="">
            <p>LOGO造型基础与设计语言</p>
            <span class="f" style="margin-top: 30px; color: #10a675;">
                <img src="../../assets/images/ico_bofangzhong.png" alt="" style="margin-top: -3px;">
                直播中
            </span>
            <div class="f_d"><a href="#" class="f_a">进入直播</a></div>
            </div>
            <div class="one">
            <img src="../../assets/images/2.png" alt="">
            <p>LOGO造型基础与设计语言</p>
            <span style="color:#10a675;">10月12日 20:00 直播</span>
            </div>
            <div class="one">
            <img src="../../assets/images/2.png" alt="">
            <p>LOGO造型基础与设计语言</p>
            <span style="color:#10a675;">10月12日 20:00 直播</span>
            </div>
            <div class="one">
            <img src="../../assets/images/2.png" alt="">
            <p>LOGO造型基础与设计语言</p>
            <span style="color:#10a675;">10月12日 20:00 直播</span>
            </div>
            <div class="one">
            <img src="../../assets/images/2.png" alt="">
            <p>LOGO造型基础与设计语言</p>
            <span style="color:#10a675;">10月12日 20:00 直播</span>
            </div>
            <div class="one">
            <img src="../../assets/images/2.png" alt="">
            <p>LOGO造型基础与设计语言</p>
            <span style="color:#10a675;">10月12日 20:00 直播</span>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {

    }
  },
  methods: {

    // tiaozhuan () {
    //   let routeUrl = this.$router.resolve({
    //     path: '/zhibozhonga',
    //     query: {id: 96} // 还可以带参数过去
    //   })
    //   window.open(routeUrl.href, 'zhibozhonga')
    // }
  }
}
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  font-family: PingFangSC, PingFangSC-Regular;
  background: #f6f6f6;

}

a {
  text-decoration: none;
}

.tit {
  width: 100%;
  height: 44px;
  background-color: #ffffff;
  max-width: 448px;
}

.tit h1 {
  float: left;
  width: 100px;
  height: 24px;
  font-size: 17px;
  font-weight: 600;
  color: #101010;
  line-height: 24px;
  margin: 10px 150px 0;
}

.course {
  width: 355px;
  height: 336px;
  background: #fefffe;
  border-radius: 10px;
  margin: 10px 10px;
}

.course div {
  float: left;
  width: 355px;
  height: 88px;
  background-color: #fff;
  margin-top: 18px;
}

.course .one img {
  float: left;
  margin-left: 12px;
}

.course .one p {
  float: left;
  width: 184px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #101010;
  line-height: 22px;
  margin-left: 12px;
}

.course .one .f {
  float: left;
  width: 70px;
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #ff8500;
  line-height: 17px;
  margin: 50px 0 0 12px;
}

.course .one span {
  float: left;
  width: 116px;
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #888888;
  line-height: 17px;
  margin: 50px 0 0 12px;
}

.course .one .f_d {
  float: right;
  width: 72px;
  height: 24px;
  background: linear-gradient(95deg, #78d4b6 4%, #0bb67e 95%);
  border-radius: 16px;
  margin: 42px 12px 0 0;
}

.course .one div {
  float: right;
  width: 72px;
  height: 24px;
  border: 1px solid #4abb96;
  border-radius: 16px;
  margin: 42px 12px 0 0;
}

.course .one .f_d .f_a {
  width: 48px;
  height: 22px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  margin: 0 10px;
}

.course .one div a {
  width: 48px;
  height: 22px;
  font-size: 12px;
  font-weight: 400;
  color: #10a675;
  line-height: 22px;
  margin: 0 10px;
}

</style>
