import Vue from 'vue'
import Router from 'vue-router'
import home from '@/components/home'
import Recordingand from '@/components/Recordingand'
import livebroadcast from '@/components/livebroadcast'
import CourseDetails from '@/components/CourseDetails'
import Paymentdetails from '@/components/Paymentdetails'
import Playing from '@/components/Playing'
import Openclass from '@/components/Openclass'
import zhanghaomimadl from '@/components/xiaoming/zhanghaomimadl'
import gaishoujihao from '@/components/tangdou/gaishoujihao'
import zhucezh from '@/components/tangdou/zhucezh'
import oneclass from '@/components/oneClass'
import MyHomework from '@/components/myHomework/homeworkIndex'
// 观看数据
import ViewDataCon from '@/components/viewData/ViewDataCon'
import ViewDataInfo from '@/components/viewData/viewDataInfo'
import HomeworkDetail from '@/components/myHomework/homeworkDetail.vue'
import QuestionPaper from '@/components/myHomework/questionPaper.vue'
import Agreement from '@/components/myHomework/Agreement.vue'
import Privacy from '@/components/myHomework/Privacy.vue'
import downLoadApp from '@/components/downLoadApp'
// tan
import guanyu from '@/components/tangdou/guanyu'
import kechengchongzhi from '@/components/tangdou/kechengchongzhi'
import liaotian from '@/components/tangdou/liaotian'
import my from '@/components/tangdou/my'
import myshoucang from '@/components/tangdou/myshoucang'
import myStudy from '@/components/tangdou/myStudy'
import Public from '@/components/tangdou/public'
import sheZhi from '@/components/tangdou/sheZhi'
import ziliao from '@/components/tangdou/ziliao'
import HistoryViewing from '@/components/tangdou/HistoryViewing'
// ming
import yueke from '@/components/xiaoming/yueke'
import yuekelslb from '@/components/xiaoming/yuekelslb'
import denglu from '@/components/xiaoming/denglu'
import kechengzhongxin from '@/components/xiaoming/kechengzhongxin'
import kechengzixun from '@/components/xiaoming/kechengzixun'
import kechengziyemian from '@/components/xiaoming/kechengziyemian'
import sousuo from '@/components/xiaoming/sousuo'
import xiaoxi from '@/components/xiaoming/xiaoxi'
import zhibozhonga from '@/components/xiaoming/zhibozhonga'
import zhuce from '@/components/xiaoming/zhuce'
import pingtest from '@/components/tangdou/testPing'
import lookover from '@/components/naturalendowments/lookover'
import Storeinformation from '@/components/naturalendowments/Storeinformation'
//合同管理
import contractmanagement from '@/components/Contractgrouping/contractmanagement'
import Allcontracts from '@/components/Contractgrouping/Allcontracts'
import FileDetails from '@/components/Contractgrouping/FileDetails'
import Personalauthentication from '@/components/Contractgrouping/authentication/Personalauthentication'
import Agreementlist from '@/components/Contractgrouping/authentication/Agreementlist'
import Realnameauthentication from '@/components/Contractgrouping/authentication/Realnameauthentication'
import Realnameresult from '@/components/Contractgrouping/authentication/Realnameresult'
import FaceResults from '@/components/Contractgrouping/authentication/FaceResults'
import Accountmismatch from '@/components/Contractgrouping/authentication/Accountmismatch'
import Face from '@/components/Contractgrouping/authentication/Face'
import ViewSigning from '@/components/Contractgrouping/signacontract/ViewSigning'
import SignaturePage from '@/components/Contractgrouping/signacontract/SignaturePage'
import MyNote from '@/components/note/noteList.vue'
import NoteDetail from "@/components/note/noteDetail.vue";
Vue.use(Router)

export default new Router({
  // mode: 'history',
  routes: [
    // 合同
    {
      path: "/Accountmismatch",
      name: "Accountmismatch",
      component: Accountmismatch,
    },
    {
      path: "/Face",
      name: "Face",
      component: Face,
    },
    {
      path: "/SignaturePage",
      name: "SignaturePage",
      component: SignaturePage,
    },
    {
      path: "/ViewSigning",
      name: "ViewSigning",
      component: ViewSigning,
    },
    {
      path: "/FaceResults",
      name: "FaceResults",
      component: FaceResults,
    },
    {
      path: "/Realnameresult",
      name: "Realnameresult",
      component: Realnameresult,
    },
    {
      path: "/Realnameauthentication",
      name: "Realnameauthentication",
      component: Realnameauthentication,
    },
    {
      path: "/Agreementlist",
      name: "Agreementlist",
      component: Agreementlist,
    },
    {
      path: "/Personalauthentication",
      name: "Personalauthentication",
      component: Personalauthentication,
    },
    {
      path: "/FileDetails",
      name: "FileDetails",
      component: FileDetails,
    },
    {
      path: "/Allcontracts",
      name: "Allcontracts",
      component: Allcontracts,
    },
    {
      path: "/contractmanagement",
      name: "contractmanagement",
      component: contractmanagement,
    },
    // 谭
    {
      path: "/Storeinformation",
      name: "Storeinformation",
      component: Storeinformation,
    },
    {
      path: "/lookover",
      name: "lookover",
      component: lookover,
    },
    {
      path: "/zhanghaomimadl",
      name: "zhanghaomimadl",
      component: zhanghaomimadl,
    },
    {
      path: "/denglu",
      name: "denglu",
      component: denglu,
    },
    {
      path: "/viewDataCon",
      name: "ViewDataCon",
      component: ViewDataCon,
    },
    {
      path: "/viewDataInfo",
      name: "ViewDataInfo",
      component: ViewDataInfo,
    },
    {
      path: "/",
      name: "home",
      component: home,
    },
    {
      path: "/lubo",
      name: "Recordingand",
      component: Recordingand,
    },
    {
      path: "/1v1",
      name: "1v1",
      component: oneclass,
    },
    {
      path: "/zhibo",
      name: "livebroadcast",
      component: livebroadcast,
    },
    {
      path: "/zhibokecheng",
      name: "CourseDetails",
      component: CourseDetails,
    },
    {
      path: "/shangpingxq",
      name: "Paymentdetails",
      component: Paymentdetails,
    },
    {
      path: "/bofangzhong",
      name: "Playing",
      component: Playing,
    },
    {
      path: "/gongkaike",
      name: "Openclass",
      component: Openclass,
    },
    {
      path: "/gaishoujihao",
      name: "gaishoujihao",
      component: gaishoujihao,
    },
    {
      path: "/zhucezh",
      name: "zhucezh",
      component: zhucezh,
    },
    {
      path: "/downloadApp",
      name: "downLoadApp",
      component: downLoadApp,
    },

    // 糖
    {
      path: "/guanyu",
      name: "guanyu",
      component: guanyu,
    },
    {
      path: "/kechengchongzhi",
      name: "kechengchongzhi",
      component: kechengchongzhi,
    },
    {
      path: "/liaotian",
      name: "liaotian",
      component: liaotian,
    },
    {
      path: "/my",
      name: "my",
      component: my,
    },
    {
      path: "/myNote",
      name: "MyNote",
      component: MyNote,
    },
    {
      path: "/noteDetail",
      name: "NoteDetail",
      component: NoteDetail,
    },
    {
      path: "/myshoucang",
      name: "myshoucang",
      component: myshoucang,
    },
    {
      path: "/myStudy",
      name: "myStudy",
      component: myStudy,
    },
    {
      path: "/public",
      name: "Public",
      component: Public,
    },
    {
      path: "/sheZhi",
      name: "sheZhi",
      component: sheZhi,
    },
    {
      path: "/ziliao",
      name: "ziliao",
      component: ziliao,
    },
    // ming
    {
      path: "/yueke",
      name: "yueke",
      component: yueke,
    },
    {
      path: "/yuekelslb",
      name: "yuekelslb",
      component: yuekelslb,
    },
    {
      path: "/kechengzhongxin",
      name: "kechengzhongxin",
      component: kechengzhongxin,
    },
    {
      path: "/kechengzixun",
      name: "kechengzixun",
      component: kechengzixun,
    },
    {
      path: "/kechengziyemian",
      name: "kechengziyemian",
      component: kechengziyemian,
    },
    {
      path: "/sousuo",
      name: "sousuo",
      component: sousuo,
    },
    {
      path: "/HistoryViewing",
      name: "HistoryViewing",
      component: HistoryViewing,
    },
    {
      path: "/xiaoxi",
      name: "xiaoxi",
      component: xiaoxi,
    },
    {
      path: "/zhibozhonga",
      name: "zhibozhonga",
      component: zhibozhonga,
    },
    {
      path: "/zhuce",
      name: "zhuce",
      component: zhuce,
    },
    {
      path: "/pingtest",
      name: "pingtest",
      component: pingtest,
    },
    {
      path: "/homework",
      name: "homework",
      component: MyHomework,
    },
    {
      path: "/homeworkDetail",
      name: "homeworkDetail",
      component: HomeworkDetail,
    },
    {
      path: "/agreement",
      name: "agreement",
      component: Agreement,
    },
    {
      path: "/privacy",
      name: "privacy",
      component: Privacy,
    },
    {
      path: "/questionPaper",
      name: "questionPaper",
      component: QuestionPaper,
    },
    // 魔方网校
    {
      path: "/course-home",
      name: "CourseHome",
      component: () => import("@/components/mofang"),
    },
    {
      path: "/search-result",
      name: "InputResult",
      component: () => import("@/components/mofang/searchResult"),
    },
    {
      path: "/more-course",
      name: "MoreCourse",
      component: () => import("@/components/mofang/moreCourse"),
    },
    // 人脸识别结果页
    {
      path: "/auth-result",
      name: "AuthResult",
      component: () =>
        import("@/components/Contractgrouping/faceAuthResult.vue"),
    },
    {
      path: "/signContract",
      name: "SignContract",
      component: () => import("@/components/signContract"),
    },
    {
      path: "/createLinkPage",
      name: "CreateLinkPage",
      component: () => import("@/components/scrm/addPersenLink.vue"),
    }
  ],
});
