<template>
    <div>
        <div v-if="xiaozi == 1">
            <div class="shibait">
                <img src="../../../assets/images/smrzsb.png" alt="">
                <p class="renzhengsb">未能成功验证人脸</p>
                <p class="shibaiyy">未通过人脸识别验证，请确保您是该账号实名身份本人、网络环境流畅</p>
            </div>
        </div>
        <div v-if="xiaozi == 0">
            <div class="shibait">
                <img src="../../../assets/images/rightchengg.png" alt="">
                <p class="renzhengsb">人脸识别认证成功</p>
            </div>
            <div class="renlianwc" @click="wancheng">完成</div>
        </div>
    </div>
</template>
<script>
export default {
    name:"RealNameresult",
    data() {
        return {
            xiaozi: null
        }
    },
    created() {
        
    },
    mounted() {
        // console.log('sgsxxxxxxxxxxx====', document.location.href)
        this.coderl = this.getQueryVariable("code");
        if(this.coderl) {
            if(this.coderl == 0) {
                this.contractStatusInfo()
                this.$toast.success('验证成功')
                this.xiaozi = 0
            } else {
                this.xiaozi = 1
                this.$toast.success('人脸验证失败')
                let timer = setTimeout(() => {
                    //需要定时执行的代码
                    this.$router.replace({
                        path: "/my",
                        query: {
                            name: 1
                        }
                    })
                }, 3000)
            }        
        } else {
            this.xiaozi = 1
        }
    },
    methods: {
        contractStatusInfo() {
            this.$api.post('/user/app/faceCallback', {
                code: this.coderl,
                orderNo: this.getQueryVariable('orderNo'),
                callback_url: window.location.href
            }).then(res=>{
                if(res.data.code == 200) {
                    this.$router.replace({
                        path: "/FaceResults"
                    })
                }
            })
        },
        getQueryVariable(variable) {
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {
                return pair[1];
                }
            }
            return false;
        },
        wancheng() {
            this.$router.push({
                path: "/contractmanagement",
                query: {
                  chenggongid: 2
                }
            })
        }
    }
}
</script>
<style scoped>
.renlianwc {
    width: 343px;
    height: 42px;
    background: #1872ed;
    border-radius: 4px;
    font-size: 16px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 42px;
    margin: 0 auto;
    margin-top: 143px;
}
.shibaiyy {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #999999;
    margin-top: 8px;
    margin-left: 33px;
    margin-right: 33px;
}
.renzhengsb {
    font-size: 20px;
    font-family: Noto Sans SC, Noto Sans SC-500;
    font-weight: 600;
    color: #202020;
    margin-top: 16px;
}
.shibait {
    text-align: center;
    margin-top: 63px;
}
* {
    margin: 0;
    padding: 0;
}
</style>