<template>
    <div>
        <div class="gantan">
            <img style="width: 74px;" src="../../../assets/images/rightzhbf.png" alt="">
        </div>
        <p class="gaizhangh">该账号非签署合同的账号，请重新确认</p>
        <div class="queding" @click="wancheng">确定</div>
    </div>
</template>
<script>
export default {
    name:"AccounTmismatch",
    data() {
        return {}
    },
    created() {
        console.log('222222222', this.$route.query.redirectUrl)
        
    },
    mounted() {
           // console.log('sgsxxxxxxxxxxx====', document.location.href)

    },
    methods: {
        wancheng() {
            this.$router.push({ name: 'zhanghaomimadl', query: { redirectUrl: this.$route.query.redirectUrl } })
        }
    }
}
</script>
<style scoped>
.queding {
    width: 343px;
    height: 42px;
    background: #1872ed;
    border-radius: 4px;
    margin: 0 auto;
    font-size: 16px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 42px;
    margin-top: 143px;
}
.gaizhangh {
    font-size: 16px;
    font-family: Noto Sans SC, Noto Sans SC-500;
    font-weight: 500;
    text-align: center;
    color: #202020;
    margin-top: 20px;
}
.gantan {
    text-align: center;
    margin-top: 94px;
}

* {
    margin: 0;
    padding: 0;
}
</style>