<template>
    <div class="announcement-previewer padding_top" :style="cptStyle">
        <div class="notice-content" :style="noticeStyle" @click="gotoDetail">
            <div class="info">
                <img v-if="component.show_icon_type === '2'" :src="noticeIcon" width="16" height="16">
                <div ref="noticeScroll" class="scroll">
                    <p ref="notice" class="title" :class="{ 'auto-scroll':isScroll }" :style="fontStyle">
                        {{ component.content || defaultContent }}
                    </p>
                </div>
            </div>
            <img class="link" v-if="component.show_link_icon_type === '2'" :src="linkIcon" width="16" height="16">
        </div>
    </div>
</template>

<script>
import { gotoPageByConfig } from '@/utils'

export default {
    name: 'announcementPreviewer',
    props: ['component'],
    computed: {
        cptStyle() {
            return {
                padding: `10px ${this.component.padding_around}px 0`
            }
        },
        noticeStyle() {
            const borderRadius = this.component.border_radius_type === '1' ? '0' : this.component.border_radius
            return {
                borderRadius: `${borderRadius}px`,
                backgroundColor: this.component.bg_color
            }
        },
        fontStyle() {
            return {
                color: this.component.font_color
            }
        },
        noticeIcon() {
            return require(`@/assets/images/mofang/${this.component.icon}`)
        },
        linkIcon() {
            return require(`@/assets/images/mofang/${this.component.link_icon}`)
        }
    },
    watch: {
        'component.content': {
            handler() {
                this.isAutoScroll()
            },
            immediate: true
        }
    },
    data() {
        return {
            isScroll: false,
            defaultContent: '请填写内容，如果过长，将会在手机上滚动显示'
        }
    },
    methods: {
        isAutoScroll() {
            this.$nextTick(() => {
                const titleW = this.$refs.notice.clientWidth
                const scrollW = this.$refs.noticeScroll.clientWidth
                if(scrollW < titleW) {
                    this.isScroll = true
                } else {
                    this.isScroll = false
                }
            })
        },
        gotoDetail() {
            if(this.component.link_switch === '0') {
                return
            }
            const item = this.component.link_list[0]
            item && gotoPageByConfig(item)
        }
    }
}
</script>

<style lang="less" scoped>
.padding_top {
    padding-top: 10px;
}
.announcement-previewer {
    .notice-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 36px;
        background: #f5f5f5;
        font-size: 12px;
        padding: 0 5px;
        .info {
            display: flex;
            align-items: center;
            width: calc(100% - 20px);
            .scroll {
                width: 100%;
                overflow: hidden;
                margin-left: 5px;
            }
            .title {
                // overflow: hidden;
                // text-overflow: ellipsis;
                width: fit-content;
                white-space: nowrap;
                &.auto-scroll {
                    // margin-left: 305px;
                    // padding-right: 300px;
                    animation: marquee 30s linear infinite;
                }
                // &:hover {
                //     animation-play-state: paused;
                // }
            }
        }
    }
    @keyframes marquee {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-100%);
        }
    }
}
</style>