import { render, staticRenderFns } from "./oneClass.vue?vue&type=template&id=71cbdac4&scoped=true"
import script from "./oneClass.vue?vue&type=script&lang=js"
export * from "./oneClass.vue?vue&type=script&lang=js"
import style0 from "./oneClass.vue?vue&type=style&index=0&id=71cbdac4&prod&scoped=true&lang=css"
import style1 from "./oneClass.vue?vue&type=style&index=1&id=71cbdac4&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71cbdac4",
  null
  
)

export default component.exports