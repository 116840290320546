<template>
    <div class="search" :style="cptStyles">
        <div class="content" :style="inputStyles" @click="gotoPage">
            <van-icon class="search-icon" name="search" :style="[iconStyles]" />
            <span  :style="[fontColorStyle]">{{ placeholderText }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "searchPreviewer",
    props: ['component'],
    computed: {
        cptStyles() {
            const color = this.component.bg_type === '2' ? this.component.bg_color : 'inherit'
            return {
                backgroundColor: color,
                padding: `0 ${this.component.padding_around}px`
            }
        },
        placeholderText() {
            return this.component.placeholder
        },
        iconStyles() {
            let mRight = 0
            if(this.component.content_position !== '3') {
                mRight = 5
            }
            return {
                marginRight: `${mRight}px`,
                color: this.component.icon_color
            }
        },
        inputStyles() {
            let s;
            if(this.component.content_position === '1') {
                s = { justifyContent: 'left' }
            } else if(this.component.content_position === '2') {
                s = { justifyContent: 'center' }
            } else {
                s = { justifyContent: 'space-between', flexDirection: 'row-reverse' }
            }
            if(this.component.border_radius_type === '1') {
                s.borderRadius = 0
            }
            return {
                borderRadius: `${this.component.border_radius}px`,
                backgroundColor: this.component.input_bg_color,
                ...s,
            }
        },
        fontColorStyle() {
            return {
                color: this.component.font_color
            }
        },
    },
    methods: {
        gotoPage() {
            this.$router.push({ name: 'InputResult' })
        }
    }
}
</script>

<style lang="less" scoped>
    .search {
        padding: 0 10px;
        .content {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 36px;
            font-size: 14px;
            padding: 0 10px;
            box-sizing: border-box;
            .search-icon{
                font-size: 16px;
            }
        }
    }
</style>
