import store from '@/store/store'
import COS from 'cos-js-sdk-v5'
import { getOnlineNotFixApi } from '@/utils/useProxyApi'

function two(num) {
    if (num < 10) {
        return '0' + num
    }
    return num
}

function createCosInstance() {
    return new COS({
        getAuthorization: function (options, callback) {
            // 异步获取临时密钥
            // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
            // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
            // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
            // var url = `${process.env.VUE_APP_API_ONLINE}/onlineapi/common/index/getCosSign` // url替换成您自己的后端服务
            var url = `${getOnlineNotFixApi()}/onlineapi/common/index/getCosSign`
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.onload = function (e) {
                try {
                    var data = JSON.parse(e.target.responseText)
                    var credentials = data.data.credentials
                    // eslint-disable-next-line no-empty
                } catch (e) { }
                if (!data || !credentials) {
                    return console.error('credentials invalid:\n' + JSON.stringify(data, null, 2))
                }
                callback({
                    TmpSecretId: credentials.tmpSecretId,
                    TmpSecretKey: credentials.tmpSecretKey,
                    SecurityToken: credentials.sessionToken,
                    // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                    StartTime: data.data.startTime, // 时间戳，单位秒，如：1580000000
                    ExpiredTime: data.data.expiredTime // 时间戳，单位秒，如：1580000000
                })
            }
            xhr.send()
        },
        UseAccelerate: true
    })
}

/**
 * 描述：文件上传到COS桶
**/
export default function uploadFile(file, dirPath, processCb) {
    return new Promise((resolve, reject) => {
        const time = new Date()
        const y = time.getFullYear()
        const m = two(time.getMonth() + 1)
        const d = two(time.getDate())
        const instance = createCosInstance()
        instance.putObject({
            // Bucket: 'tengface-live-user-1308721187', /* 填入您自己的存储桶，必须字段 */
            Bucket: process.env.VUE_APP_BUCKET,
            Region: 'ap-guangzhou', /* 存储桶所在地域，例如ap-beijing，必须字段 */
            // Key: (new Date()).getTime() + file.name, /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
            Key: `${store.state.user.org_id}/${dirPath}/${y}/${m}/${d}/${(new Date()).getTime()}_${file.name}`,
            StorageClass: 'STANDARD',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
                console.log(JSON.stringify(progressData))
                processCb && processCb(progressData)
            }
        }, (err, data) => {
            if (data.statusCode === 200) {
                resolve(`https://${data.Location}`)
            } else {
                reject(err)
            }
        })
    })
}