<template>
  <div class="info-list-box">
    <div class="info-list">
      <div class="video-title" :title="listObj.video_name">
        视频标题：{{ listObj.video_name }}
      </div>
      <div class="time-con">
        <span class="video-time">视频时长：{{ listObj.duration }}</span>
        <span class="completionRate"
          >完成率：
          <span style="color: #fb7b7d"
            >{{
              Number(listObj.complete_rate) > 100 ? 100 : listObj.complete_rate
            }}%</span
          >
        </span>
      </div>
      <span class="accumulate-time">累计视频时长：{{ listObj.view_time }}</span>
    </div>
  </div>
</template>

<script>
import { secondsToTimeFormat } from '../../utils';

export default {
  props: {
    itemObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      listObj: {},
    };
  },
  mounted() {
    this.listObj = JSON.parse(JSON.stringify(this.itemObj));
    this.listObj.duration = secondsToTimeFormat(this.itemObj.duration);
    this.listObj.view_time = secondsToTimeFormat(
      this.itemObj.view_time > this.itemObj.duration
        ? this.itemObj.duration
        : this.itemObj.view_time
    );
  },
};
</script>

<style scoped lang="scss">
.info-list {
  width: 100%;
  height: 100px;
  background: #f9fbff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  .video-title {
    font-size: 14px;
    color: #202020;
    font-weight: 400;
    margin-bottom: 10px;
    font-family: PingFang SC, PingFang SC-400;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .time-con {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #666;
    font-family: PingFang SC, PingFang SC-400;
  }
  .accumulate-time {
    font-size: 14px;
    color: #666;
    font-family: PingFang SC, PingFang SC-400;
  }
}
</style>
