import { render, staticRenderFns } from "./Agreementlist.vue?vue&type=template&id=d1990f20&scoped=true"
import script from "./Agreementlist.vue?vue&type=script&lang=js"
export * from "./Agreementlist.vue?vue&type=script&lang=js"
import style0 from "./Agreementlist.vue?vue&type=style&index=0&id=d1990f20&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1990f20",
  null
  
)

export default component.exports