<template>
    <div>
        <div v-if="$route.query.chenggongid == 1">
            <div class="shibait">
                <img src="../../../assets/images/smrzsb.png" alt="">
                <p class="renzhengsb">认证失败</p>
                <p class="shibaiyy">失败原因：姓名与手机号实名信息不匹配</p>
            </div>
            <div class="shibaixx">
                <div class="zhengshixm"><p style="margin-top: 16px;margin-left: 16px;">真实姓名</p><p style="color: #202020;margin-top: 16px;margin-right: 16px;">{{$route.query.name}}</p></div>
                <div class="zhengshixm"><p style="margin-top: 12px;margin-left: 16px;">证件号</p><p style="color: #202020;margin-top: 12px;margin-right: 16px;">{{$route.query.idcard}}</p></div>
                <div class="zhengshixm"><p style="margin-top: 10px;margin-left: 16px;">手机号</p><p style="color: #202020;margin-top: 10px;margin-right: 16px;">{{$route.query.mobile}}</p></div>
            </div>
            <div class="zaishiyici" @click="zishiyici()">再试一次</div>
            <div class="tuichurz" @click="tuichurenz()">退出认证</div>
        </div>
        <div v-if="$route.query.chenggongid == 2">
            <div class="shibait">
                <img src="../../../assets/images/rightchengg.png" alt="">
                <p class="renzhengsb">认证成功</p>
                <p class="shibaiyy">即将自动跳转...</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"RealNameresult",
    data() {
        return {}
    },
    mounted() {
        if(this.$route.query.chenggongid == 2) {
            let timer = setTimeout(() => {
                //需要定时执行的代码
                this.$router.push({
                    path: "/contractmanagement",
                    query: {
                        name: 1
                    }
                })
            }, 3000)
        }
    },
    created() {
        // console.log('222222222', this.$route.query.name, this.$route.query.idcard, this.$route.query.mobile, this.$route.query.chenggongid)
    },
    methods: {
        tuichurenz() {
            this.$router.push({
                    path: "/my",
                    query: {
                        name: 1
                    }
                })
        },
        zishiyici() {
            this.$router.push({
                    path: "/Personalauthentication",
                    query: {
                        name: 1
                    }
                })
        }
    }
}
</script>
<style scoped>
.tuichurz {
    font-size: 16px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    text-align: center;
    color: #666666;
    width: 343px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    line-height: 42px;
    margin: 0 auto;
    margin-top: 11px;
}
.zaishiyici {
    width: 343px;
    height: 42px;
    background: #1872ed;
    border-radius: 4px;
    margin: 0 auto;
    font-size: 16px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 42px;
    margin-top: 21px;
}
.zhengshixm {
    font-size: 12px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #666666;
    display: flex;
    justify-content: space-between;
}
.shibaixx {
    width: 343px;
    height: 107px;
    background: #f7faff;
    border-radius: 2px;
    margin: 0 auto;
    margin-top: 34px;
}
.shibaiyy {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #999999;
    margin-top: 8px;
}
.renzhengsb {
    font-size: 20px;
    font-family: Noto Sans SC, Noto Sans SC-500;
    font-weight: 600;
    color: #202020;
    margin-top: 16px;
}
.shibait {
    text-align: center;
    margin-top: 63px;
}
* {
    margin: 0;
    padding: 0;
}
</style>