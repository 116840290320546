<template>
    <div class="padding_top component-container">
        <div class="image-wrap has_img" v-if="img_url" @click="gotoDetail">
                <img :src="img_url" />
        </div>
        <div class="image-wrap" v-else>
            <div class='image-previewer'>
                <img  src="@/assets/images/mofang/image-preview.png" />
            </div>
        </div>
    </div>
</template>

<script>
import { gotoPageByConfig } from '@/utils'

export default {
    name: 'imagePreviewer',
    props: ['index', 'component'],
    computed: {
        img_url(){
            return this.component.img_url
        }
    },
    methods: {
        gotoDetail() {
            const { type, isLive, live_type, content: { url, ds_id, id,link,type:material_type },content } = this.component.jump_info
            if(this.component.has_jump_links) {
                gotoPageByConfig({
                    id,
                    type,
                    isLive,
                    live_type,
                    url:type === '3' ? content : link,
                    detail_id: ds_id || id,
                    material_type,
                    ds_id: ds_id
                })
            }    
        }
    }
}
</script>

<style lang='less' scoped>
.padding_top {
    padding-top: 10px;
}
.image-wrap {
    // padding: 0 10px;
    margin: 0 10px;
    border-radius: 10px;
    .image-previewer {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
        img {
            width: 104px;
            height: 86px;
            padding: 76px 0;
            border-radius: 10px;
        }
    }
}
.image-wrap.has_img {
    img {
        width: 100%;
        border-radius: 10px;
    }
}

</style>
