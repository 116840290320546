import { render, staticRenderFns } from "./homeworkDetail.vue?vue&type=template&id=21432f26&scoped=true"
import script from "./homeworkDetail.vue?vue&type=script&lang=js"
export * from "./homeworkDetail.vue?vue&type=script&lang=js"
import style0 from "./homeworkDetail.vue?vue&type=style&index=0&id=21432f26&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21432f26",
  null
  
)

export default component.exports