<template>
  <div class="liveBroadcast" :ref="'course_chapter' + 0" :style="styles">
    <!-- 第一部分 -->
    <div class="yuyuyu">
      <van-popup
        v-model="code"
        closeable
        title=""
        style="border-radius: 10px; width: 310px; height: 230px"
        width="320px"
        theme="round-button"
        showConfirmButton="false"
      >
        <h3 style="text-align: center" class="kechengduihuan">课程兑换码</h3>
        <p class="huoqulist">兑换码请联系相关老师领取</p>
        <form label-width="120px" style="margin-top: 20px">
          <div
            style="
              width: 250px;
              height: 40px;
              margin: 0 auto;
              text-align: center;
            "
          >
            <input
              style="text-align: left"
              class="codeshuru"
              v-model="codeData"
              placeholder="请输入课程兑换码"
            />
            <div style="height: 20px; text-align: left">
              <p class="yiguoqilist">{{ this.errData }}</p>
            </div>
          </div>
        </form>
        <div style="text-align: center; margin-top: 5px">
          <button class="lijiduan" type="text" @click="useCdk()">
            立即兑换
          </button>
        </div>
      </van-popup>
    </div>
    <div class="headerBox">
      <div class="diyi">
        <img v-show="!playStatus" class="courseCover" :src="cover" />
        <div class="overlayer" v-show="showPlayIcon">
          <img
            class="change"
            src="@/assets/images/bofang.png"
            alt=""
            srcset=""
            @click="playVideo"
          />
        </div>
        <template v-if="isThreeScreenCourse">
          <div id="CameraVideoTopDiv"></div>
          <SHPlayer
            id="CameraVideoDiv"
            ref="cameraVideo"
            videoType="vod"
            @timeupdate="handleListenCurTimeChangeCameraVideo"
            :key="1"
            :speedValue="cameraVideoSpeed"
            :playerType="'tcPlayer'"
            :class="isSwitchPosition ? 'white-board' : 'cameraVideo'"
            @playSpeed="handlePlaySpeed"
            @seeking="handleSeek"
            @play="onPlayerPlay"
            :hideProgress="false"
            @pause="handlePause"
            @loadeddata="onLoadeddata"
            @playing="playingFn"
            @ended="onPlayerEnded"
            @getDuration="getCameraDuration"
            :isMutedReal="isMuted"
            :fans_id="fans_id"
            :runObj="runObj"
            :copyrightObj="copyrightObj"
            :nick="nick"
            :remark="remark"
          />
          <div
            v-if="isThreeScreenCourse && memoryPoints != 0 && shangciGk"
            class="shangcigk"
          >
            上次观看至{{ transformation(memoryPoints) }}
          </div>
        </template>
        <template>
          <SHPlayer
            videoType="vod"
            ref="mainVideo"
            :key="2"
            v-if="!isThreeScreenCourse"
            :playerType="playerType"
            @timeupdate="handleListenCurTimeChange"
            @play="onPlayerPlay"
            @pause="onPlayerPause"
            @ended="onPlayerEnded"
            @unAutoPlay="dayusantishi"
            @loadedmetadata="onLoadedmetadata"
            @playing="playingFn"
            @playSpeed="handlePlaySpeed"
            class="main-video"
            :fans_id="fans_id"
            :subtitles="videoSubtitlesContents"
            :openSubtitles="subtitlesStatus"
            :subtitleOptions="{
              bottom: '5px',
              fontSize: '14px',
              padding: '4px 8px',
            }"
            :runObj="runObj"
            :copyrightObj="copyrightObj"
            :nick="nick"
            :remark="remark"
          />
          <div
            v-if="!isThreeScreenCourse && memoryPoints != 0 && shangciGk"
            class="shangcigk"
          >
            上次观看至{{ transformation(memoryPoints) }}
          </div>
          <div v-if="!isThreeScreenCourse && kuaijieshu" class="shangcigkyi">
            即将播放下一个 &nbsp;{{ jueshusp.toFixed(0) }}
          </div>
          <!-- <video
            ref="tfPlayer"
            id="tfPlayer"
            autoplay
            :videoPoster="cover"
            :VODData="VODData"
            :playType="'vod'"
            class="tfPlayer"
          /> -->
        </template>

        <div style="height: 90px; width: 100%">
          <div>
            <img
              class="fanhui"
              @click="goOff()"
              style="margin-top: 5px"
              src="../assets/images/fanhuizhong.png"
            />
          </div>
        </div>
      </div>

      <template>
        <div v-if="isThreeScreenCourse" class="threeScreenPlayer">
          <div id="whiteBoardTopDiv"></div>
          <SHPlayer
            id="whiteBoardDiv"
            videoType="vod"
            :class="isSwitchPosition ? 'cameraVideo' : 'white-board'"
            :key="3"
            :playerType="playerThreeType"
            :showRate="false"
            :hideProgress="true"
            ref="whiteBoard"
            :speedValue="whiteBoardSpeed"
            @play="onPlayerPlay"
            @pause="onPlayerPause"
            @toggle-mute="handleToggleMute"
            @loadedmetadata="onLoadedmetadata"
            @getDuration="getWhiteDuration"
            :fans_id="fans_id"
            :subtitles="videoSubtitlesContents"
            :openSubtitles="subtitlesStatus"
            :subtitleOptions="{
              bottom: '5px',
              fontSize: '14px',
              padding: '4px 8px',
            }"
            :runObj="runObj"
            :copyrightObj="copyrightObj"
            :nick="nick"
            :remark="remark"
            :isThird="false"
          />
          <img
            class="positionIcon"
            src="@/assets/img/switchIcon.png"
            @click="cutSwitch"
          />
          <div
            class="overlayer"
            v-show="showPlayIcon"
            style="
              position: absolute;
              top: 0;
              z-index: 1000;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.5);
            "
          ></div>
        </div>
        <!-- <div v-if="isThreeScreenCourse" class="shangcigk">上次观看至{{transformation(memoryPoints)}}</div> -->
      </template>
    </div>
    <div v-show="!makeNote" class="contentBox">
      <div class="debf">
        <p class="fafa">{{ name }}</p>
        <div>
          <div class="zhibosj">
            直播时间：{{ start_timeList.start_time }}
            <div v-if="numStatus == 1" class="rightStudy">
              <img src="../assets/img/guankan.png" />
              <span class="qishi">{{ studyNum }}人学习</span>
            </div>
          </div>
          <div class="mytestping" v-if="comment">我的评测：{{ comment }}</div>
        </div>
      </div>
      <!-- <div class="lineBox"></div> -->
      <div class="dsanbf" :style="pannelStyle">
        <div class="dsanbf_header">
          <div>
            <p
              style="float: left"
              :class="[{ active: tabIndex === 1 }, 'dsanbf_title']"
              @click="tabChange(1)"
            >
              课程目录
            </p>
            <p
              style="float: left"
              :class="[{ active: tabIndex === 2 }, 'dsanbf_title']"
              @click="tabChange(2)"
            >
              课程介绍
            </p>
            <p
              style="float: left"
              :class="[{ active: tabIndex === 3 }, 'dsanbf_title']"
              @click="tabChange(3)"
              v-show="subtitlesStatus"
            >
              视频文稿
            </p>
          </div>
          <div class="banji" @click="showClass = true">
            切换班级:
            <span class="class-name">
              {{ currClassName }}
              <van-icon
                v-if="showClass == false"
                style="margin-left: -3px"
                name="arrow"
              />
              <van-icon
                style="margin-left: -3px"
                v-else-if="showClass == true"
                name="arrow-down"
              />
            </span>
          </div>
          <!-- <div style="clear: both"></div> -->
        </div>
        <VideoDraft
          ref="videoDraft"
          v-show="tabIndex === 3 && subtitlesStatus"
          :subtitles="videoSubtitlesContents"
          @updatePlayerTime="updatePlayerTime"
          :isSelectVideo="isSelectVideo"
        />
        <van-collapse
          v-show="tabIndex === 1"
          style="margin-top: 0.43rem; padding: 0 0.43rem"
          v-model="activeNames"
          :border="false"
        >
          <div class="sousuozj">
            <van-search
              v-model="value2"
              shape="round"
              @input="listSections('', 1)"
              placeholder="请输入搜索关键词"
            />
          </div>
          <van-collapse-item
            class="zhangjielist"
            :id="'zhangjielist' + item.sectionId"
            :ref="'collapseId' + item.sectionId"
            v-for="item in optionsa"
            :key="item.id"
            :title="item.sectionName"
            :name="item.sectionId"
          >
            <div
              style="margin-top: 0.43rem"
              v-for="(item1, index) in item.chapters"
              :key="index"
              :ref="'chapterJie' + item1.id"
            >
              <div class="liveInformation">
                <span class="linyi">{{
                  index > 8 ? index + 1 : '0' + (index + 1)
                }}</span>
                <span v-if="item1.type === 3" class="huifanga">回放</span>
                <span v-if="item1.type === 2" class="huifanga">录播</span>
                <span
                  v-else-if="
                    item1.type === 4 || (item1.type === 1 && item1.status !== 3)
                  "
                  class="huifangb"
                  >直播</span
                >
                <span
                  v-if="item1.status !== 3"
                  class="chushia"
                  @click="showMore(item1.id)"
                  :class="{ show: id == item1.id }"
                  >{{ item1.name }}</span
                >
                <span v-else-if="item1.status === 3" class="chushialist">{{
                  item1.name
                }}</span>
                <span v-if="item1.show == 1" class="keduihuan">可兑换</span>
              </div>
              <div style="margin-top: 4px">
                <span
                  class="shijiand"
                  v-if="!classHideTime || item1.type !== 2"
                  >{{ item1.start_time }}</span
                >
                <div
                  v-if="item1.status === 4 || item1.status === 2"
                  @click="liveAuth(item1, item1.show)"
                  class="jirzb"
                >
                  进入直播
                </div>
                <div v-if="item1.status === 3" class="jirzblist">
                  直播已结束
                </div>
              </div>

              <div
                v-if="item1.videos.length > 3 && item1.type === 3"
                class="sort"
              >
                <div
                  :class="sortimgs[item1.id] ? 'stbx stbx1' : 'stbx'"
                  @click="sorta(item1.id)"
                >
                  {{
                    sortform[item1.id] == 1
                      ? '升序'
                      : sortform[item1.id] == 0
                      ? '降序'
                      : '排序'
                  }}<img
                    class="sortimg"
                    v-if="!sortimgs[item1.id]"
                    src="@/assets/images/sort.png"
                    alt=""
                  /><img
                    v-else
                    class="sortimg"
                    :src="sortimgs[item1.id]"
                    alt=""
                  />
                </div>
              </div>
              <div
                @click="liveAutha(item, item1, item2)"
                v-for="item2 in item1.videos"
                :ref="'videoJie' + item2.id"
                :key="item2.id"
              >
                <div class="videoItem">
                  <div class="videoInformation">
                    <img class="playBtn" src="../assets/images/bofang.png" />
                    <span
                      :class="acterss == item2.id ? 'active' : ''"
                      class="chushia1"
                      >{{ item2.name }}</span
                    >
                  </div>
                  <div class="time">
                    <span style="margin-right: 5px; margin-left: 25px">{{
                      item2 && transformation(item2.duration)
                    }}</span>
                    <span class="aglook" v-if="item2.is">上次看到</span>
                  </div>
                </div>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
        <div
          v-if="showAll"
          @click="openAll"
          style="text-align: center; margin-top: 20px"
        >
          <span class="gengduo">查看更多目录</span>
          <img
            style="margin-left: 6px; width: 14px; vertical-align: -2px"
            src="../assets/img/xiangxiazhankai.png"
          />
        </div>
      </div>
      <!-- <div class="lineBox"></div> -->
      <div class="dsibf" v-if="tabIndex === 2">
        <p class="mulu">课程介绍</p>
        <div style="background: #fff; height: 100%">
          <p style="margin: 20px 17px" class="kecheng_desc" v-html="desc"></p>
        </div>
      </div>
    </div>
    <div v-show="makeNote" class="contentBox noteBox">
      <div class="myNote">
        <p class="title-note">我的笔记</p>
        <p style="color: #999999" v-if="noteTime">
          自动保存成功:{{ noteTime }}
        </p>
        <div class="btnBox">
          <div class="signOut" @click="signOutNote">退出</div>
          <div class="save" @click="saveNote">保存</div>
        </div>
      </div>
      <div class="empty"></div>
      <div class="content">
        <!-- <img @click="signCut" class="videoCut" src="./../assets/images/note-cut.png" alt="">
         <img @click="videoNode" class="nodeSign" src="@/assets/images/note-sign.png" alt=""> -->
        <TFQuillEditor
          ref="myQuillEditor"
          :quill-editor-value="noteContent"
          @editorContentChange="changeTFQuillEditor"
          @signCut="signCut"
          @videoNode="videoNode"
        />
        <div
          class="nodeContainer"
          v-for="(item, index) in noteNode"
          :key="index"
          @click="toNode(item)"
        >
          <img src="@/assets/images/note-sign.png" alt="" />
          <span class="time">{{ item.time }}</span>
          <span class="videoNodeTitle">{{ item.title }}</span>
          <span
            style="margin-left: 10px; color: darkgray"
            @click="shanchujl(item, index)"
            >x</span
          >
        </div>
      </div>
      <div class="modal-bg" v-if="videNodeShow">
        <div
          class="modal-container"
          :style="isThreeScreenCourse ? 'top:20%;' : 'top:35%;'"
        >
          <img
            @click="nodeClose"
            class="close-img"
            src="@/assets/images/note-node-close.png"
            alt=""
          />
          <div class="modal-header">
            <img src="@/assets/images/note-sign1.png" alt="" />
            <div class="line"></div>
            <span>{{ nodeTitle }}</span>
          </div>
          <div style="position: relative">
            <input
              class="nodde-input"
              type="text"
              maxlength="15"
              placeholder="写点什么(选填)"
              v-model="nodeContent"
            />
            <div class="limit">{{ nodeContent.length }}/15</div>
          </div>
          <div class="modal-footer" @click="addNodeConfirm">确认保存</div>
        </div>
      </div>
      <div class="modal-bg" v-if="closeVideoShow">
        <div
          class="modal-container"
          :style="isThreeScreenCourse ? 'top:20%;' : 'top:35%;'"
          style="width: 7.3rem; height: 3.7rem"
        >
          <div class="modal-header">
            <span>确认退出笔记吗?</span>
          </div>
          <div class="model-btn">
            <p class="nq" @click="noteCancel">取消</p>
            <p class="nc" @click="nodeCancelConfirm">确认</p>
          </div>
        </div>
      </div>
    </div>
    <canvas :width="canvasWidth" :height="canvasHeight" ref="canvas"></canvas>
    <van-popup
      v-model="showClass"
      position="bottom"
      round
      :style="{ height: '50%' }"
    >
      <div>
        <p class="xuanban">请选择班级</p>
        <img
          @click="guanbiclassget()"
          class="guanbiclass"
          src="../assets/images/guanbilist.png"
          alt=""
        />
        <div
          v-for="item in options"
          :key="item.period"
          @click="classChange(item)"
        >
          <p
            class="luse"
            :style="{ color: item.select ? '#1872ed' : '#000000' }"
          >
            {{ item.period }}
          </p>
          <div v-if="item.select" class="xuanzhong">
            <img src="../assets/images/xuanzhongsf.png" alt="" />
          </div>
          <div v-else class="xuanzhong">
            <img src="../assets/images/weixuanertu.png" alt="" />
          </div>
        </div>
      </div>
    </van-popup>
    <MyToast ref="access"></MyToast>
    <div class="noteBtn" v-if="videoPlay" v-show="!makeNote">
      <img
        @click="doNote"
        class="note-img"
        src="../assets/images/note.png"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import { ActionSheet, Toast, Popup } from 'vant';
import SHPlayer from 'shplayer';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import { ref } from 'vue';
import { Dialog } from 'vant';
import MyToast from './MyToast.vue';
import TFQuillEditor from '@/components/quillEditor/index.vue';
import VideoDraft from '@/components/videoDraft.vue';
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(ActionSheet);
export default {
  name: 'liveBroadcast',
  computed: {
    ...mapGetters({
      org_id: 'user/getOrgId',
      fans_id: 'user/getFansId',
      remark: 'user/getRemark',
    }),
    styles() {
      const val = this.isThreeScreenCourse ? 10.66 : 5.33;
      return {
        paddingTop: `${val}rem`,
        height: `calc(100% - ${val}rem)`,
      };
    },
    pannelStyle() {
      return {
        height: this.tabIndex === 3 ? 'calc(100% - 1.92rem)' : 'auto',
      };
    },
    contentBoxStyles() {
      return {
        height: this.isThreeScreenCourse
          ? 'calc(100vh - 10.89rem)'
          : 'calc(100vh - 5.48rem)',
      };
    },
  },
  data() {
    return {
      isFirstEnter: false,
      classHideTime: false,
      progressTime: 0,
      whiteDuration: 0,
      cameraDuration: 0,
      cameraVideoSpeed: 1,
      whiteBoardSpeed: 1,
      playerType: 'tcPlayer',
      playerThreeType: 'tcPlayer',
      is_enable_caption: 2,
      isSelectVideo: false,
      subtitlesStatus: false,
      videoSubtitlesContents: [],
      tabIndex: 1,
      intervalID: null,
      runObj: {
        open: false,
        fontSize: '',
        fontColor: '',
        fontTrans: 1,
        remark: false,
      },
      copyrightObj: {
        open: false,
        inputValue: '',
        copyrightTrans: 1,
      },
      nick: '',
      isMuted: false,
      sortimgs: {},
      sortform: {},
      acterss: null,
      domain: 'test.tenghuiwangxiao.cn',
      name: '',
      desc: '',
      options: [],
      value: '',
      optionsa: [],
      optionsaAll: [],
      class_id: '',
      showAll: false,
      optionsaAlla: [],
      start_timeList: '',
      studyNum: 0,
      vodDomduration: '',
      numStatus: '',
      show: false,
      showClass: false,
      currClassName: '',
      cover: '',
      VODData: null,
      activeNames: [],
      currentPlayerData: null,
      heartBeat: null,
      playStatus: '',
      vodDom: null,
      scrollTop: '',
      videotimet: 0,
      fileId: '',
      wx_h5_url: 'https://u.shanhuclass.cn',
      currentSectionId: '',
      currentVideoId: '',
      sectionVideoList: [],
      comment: '',
      isFirst: true,
      innerItems: {},
      cameraVideoUrl: '',
      whiteBoardVideoUrl: '',
      remainingTime: 0,
      code: false,
      codeData: '',
      codeArr: [],
      errData: '',
      licenseUrl:
        'https://license.vod2.myqcloud.com/license/v2/1317978474_1/v_cube.license',
      cameraVideoPlayer: null,
      whiteBoardPlayer: null,
      tfPlayer: null,
      isThreeScreenCourse: false,
      playParams: {},
      whiteBoardPlayParams: {},
      cameraVideoPlayParams: {},
      memoryPoints: 0,
      playType: '',
      whiteBoardInitOptions: {
        bigPlayButton: false,
        muted: true,
        controlBar: false,
      },
      cameraVideoInitOptions: {
        controlBar: {
          playbackRateMenuButton: false,
        },
        bigPlayButton: false,
      },
      showPlayIcon: false,
      shangciGk: true,
      kuaijieshu: false,
      jueshusp: 0,
      //上一个路由地址
      fromRoutePath: '',
      calibrationTimer: null,
      id: '',
      isSwitchPosition: false,
      chapter_id: '',
      makeNote: false,
      noteContent: '',
      canvasWidth: 0,
      canvasHeight: 0,
      cropperModel: false,
      videNodeShow: false,
      memoryTime: '',
      nodeContent: '',
      nodeTitle: '',
      noteNode: [],
      noteCourseId: '',
      noteClassId: '',
      noteSectionId: '',
      noteChapterId: '',
      noteVideoId: '',
      closeVideoShow: false,
      videoPlay: false,
      timer: null,
      noteTime: '',
      canPlayH265: false,
      value2: '', //搜索
      videoSpeed: 1,
      updateSetTime: null,
      noUpdateNote: false, // 自动保存笔记
      isIOS:
        /iPhone|iPod|iPad/.test(navigator.userAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1),
    };
  },
  created() {
    // 初次进入
    this.isFirstEnter = true;
    const url = `https://shkt-online-live-1317978474.cos.accelerate.myqcloud.com/web/config/playType.json?id=${new Date().valueOf()}`;
    fetch(url)
      .then((res) => res.json())
      .then((res) => (this.canPlayH265 = res.canPlayH265));
    this.getCourseInfo();
    this.listClass();
    this.getUserInfo();
    this.getPlayerH5URL();
    this.getConfigSetFn();
  },
  mounted() {
    if (this.$route.query.zhuanbj) {
      this.doNote(1);
    }
    this.getRun();
    this.vodDom = document.getElementById('tfPlayer');
    let myvideo = this.vodDom;
    if (this.$route.query.sectionId) {
      this.playHistoryVideo();
    }
    // 监听时间变化
    // this.handleListenCurTimeChange(myvideo)

    //监听关闭网页
    window.addEventListener(
      'beforeunload',
      // eslint-disable-next-line no-unused-vars
      (e) => this.historycallback(),
      false
    );
  },
  watch: {
    optionsa(to) {
      if (to.length > 0) {
        this.$nextTick(() => {
          //
          if (
            this.$refs.collapseId0 &&
            this.$refs.collapseId0[0] &&
            this.isFirst &&
            !this.$route.query.sectionId
          ) {
            this.$refs.collapseId0[0].toggle(true);
          } else {
            // console.log(,'this.$refs.collapseId0[0]');
            // console.log(this.$route.query.sectionId,'document.getElementById');
            if (this.$route.query.sectionId) {
              this.activeNames.push(Number(this.$route.query.sectionId));
              this.scrollTop = document.querySelector('.liveBroadcast');

              this.scrollTop.scrollTop =
                this.$refs[
                  'collapseId' + this.$route.query.sectionId
                ][0].$el.offsetTop;

              //  console.log(this.$refs['collapseId'+this.$route.query.sectionId][0].$el,'this.$refs.$el.offsetTop');
              // console.log(this.$route.query.sectionId,'this.isFirst2');
            }
          }
        });
      }
    },
    isThreeScreenCourse(val) {
      this.$nextTick(() => {
        const loadingElArr = document.querySelectorAll('.tcp-loading-spinner');
        if (val) {
          this.calibrationVideo();
          if (loadingElArr.length) {
            for (let i = 0; i < loadingElArr.length; i++) {
              loadingElArr[i].style.opacity = 0;
            }
          }
        } else {
          clearInterval(this.calibrationTimer);
          if (loadingElArr.length) {
            for (let i = 0; i < loadingElArr.length; i++) {
              loadingElArr[i].style.opacity = 1;
            }
          }
        }
      });
    },
  },
  destroyed() {},
  beforeDestroy() {
    clearInterval(this.intervalID);
    window.removeEventListener(
      'beforeunload',
      (e) => this.historycallback(),
      false
    );
    this.recordTimeBeforePageDestroy();
    this.historycallback();

    clearInterval(this.heartBeat);

    clearInterval(this.calibrationTimer);
    clearTimeout(this.updateSetTime);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromRoutePath = from.path;
      if (from.path == '/downloadApp') {
        vm.$nextTick(() => {
          window.location.reload();
        });
      }
    });
  },
  components: {
    SHPlayer,
    MyToast,
    TFQuillEditor,
    VideoDraft,
  },
  methods: {
    getConfigSetFn() {
      // 获取珊瑚后台配置数据
      const isProd = process.env.NODE_ENV === 'production';
      const host = isProd
        ? 'https://live.shkt.online'
        : 'https://live.tenghuiketang.com';
      const uid = this.$store.state.user.org_id;
      this.$api
        .post(`${host}/liveapi/customer/index/qryUserConfigs`, { uid })
        .then((res) => {
          if (res && res.data.code === 200) {
            const obj = res.data.data.find(
              (item) => item.key === 'classHideTime'
            );
            this.classHideTime = obj.value === 'true';
          }
        });
    },
    getCameraDuration(val) {
      this.cameraDuration = val;
    },
    getWhiteDuration(val) {
      this.whiteDuration = val;
    },
    playingFn() {
      this.showPlayIcon = false;
    },
    handlePlaySpeed(val) {
      if (this.isThreeScreenCourse) {
        // 三分屏同步播放倍速
        this.cameraVideoSpeed = val;
        this.whiteBoardSpeed = val;
      }
      this.videoSpeed = val;
    },
    /**
     * 描述：更新播放器时间
     * @param   : val {number} 字幕开始时间
     **/
    updatePlayerTime(val) {
      if (this.isThreeScreenCourse) {
        // 三分屏设置摄像头播放器时间
        // this.$refs.whiteBoardDiv && this.$refs.whiteBoardDiv.setCurrentTime(val)
        this.$refs.cameraVideo && this.$refs.cameraVideo.setCurrentTime(val);
      } else {
        this.$refs.mainVideo && this.$refs.mainVideo.setCurrentTime(val);
      }
    },
    /**
     * 描述：获取视频字幕
     **/
    getVideoSubtitlesContent(id) {
      console.log('id: ', id);
      this.$subApi
        .post('/caption/getCaptionContentList', {
          // file_id: '1253642695935868212'
          file_id: id,
        })
        .then((res) => {
          if (res && res.data?.code !== 200) {
            this.$message.error('获取字幕内容失败');
            return;
          }
          this.videoSubtitlesContents = res?.data?.data;
          if (this.subtitlesStatus) {
            this.isSelectVideo = true;
          }
          return;
        });
    },
    shanchujl(item, index) {
      this.noteNode.splice(index, 1);
    },
    tabChange(index) {
      this.tabIndex = index;
    },
    noteCancel() {
      this.closeVideoShow = false;
    },
    nodeCancelConfirm() {
      this.closeVideoShow = false;
      this.makeNote = false;
    },
    signOutNote() {
      this.closeVideoShow = true;
    },
    async saveNote(item) {
      console.log('[<-- this.noteContent -->]', this.noteContent);
      const res = await this.$api.post('/stu/course/saveNote', {
        course_id: this.noteCourseId,
        class_id: this.noteClassId,
        section_id: this.noteSectionId,
        chapter_id: this.noteChapterId,
        video_id: this.noteVideoId,
        title: '',
        content: this.noteContent,
        node_mark: JSON.stringify(this.noteNode),
      });
      if (res.data.code == 200) {
        if (item != 1) {
          this.makeNote = false;
          Toast('保存成功');
        }
      }
      console.log('[<-- res保存笔记 -->]', res);
    },
    toNode(item) {
      if (this.isThreeScreenCourse) {
        this.$refs.whiteBoard.setCurrentTime(item.memory);
      } else {
        this.$refs.mainVideo.setCurrentTime(item.memory);
      }
    },
    nodeClose() {
      this.nodeTitle = this.nodeContent = this.memoryTime = '';
      this.videNodeShow = false;
    },
    addNodeConfirm() {
      this.noteNode.push({
        time: this.nodeTitle,
        title: this.nodeContent,
        memory: this.memoryTime,
      });
      this.nodeTitle = this.nodeContent = this.memoryTime = '';
      this.videNodeShow = false;
    },
    videoNode() {
      if (this.isThreeScreenCourse) {
        const time = this.$refs.whiteBoard.getCurrentTime();
        this.memoryTime = time;
        this.nodeTitle = this.$refs.whiteBoard.time2String(time);
      } else {
        const time = this.$refs.mainVideo.getCurrentTime();
        this.memoryTime = time;
        this.nodeTitle = this.$refs.mainVideo.time2String(time);
      }
      this.videNodeShow = true;
    },
    signCut() {
      this.cropperModel = true;
      this.$nextTick(() => {
        this.photograph();
      });
    },
    photograph() {
      // let img =document.getElementById('whiteBoards')
      let img = '';
      if (this.isThreeScreenCourse) {
        img = this.$refs.whiteBoard.getInternalVideoRef();
      } else {
        img = this.$refs.mainVideo.getInternalVideoRef();
      }
      let width = img.clientWidth;
      let height = img.clientHeight;
      this.canvasWidth = width;
      this.canvasHeight = height;
      console.log('[<-- this.$refs["canvas"] -->]', this.$refs['canvas']);
      this.$refs['canvas'].style.display = 'none';
      let ctx = this.$refs['canvas'].getContext('2d');
      console.log('[<-- ctx -->]', ctx);
      this.$nextTick(() => {
        ctx.drawImage(img, 0, 0, width, height);
        let newCanvas = this.$refs['canvas'];
        newCanvas.toBlob(async (blob) => {
          const file = this.blobToFile(blob, 'canvasImage.jpg');
          this.$refs.myQuillEditor.updatefiles(file);
        });
      });
    },
    blobToFile(blob, fileName) {
      return new File([blob], fileName, { type: 'image/jpeg' });
    },
    getCurrentTimeFn() {
      const time = new Date();
      const year = time.getFullYear();
      const month = time.getMonth() + 1;
      const date = time.getDate();
      const hours = time.getHours();
      const minutes = time.getMinutes();
      const seconds = time.getSeconds();
      this.noteTime =
        year +
        '-' +
        month +
        '-' +
        date +
        ' ' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds;
    },
    changeTFQuillEditor(val) {
      this.noteContent = val;
      console.log('[<-- val -->]', val);
      // 输入笔记后 等一秒自动保存
      clearTimeout(this.updateSetTime);
      if (!this.noUpdateNote) {
        this.updateSetTime = setTimeout(() => {
          this.saveNote(1);
          this.getCurrentTimeFn();
        }, 1000);
      }
    },
    async doNote(item) {
      const data = {
        chapter_id: this.noteChapterId,
        video_id: this.noteVideoId,
      };
      if (item == 1) {
        data.chapter_id = this.$route.query.chapter_id;
        data.video_id = this.$route.query.video_id;
      }
      const res = await this.$api.post('/stu/course/getNoteDetail', data);
      if (res.data.code == 200) {
        if (res.data.data) {
          this.noteContent = res.data.data.content;
          this.noteNode = res.data.data.node_mark;
          this.makeNote = true;
        } else {
          this.noteContent = '';
          this.noteNode = [];
          this.makeNote = true;
        }
      }
      // this.makeNote=true
    },
    getRun() {
      this.$api
        .post('/stu/courseFunctionList', {
          course_id: this.$route.query.setid,
          org_id: this.org_id,
        })
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            data.forEach((item) => {
              if (item.module == 'copyright_statement') {
                if (item.params) {
                  let josnP = JSON.parse(item.params);
                  this.copyrightObj = {
                    open: item.enabled == 'yes' ? true : false,
                    inputValue: josnP.inputValue,
                    copyrightTrans: josnP.copyrightTrans / 100,
                  };
                }
              } else if (item.module == 'running_horse') {
                if (item.params) {
                  let josnP = JSON.parse(item.params);
                  this.runObj = {
                    open: item.enabled == 'yes' ? true : false,
                    fontSize: josnP.fontSize,
                    fontColor: josnP.fontColor,
                    fontTrans: josnP.fontTrans / 100,
                    remark: josnP.remark,
                  };
                }
              }
            });
          }
        });
    },
    cutSwitch() {
      this.isSwitchPosition = !this.isSwitchPosition;
      let CameraVideoTopDiv = document.getElementById('CameraVideoTopDiv');
      let CameraVideoDiv = document.getElementById('CameraVideoDiv');
      let whiteBoardTopDiv = document.getElementById('whiteBoardTopDiv');
      let whiteBoardDiv = document.getElementById('whiteBoardDiv');
      if (this.isSwitchPosition) {
        CameraVideoTopDiv.after(whiteBoardDiv);
        whiteBoardTopDiv.after(CameraVideoDiv);
      } else {
        CameraVideoTopDiv.after(CameraVideoDiv);
        whiteBoardTopDiv.after(whiteBoardDiv);
      }
    },
    handleToggleMute(isMuted) {
      // 根据子组件传递过来的 isMuted 值来关闭静音
      this.isMuted = isMuted;
    },
    showMore(value) {
      this.id = value;
    },
    calibrationVideo() {
      clearInterval(this.calibrationTimer);
      this.calibrationTimer = setInterval(() => {
        if (this.$refs.cameraVideo && this.$refs.whiteBoard) {
          const time = this.$refs.cameraVideo.getCurrentTime();
          const time2 = this.$refs.whiteBoard.getCurrentTime();
          if (time - time2 >= 1 && time <= this.whiteDuration) {
            this.$refs.whiteBoard.setCurrentTime(time, true);
          }
          if (time2 - time >= 1 && time <= this.whiteDuration) {
            this.$refs.whiteBoard.setCurrentTime(time, true);
          }
        }
      }, 5000);
    },
    dayusantishi() {
      Toast.fail('倍数不能大于3或小于0.1');
    },
    playHistoryVideo() {
      //本地历史时间记录
      let shipingID = localStorage.getItem('shipingID');
      let localViewTime = localStorage.getItem('currentTime' + shipingID);
      const {
        sectionId,
        video_id,
        view_time,
        name,
        chapter_id,
        class_id,
        setid: course_id,
        file_id,
      } = this.$route.query;
      const section = {
        sectionId,
      };
      const chapter = {
        class_id,
        course_id,
      };
      const video = {
        id: video_id,
        name,
        view_time: view_time ? view_time : localViewTime,
        chapter_id,
        file_id,
      };
      if (video_id) {
        console.log('eth');
        this.liveAutha(section, chapter, video);
      }
    },
    // 定位播放时间
    handleListenCurTimeChange(val) {
      if (this.curChooseVideo.duration - val <= 10) {
        this.kuaijieshu = true;
        if (this.curChooseVideo.duration - val > 1) {
          this.jueshusp = this.curChooseVideo.duration - val;
          // 最后3秒内，不保存笔记
          this.noUpdateNote = this.curChooseVideo.duration - val < 3;
        }
      } else {
        this.kuaijieshu = false;
      }
      let shipingID = localStorage.getItem('shipingID');
      const currentTime = val;
      this.$refs.videoDraft && this.$refs.videoDraft.setCurrentTime(val);
      localStorage.setItem('currentTime' + shipingID, currentTime);
    },
    handleListenCurTimeChangeCameraVideo(val) {
      this.$refs.videoDraft && this.$refs.videoDraft.setCurrentTime(val);
      this.progressTime = val;
      if (this.progressTime >= this.$refs.whiteBoard.getCurrentTime()) {
        // 头像视频时长大于白板时长
        this.progressTime = this.cameraDuration;
      }
    },
    // iphone11之前的型号判断
    isIphoneBefore11() {
      if (typeof window !== 'undefined' && window.navigator) {
        return (
          (/iphone/gi.test(window.navigator.userAgent) &&
            window.screen.height <= 812) ||
          (window.screen.height === 896 && window.screen.width === 414)
        );
      }
      return false;
    },
    singlePlayVideo(options, refName) {
      this.showPlayIcon = true;
      // 去掉默认封面
      this.playStatus = 1;
      this.$nextTick(() => {
        console.log('options', options);
        this.$refs[refName].setPlayerParams(options);
      });
    },
    playOneVideo(options, fileIDOption, original) {
      const mainVideo = 'mainVideo';
      if (this.playerType === 'cPlayer') {
        // h265只播url
        this.singlePlayVideo(options, mainVideo);
      } else {
        // h264播放 original===1播Url, 不然播fileId
        if (original === 1) {
          this.singlePlayVideo(options, mainVideo);
        } else {
          this.singlePlayVideo(fileIDOption, mainVideo);
        }
      }
    },
    playThreeVideo(
      options,
      sOptions,
      fileIDOption,
      sFileIDOption,
      original,
      sOriginal
    ) {
      // 三分屏 判断H265
      const cameraVideo = 'cameraVideo';
      // if (this.playerType === 'cPlayer') {
      //   this.singlePlayVideo({...options,...this.cameraVideoInitOptions}, cameraVideo)
      // } else {
      // h264播放
      if (original === 1) {
        // 播Url
        this.singlePlayVideo(
          { ...options, ...this.cameraVideoInitOptions },
          cameraVideo
        );
      } else {
        // 播fileId
        this.singlePlayVideo(
          { ...fileIDOption, ...this.cameraVideoInitOptions },
          cameraVideo
        );
      }
      // }
      // 白板
      const whiteBoard = 'whiteBoard';
      if (this.playerThreeType === 'cPlayer') {
        this.singlePlayVideo(
          { ...sOptions, ...this.whiteBoardInitOptions },
          whiteBoard
        );
      } else {
        // h264播放
        if (sOriginal === 1) {
          this.singlePlayVideo(
            { ...sOptions, ...this.whiteBoardInitOptions },
            whiteBoard
          );
        } else {
          this.singlePlayVideo(
            { ...sFileIDOption, ...this.whiteBoardInitOptions },
            whiteBoard
          );
        }
      }
    },
    // 处理视频播放
    handlePlayVideo(item, new_video, new_video_wb) {
      const {
        mp4Url,
        sMp4Url,
        file_id,
        appid,
        sign,
        s_appid,
        s_file_id,
        s_sign,
        original,
        s_original,
      } = item;
      // h265逻辑
      // let h265Obj = []
      // if (new_video && new_video.length) {
      //   h265Obj = new_video.filter(item => Number(item.vcodec) === 2);
      // }
      let sh265Obj = [];
      if (new_video_wb && new_video_wb.length) {
        sh265Obj = new_video_wb.filter((item) => Number(item.vcodec) === 2);
      }
      // 找出数组中size最小的值
      // const minSize = Math.min(...h265Obj.map(item => item?.size));
      // const m265Obj = h265Obj.find(ele => Number(ele?.size) === minSize)
      const sminSize = Math.min(...sh265Obj.map((item) => item?.size));
      const sm265Obj = sh265Obj.find((ele) => Number(ele?.size) === sminSize);
      // 对视频地址做区分
      if (
        !this.isIOS &&
        this.canPlayH265 &&
        window.Worker &&
        window.WebAssembly &&
        window.screen.height > 812
      ) {
        // this.playerType = h265Obj.length? 'cPlayer' : 'tcPlayer'
        this.playerThreeType = sh265Obj.length ? 'cPlayer' : 'tcPlayer';
      }
      // let videoUrl = this.playerType === 'tcPlayer'? mp4Url : m265Obj?.url // 主视频
      let threeVideoUrl =
        this.playerThreeType === 'tcPlayer' ? sMp4Url : sm265Obj?.url; // 白板视频
      console.log(threeVideoUrl, '265白板');
      this.fileId = file_id;
      this.playStatus = 2;
      // 播放器创建流程
      const options = {
        sources: [mp4Url],
        playType: 'url',
        startTime: this.memoryPoints,
        autoplay: true,
      };

      const sOptions = {
        sources: [threeVideoUrl],
        playType: 'url',
        muted: true,
        startTime: this.memoryPoints,
        autoplay: false,
      };

      const fileIDOption = {
        fileID: file_id,
        appID: appid,
        psign: sign,
        playType: 'file_id',
        startTime: this.memoryPoints,
        autoplay: true,
      };

      const sFileIDOption = {
        fileID: s_file_id,
        appID: s_appid,
        psign: s_sign,
        playType: 'file_id',
        muted: true,
        startTime: this.memoryPoints,
        autoplay: false,
      };

      if ((mp4Url && threeVideoUrl) || (file_id && s_file_id)) {
        // 三分屏
        this.isThreeScreenCourse = true;
        this.playThreeVideo(
          options,
          sOptions,
          fileIDOption,
          sFileIDOption,
          original,
          s_original
        );
      } else {
        // 单个视频
        this.isThreeScreenCourse = false;
        this.playOneVideo(options, fileIDOption, original);
      }
    },
    handleScroll() {
      //获取元素高度  this.$refs.init.offsetHeight
      this.scrollTop = document.querySelector('.liveBroadcast');
      // this.scrollTop.scrollTop=1300
      console.log(this.scrollTop.scrollTop);
    },
    historycallback() {
      if (!this.playStatus) return;
      let curTime = '';
      if (this.isThreeScreenCourse) {
        curTime = this.progressTime;
        // console.log(this.$refs.cameraVideo,'cameraVideo');
        this.curChooseVideo.duration = Number(
          this.$refs.cameraVideo.videoDuration
        ).toFixed(0);
      } else {
        // 头像时间大于白板时间
        curTime = this.$refs.mainVideo.getCurrentTime();
        // this.curChooseVideo.duration
        this.curChooseVideo.duration = Number(
          this.$refs.mainVideo.videoDuration
        ).toFixed(0);
        // console.log(this.$refs.mainVideo,'mainVideo');
      }
      this.curChooseVideo.duration =
        this.curChooseVideo.duration != 0
          ? this.curChooseVideo.duration
          : this.videotimet;
      this.curChooseVideo.view_time = curTime;
      if (Object.entries(this.curChooseVideo).length > 7) {
        this.$api.post('/user/video/callback', this.curChooseVideo);
      }
    },
    historycallbacks() {
      console.log('走这里了');
      if (!this.playStatus) return;
      let curTime = '';
      if (this.isThreeScreenCourse) {
        curTime = this.$refs.cameraVideo.getCurrentTime();
        this.curChooseVideo.duration = Number(
          this.$refs.cameraVideo.videoDuration
        ).toFixed(0);
      } else {
        curTime = this.$refs.mainVideo.getCurrentTime();
        this.curChooseVideo.duration = Number(
          this.$refs.mainVideo.videoDuration
        ).toFixed(0);
      }
      this.curChooseVideo.view_time = curTime;
      var data = new FormData();
      data.push(this.curChooseVideo);
      Navigator.sendBeacon(
        'https://online.tenghuiwangxiao.cn/user/video/callback',
        data
      );
    },
    async sorta(ind) {
      // console.log(ind);
      let char_sort = '';
      if (!this.sortform[ind]) {
        this.sortform[ind] = 0;
      }
      this.sortform[ind]++;
      if (this.sortform[ind] == 1) {
        this.sortimgs[ind] = require('@/assets/images/sorts.png');
        char_sort = 'asc';
      } else if (this.sortform[ind] == 2) {
        this.sortimgs[ind] = require('@/assets/images/sortx.png');
        this.sortform[ind] = 0;
        char_sort = 'desc';
      }
      console.log(this.sortform, 'ind');
      await this.getClassCode();
      this.listSections(char_sort);
    },
    async getPlayerH5URL() {
      try {
        let data = await this.$api.get(
          'https://shkt-online-live-1317978474.cos.accelerate.myqcloud.com/web/config/miniConfig.json?a=' +
            new Date().valueOf()
        );
        if (data && data.data && data.data.wx_h5_url) {
          this.wx_h5_url = data.data.wx_h5_url;
        }
      } catch (error) {
        console.error(error);
      }

      try {
        let data2 = await this.$api.get(
          'https://tengfaceliveuser.tengface.net/web/config/userConfig/' +
            this.org_id +
            '.json?a=' +
            new Date().valueOf()
        );
        if (data2 && data2.data && data2.data.wx_h5_url) {
          this.wx_h5_url = data2.data.wx_h5_url;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async onPlayerEnded() {
      if (this.sectionVideoList.length > 0) {
        let sectionListVideo = this.sectionVideoList.find((item) => {
          return item.sectionId == this.currentSectionId;
        });
        if (sectionListVideo) {
          let vidoes = sectionListVideo.video;

          let index = vidoes.findIndex((item1) => {
            return item1.id == this.currentVideoId;
          });
          if (!this.$route.query.sectionId) {
            console.log('opop');
            if (index > -1 && index < vidoes.length - 1) {
              let voideItem = vidoes[index + 1];
              await this.saveNote();
              this.liveAutha(sectionListVideo, { show: 2 }, voideItem);
            }
          } else {
            console.log('opopop');
            if (index > -1 && index < vidoes.length - 1) {
              let voideItem = vidoes[index];
              await this.saveNote();
              this.liveAutha(sectionListVideo, { show: 2 }, voideItem);
            }
          }
        }
      }
    },
    onPlayerPlay() {
      this.playStatus = 2;
      // 不是三分屏
      if (this.isThreeScreenCourse) {
        this.$refs.whiteBoard.play();
        this.$refs.cameraVideo.play();
        return;
      }
    },

    onPlayerPause() {
      this.playStatus = 3;
      if (this.$refs.cameraVideo && this.$refs.whiteBoard) {
        if (this.progressTime < this.whiteDuration) {
          // 头像时长大于白板时长 白板暂停不触发头像暂停
          this.$refs.cameraVideo.pause();
        }
        // this.$refs.whiteBoard.pause();
      }
      this.$refs.videoDraft && this.$refs.videoDraft.clearTimer();
    },
    onLoadedmetadata() {
      // 存在参数，跳转播放记忆点
      this.playStatus = 2;
      if (this.isThreeScreenCourse) {
        console.log(
          this.memoryPoints,
          this.isThreeScreenCourse,
          this.$refs.whiteBoard,
          '记录三分屏播放时间'
        );
        this.$refs.cameraVideo.setCurrentTime(this.memoryPoints, true);
        this.$refs.whiteBoard.setCurrentTime(this.memoryPoints, true);
      } else {
        console.log(
          this.memoryPoints,
          this.isThreeScreenCourse,
          this.$refs.mainVideo,
          '记录单个播放时间'
        );
        this.$refs.mainVideo.setCurrentTime(this.memoryPoints, true);
      }
    },
    onLoadeddata() {
      this.playStatus = 2;
    },
    guanbiclassget() {
      this.showClass = false;
    },
    goOff() {
      if (this.fromRoutePath == '/') {
        //回到主界面
        this.$router.replace({ path: '/' });
      } else {
        this.$router.go(-1);
      }
      console.log('fromRoutePath', this.fromRoutePath);
    },
    openAll() {
      this.showAll = false;
      this.optionsa = this.optionsaAll;
    },
    classChange(item) {
      // console.log(item, "item");
      // const selectedItem = this.options.find(option => option.id === item.id);
      // console.log(selectedItem,'lllllllllllll');
      // if (selectedItem && selectedItem.is_access !== true) {
      //   // this.$message.error('您无权选择该项！');
      //   // console.error('您无权选择该项！')
      //   this.$refs.access.show('您暂无该班级权限，请联系老师开通');
      //   // 不做任何操作，保持当前选项不变
      // } else {
      //   // 处理用户选择其他可选项的逻辑
      //   // 首先将所有选项的 select 属性设置为 false
      //   this.options.forEach(option => {
      //     option.select = false;
      //   });
      //   // 找到用户选择的选项并将其 select 属性设置为 true
      //   selectedItem.select = true;

      //   // 更新相关数据
      //   this.class_id = selectedItem.id;
      //   this.currClassName = selectedItem.period;
      //   this.showClass = false;
      //   this.historycallback();
      //   this.getClassCode();
      //   this.listSections();
      // }
      this.tabIndex = 1;
      this.videoSubtitlesContents = [];
      this.isSelectVideo = false;
      this.options.forEach((item) => {
        item.select = false;
      });
      item.select = true;
      this.class_id = item.id;
      this.currClassName = item.period;
      this.showClass = false;
      this.historycallback();
      this.getClassCode();
      this.listSections();
    },
    async getUserInfo() {
      const res = await this.$api.post('/user/index/getUserInfo');
      if (res.data.code === 200) {
        this.$store.commit('user/setFansId', res.data.data.id);
        this.$store.commit('user/setOrgId', res.data.data.org_id);
        this.nick = res.data.data.nick_name;
      }
    },
    getCourseInfo() {
      // 课程详情
      const data = {
        org_id: this.org_id,
        course_id: this.$route.query.setid,
      };
      this.$api.post('/stu/course/getCourseInfo', data).then((res) => {
        if (res.data.code === 200) {
          this.name = res.data.data.name;
          this.desc = res.data.data.desc;
          this.cover = res.data.data.cover;
          this.subtitlesStatus =
            res.data.data.is_enable_caption == 1 ? true : false;
        } else {
          Toast.fail(res.data.message);
        }
      });
    },
    async listClass() {
      // 课程班级列表
      const data = {
        org_id: this.org_id,
        course_id: this.$route.query.setid,
      };
      const res = await this.$api.post('/stu/course/listClass', data);
      if (res.data.code === 200) {
        this.options = res.data.data;
        let hasAccessArr = res.data.data.filter((item) => item.access !== 0);
        if (res.data.data && res.data.data.length > 0) {
          if (hasAccessArr.length === 0) {
            let currClassObj = res.data.data[res.data.data.length - 1];
            this.class_id = currClassObj.id;
            this.currClassName = currClassObj.period;
            currClassObj.select = true;
          } else {
            this.class_id = hasAccessArr[0].id;
            this.currClassName = hasAccessArr[0].period;
          }
          if (this.$route.query.class_id) {
            this.class_id = parseInt(this.$route.query.class_id);
          }
          this.options.forEach((item) => {
            if (item.id == this.class_id) {
              item.select = true;
              this.currClassName = item.period;
            } else {
              item.select = false;
            }
          });
          await this.getClassCode();
          this.listSections();
        }
      }
    },
    listSections(char_sort = '', index) {
      // 章节列表
      const data = {
        org_id: this.org_id,
        course_id: this.$route.query.setid,
        class_id: this.class_id,
        page: this.page,
        num: this.num,
        char_sort,
      };
      if (index == 1 && this.value2 != '') {
        data.key = this.value2;
      }
      const zhangJie =
        this.value2 != ''
          ? '/stu/course/sectionSearch'
          : '/stu/course/listSections';
      this.$api.post(zhangJie, data).then((res) => {
        if (res.data.code === 200) {
          this.optionsaAll = res.data.data;
          if (this.isFirst) {
            // console.log(7777);
            let chapterindex = 0;
            let chapterJie = null;
            try {
              this.optionsaAll.forEach((item, index) => {
                item.chapters.forEach((item2) => {
                  if (this.codeArr.indexOf(item2.id) != -1) {
                    item2.show = 1;
                  } else {
                    item2.show = 2;
                  }
                  if (item2.status == 2) {
                    chapterindex = index;
                    chapterJie = item2.id;
                    this.showAll = false;
                    this.optionsa = res.data.data;
                    throw new Error(chapterindex);
                  }
                });
              });
            } catch (e) {
              // if (e.message != "End") throw e;
              this.$nextTick(() => {
                if (this.$refs['collapseId' + chapterindex]) {
                  this.$refs['collapseId' + chapterindex][0].toggle(true);
                }
                // this.$refs.collapseId1[0].toggle(true);
                // this.isFirst = false;
                // console.log(!!chapterJie,'!!chapterJie');
                if (this.isFirst && !!chapterJie) {
                  this.isFirst = false;
                  // console.log(this.isFirst,'this.isFirst3');
                  setTimeout(() => {
                    const parent = this.$refs.course_chapter0;
                    // console.log(54321,'bbb');
                    // console.log(chapterJie,'chapterJie');
                    // console.log(this.$refs["chapterJie" + chapterJie],'this.$refs["chapterJie" + chapterJie]11');
                    // console.log(this.$refs["chapterJie" + chapterJie][0],'this.$refs["chapterJie" + chapterJie]22');
                    // console.log(this.$refs["chapterJie" + chapterJie][0].offsetTop,'this.$refs["chapterJie" + chapterJie]33')
                    if (this.$refs['chapterJie' + chapterJie]) {
                      const position =
                        this.$refs['chapterJie' + chapterJie][0].offsetTop;
                      let lastTop = chapterindex * 44 + position;
                      // console.log(lastTop,'aaaaaaaaaaaaaaappp');
                      parent.scrollTo({
                        top: lastTop,
                        behavior: 'smooth',
                      });
                    }
                    // console.log(parent,'parent');
                    // console.log(position,'position');
                  }, 1000);
                }
              });
            }

            if (chapterJie === null) {
              console.log(2333333);
              if (this.optionsaAll.length > 5) {
                this.showAll = this.$route.query.sectionId ? false : true;
                this.optionsa = this.$route.query.sectionId
                  ? this.optionsaAll
                  : this.optionsaAll.slice(0, 5);
              } else {
                this.showAll = false;
                this.optionsa = res.data.data;
              }
            }
          } else {
            if (this.optionsaAll.length > 5) {
              this.showAll = this.$route.query.sectionId ? false : true;
              this.optionsa = this.$route.query.sectionId
                ? this.optionsaAll
                : this.optionsaAll.slice(0, 5);
            } else {
              this.showAll = false;
              this.optionsa = res.data.data;
            }
          }

          this.classInfo();
          this.tancalsslist = res.data.data;
          const list = [];
          this.tancalsslist.forEach((item, index) => {
            let vodes = [];
            item.chapters.forEach((item1) => {
              if (this.codeArr.indexOf(item1.id) != -1) {
                item1.show = 1;
              } else {
                item1.show = 2;
              }
              if (item1.videos.length !== 0) {
                vodes = vodes.concat(item1.videos);
              }
            });
            if (this.tancalsslist[index].chapters.length !== 0) {
              list.push({
                sectionId: item.sectionId,
                video: vodes,
                class_id: this.tancalsslist[index].chapters[0].class_id,
                course_id: this.tancalsslist[index].chapters[0].course_id,
              });
            }
            //  console.log(item.chapters)
          });
          if (index == 1 && this.value2 != '') {
            this.optionsa.forEach((item) => {
              this.activeNames.push(item.sectionId);
            });
            const uniqueArray = [...new Set(this.activeNames)];
            this.activeNames = uniqueArray;
          } else {
            this.activeNames = [];
          }
          this.sectionVideoList = list;
          console.log('eeeeeeeeeeeee', list);
          // 新增逻辑定位上次看到,正在直播
          this.setScrollPositionFn();
          // 动态设置视频下方高度样式问题
          this.$nextTick(() => {
            const height = document.querySelector('.headerBox').clientHeight;
            document.querySelector(
              '.contentBox'
            ).style.height = `calc(100vh - ${height}px)`;
          });
        }
      });
    },
    setScrollPositionFn() {
      // 初次进来, 没有搜索值
      if (this.isFirstEnter && !this.value2 && !this.$route.query.name) {
        // 找到上次看到或者正在直播
        this.activeNames = [];
        let chapterindex = '';
        let chapterJie = '';
        let videoJie = '';
        this.optionsa.forEach((item, index) => {
          item.chapters.forEach((item2) => {
            if (item2.status == 2) {
              // 正在直播
              chapterindex = index;
              chapterJie = item2.id;
            }
          });
        });
        if (!chapterJie) {
          this.optionsa.forEach((item, index) => {
            item.chapters.forEach((item2) => {
              item2.videos.forEach((videoList) => {
                if (videoList.is) {
                  chapterindex = index;
                  chapterJie = item2.id;
                  videoJie = videoList.id;
                }
              });
            });
          });
        }
        // 展开当前直播中与上次看到
        if (chapterJie) {
          this.activeNames.push(this.optionsa[chapterindex].sectionId);
        } else {
          // 全部没看过, 展开第一个
          this.activeNames.push(this.optionsa[0].sectionId);
        }
        // 获取当前的dom
        this.$nextTick(() => {
          const parent = this.$refs.course_chapter0;
          let position = this.$refs['chapterJie' + chapterJie][0].offsetTop;
          if (videoJie) {
            position = this.$refs['videoJie' + videoJie][0].offsetTop;
          }
          let lastTop = chapterindex * 44 + position;
          if (lastTop < 80) return;
          console.log(parent, 'parent');
          console.log(lastTop, 'position');
          setTimeout(() => {
            parent.scrollTo({
              top: lastTop,
              behavior: 'smooth',
            });
          }, 1000);
        });
        this.isFirstEnter = false;
      }
    },
    setup() {
      console.log(555);
      const activeNames = ref(['0']);
      return { activeNames };
    },
    liveAuth(item1, show) {
      // 直播鉴权
      console.log('111', item1);
      const data = {
        course_id: this.$route.query.setid,
        class_id: this.class_id,
        chapter_id: item1.id,
      };
      this.$api.post('/stu/course/liveAuth', data).then((res) => {
        if (res.data.code === 200) {
          if (item1.type == 4) {
            this.fakeLiveJump(item1, res.data);
          } else {
            this.liveAddress(item1, res.data);
          }
        } else {
          if (show == 1) {
            this.chapter_id = item1.id;
            this.errData = '';
            this.code = true;
          } else {
            Toast.fail(res.data.message);
          }
        }
      });
    },

    transformation(duration) {
      let g = duration;
      let m = parseInt((duration / 60) % 60);
      let s = duration % 60;
      if (s <= 9) {
        s = '0' + s;
      }
      if (m <= 9) {
        m = '0' + m;
      }
      if (g >= 3600) {
        g = g / 3600;
        g = parseInt(g);
        g = '0' + g + ':';
      } else {
        g = '';
      }
      return g + m + ':' + s;
    },
    liveAutha(section, chapter, video) {
      (this.noteCourseId = chapter.course_id
        ? chapter.course_id
        : this.$route.query.setid),
        (this.noteClassId = chapter.class_id
          ? chapter.class_id
          : this.class_id);
      this.noteSectionId = section.sectionId;
      this.noteChapterId = video.chapter_id;
      this.noteVideoId = video.id;
      this.historycallback();
      this.videotimet = video.duration;
      console.log(video, '录播鉴权');
      this.curChooseVideo = {
        chapter_id: video.chapter_id,
        course_id: chapter.course_id,
        class_id: chapter.class_id,
        video_id: video.id,
        file_id: video.file_id,
        name: video.name,
        duration: video.duration,
        sectionId: section.sectionId,
      };
      this.currentSectionId = section.sectionId;
      this.currentVideoId = video.id;
      this.acterss = video.id;

      const data = {
        course_id: chapter.course_id
          ? chapter.course_id
          : this.$route.query.setid,
        class_id: chapter.class_id ? chapter.class_id : this.class_id,
        chapter_id: video.chapter_id,
        video_id: video.id,
      };
      this.$api.post('/stu/course/videoAuth', data).then((res) => {
        if (res.data.code === 200) {
          if (
            res.data.data.video.encrypt_status == '1' &&
            res.data.data.video.allow_web == '2'
          ) {
            this.$router.push({ path: '/downloadApp' });
          } else {
            this.videoPlay = true;
            localStorage.setItem('shipingID', video.id);
            if (video.duration - video.view_time <= 0) {
              this.memoryPoints = 0;
            } else {
              this.memoryPoints = video.view_time;
            }
            if (this.$route.query.memory) {
              this.memoryPoints = this.$route.query.memory;
            }
            this.shangciGk = true;
            let timer = setTimeout(() => {
              this.shangciGk = false;
            }, 5000);
            this.handlePlayVideo(
              res.data.data.video,
              res.data.data.new_video,
              res.data.data.new_video_wb
            );
            // 心跳上报
            clearInterval(this.calibrationTimer);
            this.getVideoSubtitlesContent(this.fileId);
            // if(this.subtitlesStatus){
            //   this.tabIndex=2
            //   this.isSelectVideo=true
            // }
            this.$nextTick(() => {
              this.startVodTime(this.fileId);
              this.remainingTime = 0;
              this.calibrationVideo();
            });
          }
          this.listSections();
        } else {
          if (chapter.show == 1) {
            this.chapter_id = chapter.id;
            this.errData = '';
            this.code = true;
          } else {
            console.log(this.$refs.access);
            this.$refs.access?.show(res.data.message);
            // Toast.fail(res.data.message);
          }
        }
      });
    },
    startVodTime() {
      clearInterval(this.heartBeat);
      this.heartBeat = setInterval(() => {
        let token = localStorage.getItem('token');
        if (!token) {
          this.heartBeat && clearInterval(this.heartBeat);
        }
        if (this.playStatus && token) {
          let progress = 0;
          if (this.isThreeScreenCourse) {
            progress = parseInt(this.$refs.cameraVideo.getCurrentTime() || 0);
          } else {
            progress = parseInt(this.$refs.mainVideo.getCurrentTime() || 0);
          }
          // 如果是倍速播放，需要保证每5秒的视频时长都需要有心跳记录,假如当前的progress=100，视频是2倍数，则在记录100秒的同时，还需要记录95秒的视频心跳，后台的观看进度才不会断开
          // 小于1倍数的时候，只需要请求一次视频心跳
          let num = this.videoSpeed < 1 ? 1 : Math.ceil(this.videoSpeed);
          while (num > 0) {
            num--;
            this.$dataApi
              .post('/dataapi/app/index/videoHeart', {
                file_id: this.fileId,
                fans_id: this.fans_id,
                uid: this.org_id,
                progress: Math.floor(progress - num * 5),
              })
              .then(() => {})
              .catch(() => {});
          }
          // const data = {
          //   file_id: this.fileId,
          //   fans_id: this.fans_id,
          //   uid: this.org_id,
          //   progress,
          // };
          // this.$dataApi
          //   .post('/dataapi/app/index/videoHeart', data)
          //   .then((res) => {
          //     console.log(res);
          //   });
        }
      }, 5000);
    },
    fakeLiveJump(item1, item) {
      const liveUrl = `${this.wx_h5_url}/f/?ds_id=${item1.ds_id}&tid=${
        item1.room_id
      }&token=${item.data.liveToken}&other=1&shool_token=${localStorage.getItem(
        'token'
      )}`;

      console.log('fakeLiveJump', liveUrl);

      location.href = liveUrl;
    },
    liveAddress(item1, item) {
      console.log('item1', item1);
      // 查询直播地址
      if (item1.play_style == 4) {
        location.href = `${this.wx_h5_url}/tcicMiddlePage/#/h5?ds_id=${item1.ds_id}&classid=${item1.interact_id}&fans_id=${this.fans_id}`;
      } else {
        const liveUrl = `${this.wx_h5_url}/?ds_id=${item1.ds_id}&token=${
          item.data.liveToken
        }&other=1&shool_token=${localStorage.getItem('token')}`;
        console.log('liveAddress', liveUrl);
        location.href = liveUrl;
      }

      // const data = {
      //   chapter_id: id,
      // };
      // this.$api.post("/stu/course/liveAddress", data).then((res) => {
      //   if (res.data.code === 200) {
      //     const url = `${res.data.data.live_url}&token=${item.data.liveToken}&shool_token=${localStorage.getItem('token')}&other=1`;
      //     location.href = url;
      //   }
      // });
    },
    classInfo() {
      // 查询班级信息
      const data = {
        org_id: this.org_id,
        classId: this.class_id,
      };
      this.$api.post('/stu/course/classInfo', data).then((res) => {
        if (res.data.code === 200) {
          if (res.data.data.chapter) {
            this.start_timeList = res.data.data.chapter.start_time;
          }
          this.numStatus = res.data.data.numStatus;
          this.studyNum = res.data.data.studyNum;
          this.comment = res.data.data.comment;
        }
      });
    },

    handlePause() {
      this.playStatus = 3;
      if (this.$refs.cameraVideo && this.$refs.whiteBoard) {
        this.$refs.whiteBoard.pause();
      }
      this.$refs.videoDraft && this.$refs.videoDraft.clearTimer();
    },
    playVideo() {
      if (this.isThreeScreenCourse) {
        this.$refs.cameraVideo.play();
        this.$refs.whiteBoard.play();
      } else {
        this.$refs.mainVideo.play();
      }
      this.showPlayIcon = false;
      this.playStatus = 2;
    },
    handleSeek(val, isAuto) {
      // 同步头像视频
      if (val > this.whiteDuration) {
        // 拖动时间大于白板时间取白板时间
        this.$refs.whiteBoard.setCurrentTime(this.whiteDuration, true);
      } else {
        this.$refs.whiteBoard.setCurrentTime(val, true);
      }
      // const whiteBoardTime = val;
      // const cameraTime = this.$refs.cameraVideo.getCurrentTime();
      // const diff = Math.abs(whiteBoardTime - cameraTime - this.remainingTime);
      // if (diff < 5) return;
      // if (this.$refs.cameraVideo && this.$refs.whiteBoard) {
      //   if (this.remainingTime === 0) {
      //     this.compareVideoTime();
      //   }
      //   if(!isAuto) {
      //     // this.$refs.cameraVideo.setCurrentTime(this.remainingTime + val)
      //     this.$refs.cameraVideo.setCurrentTime(val)
      //   }
      // }
    },
    compareVideoTime() {
      const cameraTime = this.$refs.cameraVideo.getDuration;
      const courseTime = this.$refs.whiteBoard.getDuration;
      if (cameraTime && courseTime) {
        this.remainingTime = courseTime - cameraTime || 0;
      }
    },
    // 获取当前课程有兑换码章节
    async getClassCode() {
      const data = {
        org_id: this.org_id,
        class_id: this.class_id,
        course_id: this.$route.query.setid,
      };
      const res = await this.$api.post('/stu/course/getClassCode', data);
      if (res.data.code === 200) {
        const cdarr = res.data.data;
        this.codeArr = Object.values(cdarr);
      }
    },

    // 兑换码兑换课程
    useCdk() {
      const data = {
        org_id: this.org_id,
        code: this.codeData,
        class_id: this.class_id,
        course_id: this.$route.query.setid,
        chapter_id: this.chapter_id,
      };
      this.$api.post('/stu/course/useCdk', data).then((res) => {
        console.log(res.data.code);
        if (res.data.code === 200) {
          this.videoPlay = true;
          this.code = false;
          this.codeData = '';
          Toast('兑换成功');
        } else if (res.data.code === 400) {
          this.errData = res.data.message;
        }
      });
    },
    recordTimeBeforePageDestroy() {
      // 只有正在直播的时候才记录
      if (this.playStatus !== 2) {
        return;
      }
      let progress = 0;
      if (this.isThreeScreenCourse) {
        progress = parseInt(this.$refs.cameraVideo.getCurrentTime() || 0);
      } else {
        progress = parseInt(this.$refs.mainVideo.getCurrentTime() || 0);
      }
      this.$dataApi.post('/dataapi/app/index/videoHeart', {
        file_id: this.fileId,
        fans_id: this.fans_id,
        uid: this.org_id,
        progress,
      });
    },
  },
};
</script>
<style scoped>
.sousuozj .van-search {
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>
.shangcigkyi {
  width: 114px;
  height: 28px;
  background: rgba(21, 21, 21, 0.8);
  border-radius: 4px;
  position: absolute;
  margin-top: -90px;
  margin-left: 262px;
  font-size: 12px;
  font-family: Noto Sans SC, Noto Sans SC-400;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 28px;
}
.shangcigk {
  width: 110px;
  height: 32px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  position: absolute;
  margin-top: -90px;
  margin-left: 10px;
  font-size: 12px;
  font-family: Noto Sans SC, Noto Sans SC-400;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 32px;
}
.yuyuyu >>> .el-dialog {
  border-radius: 20px;
}
.kechengduihuan {
  font-size: 18px;
  font-family: PingFangSC-Medium;
  text-align: center;
  color: #202020;
  margin-bottom: 5px;
  margin-top: 32px;
}
.huoqulist {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #666;
  margin-top: 1px;
}
.yuyuyu >>> .el-input__inner {
  background: #f5f5f5;
  border-radius: 4px;
  width: 244px;
  height: 36px;
  border: none;
}
.lijiduan {
  width: 250px;
  height: 40px;
  background: linear-gradient(99deg, #73aaff 0%, #0053ff 100%);
  border-radius: 6px;
  margin-top: 16px;
  border: none;
  color: #fff;
}
.keduihuan {
  display: inline-block;
  text-align: center;
  width: 38px;
  height: 16px;
  background: #ffece7;
  border-radius: 2px;
  font-size: 10px;
  font-family: PingFangSC-Regular;
  color: #f33f27;
  line-height: 16px;
  margin-left: 5px;
  padding: 2px;
}

.codeshuru {
  width: 250px;
  height: 40px;
  background: #f5f5f5;
  border-radius: 4px;
  border: none;
  padding: 0 10px;
  box-sizing: border-box;
}
.yiguoqilist {
  font-size: 10px;
  font-family: PingFangSC-Regular;
  text-align: left;
  color: #ff5227;
  line-height: 20px;
  padding-left: 10px;
  margin: 0;
}
.yuyuyu >>> .el-dialog--center .el-dialog__body {
  padding: 25px 25px 0px;
}
</style>
<style lang="scss" scoped>
.away-top {
  margin-top: 6.43rem !important;
}
.sortimg {
  width: 0.5rem;
  height: 0.5rem;
}
.sort {
  font-size: 0.375rem;
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-justify-content: right;
  justify-content: right;
  align-items: center;
}
.activest {
  color: #10a675;
}
.stbx {
  width: 1.5rem;
  height: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: solid #b7b7b7 1px;
}
.stbx1 {
  color: #1872ed;
  background: #e7f1ff;
  border: solid #1872ed 1px;
}
.tfPlayer >>> .playBtn {
  width: 12% !important;
}
.noteBox {
  position: relative;
}
.contentBox >>> .van-cell--clickable {
  background: #f6f6f6;
}
.noteBtn {
  position: fixed;
  width: 44px;
  height: 44px;
  background: #ffffff;
  border-radius: 50%;
  right: 16px;
  bottom: 141px;
  box-shadow: 0px 4px 10px 0px rgba(0, 33, 79, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
}
.note-img {
  height: 24px;
  width: 24px;
}
.empty {
  height: 1.2rem;
}
.myNote {
  height: 1rem;
  padding: 0.2rem 0.38rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  .title-note {
    font-size: 0.38rem;
    font-weight: 600;
    color: #202020;
  }
  .btnBox {
    display: flex;
    .signOut {
      width: 48px;
      height: 24px;
      background: #f1f2f7;
      border-radius: 4px;
      color: #666666;
      font-size: 0.3rem;
      font-weight: 400;
      text-align: center;
      line-height: 24px;
      margin-right: 0.3rem;
    }
    .save {
      width: 48px;
      height: 24px;
      background: linear-gradient(114deg, #73aaff 25%, #0053ff 100%);
      border-radius: 4px;
      font-size: 0.3rem;
      font-weight: 400;
      color: #fff;
      text-align: center;
      line-height: 24px;
    }
  }
}
.content {
  position: relative;
  padding: 0 0.38rem;
  .videoCut {
    position: absolute;
    top: 2.33rem;
    left: 5rem;
    height: 0.5rem;
    width: 0.5rem;
  }
  .nodeSign {
    position: absolute;
    top: 2.33rem;
    left: 5.65rem;
    height: 0.5rem;
    width: 0.5rem;
  }
}
.nodeContainer {
  width: fit-content;
  height: 0.5rem;
  background: #f1f2f7;
  border-radius: 40px;
  padding: 7px 16px;
  font-size: 14px;
  font-weight: 400;
  color: #202020;
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 10px 0;
  img {
    height: 16px !important;
    width: 16px !important;
    margin-right: 6px;
  }
  .time {
    margin-right: 10px;
  }
  .videoNodeTitle {
    display: inline-block;
    white-space: nowrap;
  }
}
.modal-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  .modal-container {
    width: 8rem;
    height: 5rem;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    .close-img {
      width: 16px;
      height: 16px;
      float: right;
      margin-top: 20px;
      margin-right: 20px;
    }
    .modal-header {
      margin-top: 0.8rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 400;
      color: #202020;
      img {
        width: 20px;
        height: 20px;
      }
      .line {
        display: inline-block;
        width: 1px;
        height: 16px;
        background: #999999;
        margin: 0 10px;
      }
    }
    .nodde-input {
      width: 6.6rem;
      height: 1rem;
      background: #efefef;
      border-radius: 4px;
      margin: 0.5rem 0.42rem;
      padding: 0 10px;
    }
    .limit {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 0.86rem;
      right: 0.8rem;
    }
    .modal-footer {
      width: 3rem;
      height: 1rem;
      background: linear-gradient(100deg, #73aaff 0%, #0053ff 100%);
      border-radius: 22px;
      margin: 0 auto;
      text-align: center;
      line-height: 1rem;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
    }
    .modal-content {
      font-size: 16px;
      font-weight: 400;
      color: #202020;
      width: 100%;
      text-align: center;
    }
    .model-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.7rem;
      .nq {
        width: 2.7rem;
        height: 1rem;
        background: #efefef;
        border-radius: 0.5rem;
        margin-right: 0.5rem;
        font-size: 14px;
        font-weight: 400;
        color: #202020;
        text-align: center;
        line-height: 1rem;
      }
      .nc {
        width: 2.7rem;
        height: 1rem;
        background: linear-gradient(100deg, #73aaff 0%, #0053ff 100%);
        border-radius: 0.5rem;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        text-align: center;
        line-height: 1rem;
      }
    }
  }
}
input {
  border: 0;
}
.liveBroadcast .headerBox {
  /* position: -webkit-sticky; */
  position: fixed;
  /* position: relative; */
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.dsanbf >>> .el-input__inner {
  border: none;
  width: 185px;
}
.banji {
  display: flex;
  font-size: 0.32rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  float: right;
  margin-right: 0.43rem;
  height: 24px;
  line-height: 24px;
  border: #1872ed 1px solid;
  border-radius: 12px;
  padding: 0 8px;
  color: #1872ed;
  /*margin-top: 0.48rem;*/
}
.banji .class-name {
  display: inline-block;
  white-space: nowrap;
  max-width: 82px;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* 头部 */
.diyi {
  width: 100%;
  height: 5.33rem;
  overflow: hidden;
  background: #fff;
  position: relative;
}
.overlayer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.overlayer img {
  width: 60px;
  z-index: 9999;
}

.diyi .courseCover {
  z-index: 3000;
  width: 100%;
  height: 100%;
}
.diyi .main-video {
  width: 100%;
  height: 100%;
}
.diyi .cameraVideo {
  width: 100%;
  height: 100%;
}

.white-board {
  width: 100%;
  height: 5.33rem !important;
}
.threeScreenPlayer {
  position: relative;
}
.positionIcon {
  position: absolute;
  left: 11px;
  width: 25px;
  height: 25px;
  top: 50%;
  z-index: 99;
  transform: translateY(-50%);
}
.fanhui {
  width: 25px;
  height: 25px;
  opacity: 0.5;
  background: #000000;
  border-radius: 50%;
  text-align: center;
  top: 10px;
  left: 10px;
  position: absolute;
  z-index: 9999;
}
.fanhuiyi {
  width: 30px;
  height: 30px;
  opacity: 0.5;
  background: #000000;
  border-radius: 50%;
  text-align: center;
  margin-top: 13px;
}
.fanhuier {
  width: 30px;
  height: 30px;
  opacity: 0.5;
  background: #000000;
  border-radius: 50%;
  text-align: center;
  margin-top: 13px;
  margin-left: 18px;
}
.mianfei {
  width: 109px;
  height: 40px;
  opacity: 0.5;
  background: #000000;
  border-radius: 20px;
  clear: both;
  margin: 0 auto;
  /* margin-top: 88px; */
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
}

/* 第二部分 */
.debf {
  width: 100%;
  /* height: 77px; */
  background: #fff;
  overflow: hidden;
  margin-top: -8px;
}
.liveBroadcast {
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: auto;
  z-index: 1;
}
.liveBroadcast .fafa {
  font-size: 0.48rem;
  line-height: 0.64rem;
  font-weight: 600;
  color: #101010;
  margin-top: 0.27rem;
  margin-left: 0.43rem;
  margin-bottom: 0px;
}
.liveBroadcast .zhibosj {
  margin: 0px;
  margin-top: 0.29rem;
  margin-bottom: 0.29rem;
  margin-left: 0.43rem;
  font-size: 0.32rem;
  line-height: 0.43rem;
  font-weight: 400;
  color: #888888;
}

.liveBroadcast .mytestping {
  margin: 0px;
  margin-top: 5px;
  padding: 0 0.43rem;
  font-size: 0.32rem;
  line-height: 0.43rem;
  font-weight: 400;
  color: #888888;
}

.liveBroadcast .rightStudy {
  float: right;
  margin-right: 0.43rem;
}

.liveBroadcast .rightStudy img {
  vertical-align: -0.05rem;
  height: 16px;
  width: 16px;
}

.liveBroadcast .qishi {
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #1872ed;
  margin-left: 0.13rem;
}

.liveBroadcast .lineBox {
  width: 100%;
  height: 0.27rem;
  background-color: #f6f6f6;
}

/* 第三部分 */
.liveBroadcast .dsanbf {
  width: 100%;
  height: calc(100% - 75px);
  background: #ffffff;
  overflow: hidden;
}

.liveBroadcast .dsanbf .dsanbf_header {
  display: flex;
  align-items: center;
  height: 1rem;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
  font-size: 16px;
  color: #666666;
}
.liveBroadcast .dsanbf .dsanbf_header .dsanbf_title {
  height: 1rem;
  line-height: 1rem;
  margin-left: 8px;
  font-size: 0.35rem;
}
.liveBroadcast .dsanbf .dsanbf_header .active {
  color: #202020;
}
.liveBroadcast .dsanbf .dsanbf_header .active::after {
  content: '';
  display: flex;
  position: relative;
  top: 0;
  left: 28%;
  width: 24px;
  height: 1px;
  background: #1872ed;
  border-radius: 2px;
}

.liveBroadcast .mulu {
  font-size: 0.48rem;
  line-height: 0.59rem;
  font-weight: 600;
  color: #101010;
  margin-top: 0.43rem;
  margin-left: 0.43rem;
  margin-bottom: 0px;
}
.aglook {
  padding: 2px 5px;
  border-radius: 5px;
  color: #528dfb;
  font-size: 12px;
}
.liveBroadcast .courseItemView {
  overflow: hidden;
  margin-top: 0.53rem;
}
.liveBroadcast .liveInformation {
  width: 100%;
  display: flex;
}
.liveBroadcast .linyi {
  display: inline-block;
  font-size: 0.37rem;
  color: #20242f;
}
.liveBroadcast .videoItem {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  margin-left: 0.63rem;
  margin-top: 0.43rem;
}
.liveBroadcast .videoInformation {
  display: flex;
  align-items: center;
}
.liveBroadcast .videoItem .playBtn {
  /* width: 0.37rem; */
  width: 0.37rem;
  height: 0.37rem;
  vertical-align: -0.05rem;
}

.liveBroadcast .videoItem .time {
  min-width: 80px;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.32rem;
  color: #888888;
  align-items: center;
  justify-content: space-between;
  /* margin-right: 0.53rem;
  margin-top: 0.1rem;
  margin-bottom: 0px; */
}

.huifanga {
  display: inline-block;
  background: #d9e8fe;
  border-radius: 2px;
  padding: 2px 3px;
  font-size: 0.32rem;
  font-weight: 400;
  color: #3f8ef8;
  margin-left: 0.45rem;
  height: 0.4rem;
  width: 0.7rem;
  text-align: center;
  line-height: 0.4rem;
}
.huifangb {
  display: inline-block;
  background: #e6f6f0;
  border-radius: 2px;
  padding: 2px 3px;
  font-size: 0.32rem;
  font-weight: 400;
  color: #10a675;
  margin-left: 0.45rem;
  height: 0.4rem;
  width: 0.7rem;
  text-align: center;
  line-height: 0.4rem;
}
.chushi {
  -webkit-text-size-adjust: 100%;
  font-size: 0.37rem;
  font-weight: 500;
  color: #10a675;
  margin-left: 6px;
}
.chushia1 {
  -webkit-text-size-adjust: 100%;
  font-size: 0.37rem;
  font-weight: 500;
  color: #101010;
  margin-left: 0.21rem;
  display: inline-block;
}
.chushia {
  -webkit-text-size-adjust: 100%;
  font-size: 0.37rem;
  font-weight: 500;
  color: #101010;
  margin-left: 0.21rem;
  display: inline-block;
  max-width: 3.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.show {
  -webkit-text-size-adjust: 100%;
  max-width: 3.5rem;
  color: #101010;
  white-space: pre-wrap;
  height: auto;
  overflow: visible;
}
.chushialist {
  /* -webkit-text-size-adjust: 100%; */
  font-size: 0.37rem;
  font-weight: 500;
  color: #101010;
  margin-left: 0.43rem;
  display: inline-block;
  max-width: 3.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shijianb {
  font-size: 0.32rem;
  font-weight: 400;
  color: #10a675;
  margin-left: 49px;
}
.shijianc {
  font-size: 0.32rem;
  font-weight: 400;
  color: #10a675;
  margin-left: 10px;
}
.shijiand {
  display: inline-block;
  font-size: 12px !important;
  font-weight: 400;
  color: #888888;
  margin-left: 1.28rem;
}
.shijianf {
  font-size: 0.32rem;
  font-weight: 400;
  color: #888888;
  margin-left: 10px;
}
.gengduo {
  font-size: 0.37rem;
  font-weight: 400;
  color: #1872ed;
}
/* 第四部分 */
.dsibf {
  width: 100%;
  background: #fff;
  overflow: hidden;
}

.meiyou {
  width: 100%;
  height: 37px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #888888;
}
.dibu {
  width: 100%;
  height: 58px;
  background: #fefffe;
  box-shadow: 0px -3px 10px 0px rgba(0, 0, 0, 0.05);
}
.liji {
  width: 260px;
  height: 40px;
  background: linear-gradient(90deg, #fdb56b, #fc6d2b);
  border-radius: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 9px;
  margin-left: 31px;
}
.active {
  color: #1872ed;
}
.xuanban {
  font-size: 0.37rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #000000;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 33px;
}
.luse {
  font-size: 0.43rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  /* color: #10a675; */
  margin-left: 20px;
  margin-top: 26px;
}
.xuanzhong {
  float: right;
  margin-top: -21px;
  margin-right: 25px;
}
.xuanzhong img {
  width: 0.53rem;
  height: 0.53rem;
}
.jirzb {
  float: right;
  background: linear-gradient(133deg, #73aaff 3%, #0053ff 91%);
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #fefffe;
  padding: 5px 11px;
  border-radius: 14px;
  margin-top: -20px;
}
.jirzblist {
  float: right;
  font-size: 0.32rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #888888;
  margin-top: -0.6rem;
}
.guanbiclass {
  position: absolute;
  width: 0.37rem;
  height: 0.37rem;
  top: 0.61rem;
  right: 0.4rem;
}
.zhangjielist {
  // font-size: 0.43rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #20242f;
  margin-top: 0.27rem;
}
::v-deep .van-collapse-item__content {
  padding: 12px 0px;
}
.zhangjielist ::v-deep .van-cell__title span {
  display: inline-block;
  font-size: 0.43rem !important;
}
</style>

<style>
.kecheng_desc img {
  width: 100%;
}
</style>
