<template>
    <div>
        <!-- 数字证书申请及免责协议 -->
        <div class="hetongclass" v-if="zhilist == 1">
            <div style="display: flex;">
                <van-icon class="fanhui" name="arrow-left" color="#202020" size="28" @click="fanhui" />
                <div class="hetongguanl">数字证书申请及免责协议</div>
            </div>
            <div style="padding-bottom: 20px;padding-top: 20px;">
                <br><p class="xiaotan">&nbsp;&nbsp;&nbsp;&nbsp;本协议由湖南腾速科技有限公司（下称“珊瑚课堂”）与数字证书用户（下称“用户”或“您”）之间签署，本协议具有合同效力。珊瑚课堂作为多家合作CA机构的授权审核机构，有权与用户签署本协议。CA机构作为权威、可信、公正的第三方电子认证服务机构，根据国家相关法律、法规，为用户提供合法的数字证书申请、审核、签发和管理等电子认证服务。为明确各方权利和义务，珊瑚课堂和用户就数字证书的申请和使用等事宜达成以下协议，共同遵守执行。</p><br>
                <p class="xiaotan"><span class="zijifuze">第一条 定义</span><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;数字证书：电子认证服务机构签发的包含数字证书使用者身份信息和公开密钥的电子文件。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;CA机构：工业和信息化部批准的电子认证服务机构和国家密码管理局批准的电子政务电子认证服务机构，遵照《中华人民共和国电子签名法》为用户提供数字证书相关的电子认证服务。
                    &nbsp;&nbsp;&nbsp;&nbsp;湖南腾速科技有限公司：珊瑚课堂，相关CA机构的授权审核机构，为用户提供数字证书颁发的申请、审核等服务。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;用户：数字证书持有人以及申请使用数字证书的主体。<br><br>

                   <span class="zijifuze"> 第二条 声明</span><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;您应在申请数字证书以前务必仔细阅读本协议，若您不同意本协议的任意内容，或者无法准确理解珊瑚课堂对条款的解释，请不要点击同意并进行后续操作； 若您点击同意，则表示您对本协议的全部内容已充分阅读并认可和同意遵守，同时，承诺遵守中国法律、法规、规章及其他政府规范性文件的规定， 如有违反而造成任何法律后果，您将以本人名义独立承担所有相应的法律责任。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;您点击同意即表示您同意珊瑚课堂将您的个人身份信息或单位信息传输至珊瑚课堂与CA机构用于申请数字证书。<br><br>

                    <span class="zijifuze">第三条 申请</span><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;用户同意通过珊瑚课堂向珊瑚课堂合作的CA机构申请并使用该机构颁发给用户的数字证书，承诺在申请证书时提供真实、完整和准确的信息及证明材料。如因故意或过失未提供真实、完整和准确的信息，导致CA机构签发证书错误，造成相关各方损失的，由用户承担相关责任。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;珊瑚课堂作为CA机构的授权审核机构，负责用户的信息录入、身份审核等工作。用户在申请数字证书时应遵照CA机构的规程办理手续。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;珊瑚课堂应积极响应用户发出的证书申请请求，及时通过CA机构为用户签发证书。如果由于设备或网络故障而导致签发数字证书错误、延迟、中断或者无法签发，珊瑚课堂和CA机构不承担任何赔偿责任。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;用户在获得数字证书时应及时验证此证书所匹配的用户信息，如无异议则视为接受证书并承担该证书使用的责任和后果。<br><br>

                    <span class="zijifuze">第四条 使用</span><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;数字证书用于网络上的用户身份标识、数字签名验证及密钥分配，各应用系统可根据需要对其用途进行定义，但不包括涉及违反国家法律、法规或危害国家安全的用途。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;用户应确保其应用系统能为数字证书提供安全的应用环境，若因网络、主机、操作系统或其他软硬件环境等存在安全漏洞，由此导致的安全事故及相关后果，珊瑚课堂和CA机构不承担责任。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;用户应当妥善保管数字证书以及调用数字证书的验证码或密码，不得泄漏或交付他人。如用户保管不善导致数字证书遭盗用、冒用、伪造或者篡改，用户应当自行承担相关责任。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;数字证书对应的私钥为用户本身访问和使用，用户对使用数字证书的行为负责。所有使用数字证书在网络上签署电子文件或进行其他活动均视为用户所为，因此而产生的相关后果应当由用户自行承担。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;数字证书一律不得转让、转借或转用。因转让、转借或转用而产生的相关后果应当由用户自行承担。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;如遇数字证书遗失、被窃，或数字证书私钥泄露，用户应当立即到数字证书颁发的CA机构申请注销证书。<br><br>
                    <span class="zijifuze">第五条 更新</span><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;珊瑚课堂保证您在使用我们的电子合同签约服务时，您的数字证书处于有效期内。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="zijifuze">因技术需要，如遇证书中的信息发生变更，用户应及时通过珊瑚课堂向CA机构申请更新证书。若用户逾期没有更新证书，因此而产生的相关后果应当由用户自行负责。</span><br><br>

                    <span class="zijifuze">第六条 吊销</span><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="zijifuze">如遇数字证书私钥泄露丢失、证书中的信息发生重大变更、或用户不希望继续使用数字证书的情况，用户应当立即向CA机构申请吊销证书。吊销手续遵循各注册机构的规定。用户应当承担在证书吊销之前所有因使用数字证书而造成的责任。</span><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="zijifuze">如果用户主体资格灭失（如企业注销等），法定代表人或授权代表通过珊瑚课堂向CA机构请求吊销用户证书，用户主体及相关责任人应当承担其数字证书在吊销前所有使用数字证书而造成的相关后果。</span><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;对于下列情形之一，CA机构有权主动吊销所签发的证书：<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;(1)用户申请证书时，提供不真实信息；<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;(2)证书对应的私钥泄露或出现其他证书的安全性得不到保证的情况；<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;(3)用户不能履行或违反了相关法律、法规和本协议所规定的责任和义务；<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;(4)法律、法规规定的其他情形。<br><br>
                    
                    <span class="zijifuze">第七条 其他</span><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;珊瑚课堂与CA机构不对由于意外事件或其他不可抗力事件而导致暂停或终止全部或部分证书服务承担任何责任。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;本协议的解释适用中华人民共和国法律。若发生任何纠纷或争议，首先应友好协商解决，协商不成的，争议方均可提请杭州仲裁委员会按照该会有效的仲裁规则进行仲裁。仲裁裁决是终局的，对双方均具有约束力。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;用户确认已经认真阅读并完全理解本协议中的各项规定，用户一旦在本页面点击同意即表明接受本协议的约束，本协议即时生效。</p>
            </div>
        </div>
        <!-- 珊瑚课堂用户注册与使用协议 -->
        <div class="hetongclass" v-if="zhilist == 2">
            <div style="display: flex;">
                <van-icon class="fanhui" name="arrow-left" color="#202020" size="28" @click="fanhui" />
                <div class="hetongguanl">珊瑚课堂用户注册与使用协议</div>
            </div>
            <div style="padding-bottom: 20px;padding-top: 20px;">
                <p class="xiaotan">&nbsp;&nbsp;&nbsp;&nbsp;本网站由湖南腾速科技有限公司（下称“珊瑚课堂”或“本网站”）负责运营。本注册与使用协议双方为珊瑚课堂与珊瑚课堂注册用户（下称“用户”或“您”），本协议具有合同效力，适用于您在本网站的全部活动。</p>
                <p class="xiaotan">
                    <br><span class="zijifuze">一、注册声明</span><br>
                    <span class="zijifuze">
                    （一）请您在注册成为珊瑚课堂用户前务必仔细阅读本协议，若您不同意本协议的任意内容，或者无法准确理解珊瑚课堂对条款的解释，请不要进行后续操作；若您注册成为珊瑚课堂用户，则表示您对本协议的全部内容已充分阅读并认可和同意遵守。同时，承诺遵守中国法律、法规、规章及其他政府规范性文件的规定，如有违反而造成任何法律后果，您将以本人名义独立承担所有相应的法律责任。<br>
                    （二）本协议内容包括以下条款及已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的一部分，与协议正文具有同等法律效力。珊瑚课堂有权根据需要不定时地制定、修改本协议或各类规则，如本协议及规则有任何变更，一切变更以本网站最新公布的内容为准。经修订的协议、规则一经公布，立即自动生效，对新协议、规则生效之后注册的用户发生法律效力。对于协议、规则生效之前注册的用户，若用户在新规则生效后继续使用本网站提供的各项服务，则表明用户已充分阅读并认可和同意遵守新的协议或规则。若您拒绝接受新的协议和规则，您必须放弃使用珊瑚课堂提供的各项服务（包括但不限于使用用户名和密码登录珊瑚课堂、在珊瑚课堂上进行电子合同签约、证据托管等）。<br>
                    （三）您使用珊瑚课堂即表示您同意珊瑚课堂将您的个人身份信息或企业信息及其签署的合同、其个人或企业的电子签名认证信息传输至公司合作的第三方认证机构以及司法鉴定机构，且前述信息保存至数字证书失效后5年，且该等信息不因您的账户锁定或注销而停止保存。<br>
                    </span>
                    <br><span class="zijifuze">二、定义及解释</span><br>
                    （一）珊瑚课堂账户<br>
                    指您通过注册获得珊瑚课堂账号以供您使用珊瑚课堂各项服务的账户。<br>
                    （二）本网站<br>
                    除本协议另有规定外，指www.fangxinqian.cn及相关移动客户端应用程序。<br>
                    （三）CA机构<br>
                    指获得《电子认证服务许可证》的第三方数字证书签发机构。<br>
                    （四）电子签名认证证书<br>
                    即数字证书，指由第三方CA签发的，含有签发电子签名认证证书的电子认证服务机构名称、证书持有人名称、证书序列号、证书有效期、证书持有人的电子签名验证数据、电子认证服务机构的电子签名、工业和信息化部规定的其他内容的身份证明数据。<br>
                    （五）电子签名<br>
                    指数据电文中以电子形式所含、所附用于识别签名人身份并表明签名人认可其中内容的数据。<br>
                    （六）数据电文<br>
                    指以电子、光学、磁或者类似手段生成、发送、接收或者储存的信息。<br>
                    
                    <br><span class="zijifuze">三、珊瑚课堂服务简介</span><br>
                    （一）电子合同签约服务<br>
                    采用电子签名、时间戳等技术，实现对数据电文的安全保护和签章信息的图形化展现，明确电子文件签署者各方的身份信息、签署行为的过程以及签署完成后文件改动，从而使用户在线完成文件的签署。<br>
                    （二）合同及证据托管、证据保全、下载服务<br>
                    用户签署的文件在完成后，将由珊瑚课堂对签署完成的文件本身以及签署的流程信息等内容进行保存，并同步至珊瑚课堂合作的第三方认证机构以及司法鉴定机构。合同的发起方及签署方可在本网站随时下载签署完成的合同。<br>
                    （三）合同模板服务<br>
                    珊瑚课堂准备了部分类型的合同模板以供您在签署相关合同时进行参考。<br>
                    （四）珊瑚课堂出证服务<br>
                    珊瑚课堂作为独立的第三方，可独立作出报告文件，该文件包含了合同原文件数据、合同签署方账户信息和签署流程信息，以供本地存档查阅，并可作为调解、仲裁等争议解决过程中的合同相关证据出示。用户申请出证报告时，相关认证信息（如合同另一签署方的信息）会披露给出证发起方。<br>
                    （五）司法鉴定服务<br>
                    珊瑚课堂与相关司法鉴定机构就鉴定珊瑚课堂电子签名服务相关内容达成战略合作，可对在珊瑚课堂平台签署的相关文件作出司法鉴定。<br>
                    
                    <br><span class="zijifuze">四、服务费用</span><br>
                    （一）当用户使用本网站服务时，珊瑚课堂将向用户收取相应服务费用。各项服务具体费用标准请详见网站上公布的业务定价及有关规则。用户承诺按照珊瑚课堂公布的服务费用标准向珊瑚课堂支付服务费用。珊瑚课堂保留单方面制定与调整服务费用的权利。费用标准一旦被修改，即公布在本网站上，修改后的费用标准一经公布即生效，适用于费用标准修改后发生的交易。<br>
                    （二）珊瑚课堂在避免用户损失的前提下，有权单方决定暂时或永久地改变或停止提供某些服务。<br>
                    （三）用户通过本网站购买签署套餐后，未开始使用签发文件服务消耗套餐用量的，自付款之日起七个日历天内，可向珊瑚课堂申请退还已支付的相应服务费用。<br>
                    （四）除上述情形外，珊瑚课堂不接受其它任何情况下非珊瑚课堂原因的退费申请。珊瑚课堂不因用户账号注销、套餐有效期届满等原因而负有退还用户已支付费用的义务。<br>
                    
                    <br><span class="zijifuze">五、用户账户</span><br>
                    （一）珊瑚课堂用户<br>
                    珊瑚课堂的用户包括企业用户和个人用户。企业用户是指通过本网站完成全部注册程序后，使用珊瑚课堂提供的服务，并且依据中华人民共和国法律在境内（香港、台湾、澳门除外）设立的，能够独立承担民事责任的法人和其他组织。个人用户是指中华人民共和国公民，通过本网站完成全部注册程序后，使用珊瑚课堂提供的服务，并且符合中华人民共和国法律规定的年满14周岁的自然人。<br>
                    （二）用户注册与实名认证<br>
                    1.个人及企业在本网站注册方可成为珊瑚课堂的用户。个人及企业注册成为珊瑚课堂用户后须进行实名认证。在注册与实名认证过程中个人及企业必须根据本网站的要求提供最新、真实、有效及完整的资料。其中，根据实名认证方式的不同，个人所需提供的实名认证资料将有所差异，具体所需资料请以本网站要求为准。企业需要提供的实名认证资料包括但不限于营业执照、组织机构代码证、税务登记证，企业法定代表人姓名及其身份证信息，并且企业法定代表人需要选择eID认证、手持身份证照认证或企业银行基本账户认证的任意一种方式进行实名认证。<br>
                    2.实名认证具体操作流程请严格按照本网站实名认证规定的流程履行，否则将影响实名审核的结果。<br>
                    3.用户同意并授权珊瑚课堂审核、核对用户资料信息以确认用户身份，但用户信息是由用户本人自行提供的，故珊瑚课堂无法保证该信息之准确、有效和完整。所提交的用户认证信息将用于核发其专属的电子签名文件，珊瑚课堂将对用户的信息进行严格保密。用户信息及隐私保护请详见《隐私政策》。<br>
                    （三）账户安全<br>
                    1.珊瑚课堂将通过用户的登录名、密码以及登录后的其他操作识别用户的相关指示，同时珊瑚课堂会通过本服务应用识别来自用户使用的各类产品或设备的指示，用户应当妥善保管珊瑚课堂登录名、密码、身份信息，各类产品或设备，对于因用户泄露登录名、密码、身份信息或是因产品或设备遗失导致的损失，由用户自行承担。用户应在登录本系统时段结束时，以正确步骤离开网站。用户如果发现有他人冒用或盗用其珊瑚课堂登录名及密码或任何其他未经合法授权之情形，或发生与珊瑚课堂账户关联的手机或其他设备遗失或其他可能危及到珊瑚课堂账户安全情形时，应立即以有效方式通知本公司，向本公司申请账户锁定。<br>
                    2.用户应对其持有的珊瑚课堂账户负责，只有用户本人方可使用该账户。该账户不可转让、不可继承，在您决定不再使用该账户时，您应将该账户内的数据导出或删除，并按本公司规定流程向本公司申请锁定该账户。<br>
                    3.珊瑚课堂可以暂时停止提供或者限制本服务部分功能，或提供新的功能，在任何功能减少、增加或者变化时，只要用户仍然使用本服务，即表示同意本协议或者变更后的协议。<br>
                    4.珊瑚课堂对因用户未能遵守本协议的约定而发生的任何直接或间接损失等不利后果不承担任何责任。<br>
                    5.珊瑚课堂有权了解您使用本公司产品或服务的真实交易背景及目的，用户应如实提供本公司所需的真实、全面、准确的信息。如果本公司有合理理由怀疑用户提供虚假信息、进行欺诈等违法违规行为的，本公司有权根据相关国家法律法规的规定对用户个人信息以及签署的文件信息等进行核实、查询、披露，有权锁定相应账户并配合相关机关进行后续调查。<br>
                    
                    <br><span class="zijifuze">六、通知与送达</span><br>
                    （一）通知方式<br>
                    本协议条款及任何其他的协议、公告或其他关于您使用本服务账号及服务的通知，您同意本网站使用电子方式通知您。电子方式包括但不限于以电子邮件方式、或于本网站或者合作网站上公布、或无线通讯装置通知等方式。<br>
                    （二）送达<br>
                    网站的通知如以公示方式做出，一经在本网站公示即视为已经送达。除此之外，其他向您个人发布的具有专属性的通知将由本网站在您注册时或者注册后变更用户信息时向本网站提供的电子邮箱或用户注册后在本网站绑定的手机号码发送，一经发送即视为已经送达。请您密切关注用户的电子邮箱以及手机中的短信信息。因信息传输或您变更邮箱但未在本网站的注册信息中填写您变更后的邮箱等原因导致您未在前述通知发出当日收到该等通知的，本网站不承担责任。<br>
                    （三）通知内容<br>
                    您同意珊瑚课堂利用在本网站登记的联系方式与您联络并向您传递相关的信息，包括但不限于行政管理方面的通知、产品信息、有关您使用本网站的通讯以及针对性的广告等。<br>
                    
                    <br><span class="zijifuze">七、使用事项</span><br>
                    <span class="zijifuze">
                    （一）您在使用珊瑚课堂服务时，必须遵守国家各项法律、法规、规章以及政府规范性文件，并接受珊瑚课堂相关的协议、规则、规定、程序和惯例的约束。禁止在本网站进行任何可能违反国家法律、法规、规章和政府规范性文件的行为（包括但不限于侵犯任何第三方著作权、专利权、商标权、商业秘密、隐私权、名誉权或其它权利的行为）或者任何未经授权使用本网站的行为（包括但不限于擅自进入本网站未公开的系统、不正当的使用密码和网站的任何内容等）。<br>
                    （二）注册成功后，每位用户都拥有一个属于自己的用户名和登录密码，通过使用该用户名和登录密码您可以登录珊瑚课堂，从而使用珊瑚课堂服务。您须妥善保管您在珊瑚课堂上的用户名和密码。本网站通过您的用户名、密码以及登录后的操作来识别您的指令。您确认，使用您的用户名和密码登录本网站后在本网站的一切行为均代表您本人意志。使用您的用户名和密码登录操作所产生的电子信息记录均为您行为的有效凭据，并由您本人承担相应的法律后果。不得在未经珊瑚课堂许可的情况下出售或授权使用账号。<br>
                    （三）您对通过珊瑚课堂发布的所有信息（内容包括但不限于个人信息、商业信息，形式包括但不限于文字、图片）的真实性、准确性、即时性、完整性和合法性独立承担所有责任。同时，在您所发布的信息中不得含有蓄意毁坏、恶意干扰、秘密地截取或侵占任何系统、数据或个人资料的任何病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他电脑程序。<br>
                    （四）请注意：根据《电子签名法》的规定下列情形不适合使用电子文书：涉及婚姻、收养、继承等人身关系的；涉及停止供水、供热、供气等公用事业服务的；法律、行政法规规定的不适用电子文书的其他情形。因此，用户不得在珊瑚课堂平台上签订上述法律文件。若用户在珊瑚课堂平台上传并签订上述法律文件，而根据法律之规定上述签约行为无效的，珊瑚课堂不承担任何责任。<br>
                    （五）本网站内容可能涉及由第三方所有、控制或运营的其它网站（以下称“第三方网站”）。本网站不能保证也没有义务保证第三方网站上的信息的真实性和有效性。您应按照第三方网站的相关协议与规则使用第三方网站，而不是按照本协议。第三方网站的内容、产品、广告和其他任何信息均由您自行判断并承担风险，而与本网站无关。<br>
                    </span>
                    
                    <br><span class="zijifuze">八、用户间纠纷处理</span><br>
                    （一）用户应在使用珊瑚课堂服务时，应审慎核查签署各方的身份信息，对于签署方中有未经珊瑚课堂实名认证的用户，可能产生签约主体不明或签约主体错误的风险，如需避免此风险，请各签署方进行实名认证并审慎核查签署方身份。否则，因此而产生的风险和责任，由用户自行承担。<br>
                    （二）在使用珊瑚课堂的过程中，用户发现任何可能侵害自己或珊瑚课堂权利的事实时，应及时通知珊瑚课堂并提供相应的证明材料。因投诉不实给珊瑚课堂或第三方造成损失的，用户应承担法律责任。<br>
                    （三）珊瑚课堂有权接受并处理您与其他用户间因使用珊瑚课堂服务而产生的纠纷及投诉，有权通过电话、短信、电子邮件等联系方式向您了解情况，并将所了解的情况通过上述方式通知对方。您有义务在收到珊瑚课堂通知后，在指定的时间内提供相应的资料，以配合纠纷及投诉的情况了解与处理，否则，珊瑚课堂有权单方面独立判断其他用户对您的投诉，一旦成立，并做出对您不利的处理结果。同时，珊瑚课堂有权立即中止或终止您的相关珊瑚课堂服务。<br>
                    （四）珊瑚课堂受理、处理纠纷、争议及投诉完全依据您的授权。因珊瑚课堂对证据的鉴别能力及对纠纷的处理能力有限，不保证处理结果一定符合您的期望。珊瑚课堂有权决定是否参与争议的处理。<br>
                    （五）经生效法律文书确认用户存在违法或违反本协议行为或者珊瑚课堂自行判断您涉嫌存在违法或违反本协议行为的，珊瑚课堂有权公布用户的违法行为并做出相应处理，包括但不限于终止服务、永久禁止使用珊瑚课堂等。<br>
                    
                    <br><span class="zijifuze">九、隐私条款</span><br>
                    请您详见隐私政策。<br>
                    
                    <br><span class="zijifuze">十、知识产权声明</span><br>
                    无论是否明示，珊瑚课堂对网站内所有非公有领域或非他方专有的信息内容享有知识产权（包括但不限于商标权、专利权、著作权），信息内容包括但不限于文字、图片、软件、音频、视频、数据、源代码、设计。非经珊瑚课堂书面授权同意，任何组织或个人都不得复制、打印和传播属于珊瑚课堂的信息内容用于其他用途。网站所有的产品、技术及程序均属于珊瑚课堂知识产权，未经珊瑚课堂书面授权许可，任何人不得擅自使用（包括但不限于以非法的方式打印、复制、传播、展示、下载等）。否则，珊瑚课堂将依法追究其法律责任。<br>
                    
                    <br><span class="zijifuze">十一、免责声明</span><br>
                    <span class="zijifuze">
                    （一）您有义务在注册时提供自己的真实、最新、完整的资料，并保证电子邮件地址、联系电话、联系地址、邮政编码等内容的最新、有效性及安全性。您有义务维护并立即更新您的个人资料，确保其真实、最新、有效及完整。若您提供任何错误、虚假、过时或不完整的资料，或者本网站有合理的理由怀疑您提供的为错误、虚假、过时或不完整的资料时，本网站有权暂停或终止您的账号，并拒绝您使用本网站部份或全部服务。在此情况下，本网站不承担任何责任，并且您同意自行承担因此所产生的直接或间接的任何支出或损失。<br>
                    （二）在如下情况，珊瑚课堂有权中止、终止对您提供部分或全部服务而不承担任何责任：<br>
                    1.在珊瑚课堂未向您收费的情况下，珊瑚课堂可自行全权决定以合理理由 (包括但不限于您已违反本协议的字面意义和精神，或您以不符合本协议的字面意义和精神的方式行事) 终止您的登录密码、用户名 (或其任何部份) 或您对服务的使用。<br>
                    2.在出现下列任一情况时，珊瑚课堂可立即发出警告，中止或终止您的账号，删除您的任何现有信息，以及您在网站上展示的任何其他资料：（a）您违反本协议；(b)珊瑚课堂无法核实或鉴定您向珊瑚课堂提供的任何资料；（c）珊瑚课堂相信您的行为可能会使您、珊瑚课堂其他用户或通过珊瑚课堂提供服务的第三者服务供应商发生任何法律责任；(d)发现您从事涉及网站的诈骗等违法违规活动，珊瑚课堂有权中止或终止您的服务。对于（a）、（b）、(c)三项，珊瑚课堂不限制用户采取相关补救措施。<br>
                    3.对于本协议规定的采取的中止或终止措施，珊瑚课堂不对结果承担任何责任。<br>
                    4.根据本协议的任何规定中止或终止您使用服务之措施，珊瑚课堂将本着审慎的态度实施，并将履行告知义务，即使通知您不能继续使用珊瑚课堂的服务，但在您的账号内的所有相关资料，珊瑚课堂将继续为您保存，且可以查询和为您提供相关文件内容的证明。<br>
                    5.由于互联网本身所具有的不稳定性，珊瑚课堂无法保证服务不会中断。系统因有关状况无法正常运作，使用户无法使用任何珊瑚课堂服务或使用珊瑚课堂服务受到任何影响时，珊瑚课堂对用户或第三方不负任何责任，前述状况包括但不限于：<br>
                    （1）珊瑚课堂系统停机维护期间。<br>
                    （2）电信设备出现故障不能进行数据传输的。<br>
                    （3）由于黑客攻击、网络供应商技术调整或故障、网站升级、银行方面的问题等原因而造成的珊瑚课堂服务中断或延迟。<br>
                    （4）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成珊瑚课堂系统障碍不能执行业务的。<br>
                    6.因用户的过错导致的任何损失，该过错包括但不限于：操作不当、遗忘或泄露密码、密码被他人破解、用户使用的计算机系统被第三方侵入、用户委托他人代理签署时他人恶意或不当操作而造成的损失，珊瑚课堂不承担任何责任。<br>
                    </span>
                    
                    <br><span class="zijifuze">十二、违约责任</span><br>
                    <span class="zijifuze">
                    （一）因您违反本协议或经在此提及而纳入本协议的其他文件，或因您违反了法律、法规、规章、政府规范性文件或侵害了第三方的权利，而使第三方对珊瑚课堂及其股东、职员、代理人提出索赔要求（包括司法费用和其他专业人士的费用），您必须对珊瑚课堂及股东、职员、代理人承担赔偿责任，使其等免遭损失。<br>
                    （二）如因珊瑚课堂违反有关法律、法规或本协议项下的任何条款而给用户造成损失，珊瑚课堂同意承担由此造成的损害赔偿责任，基于您遭受的实际损失，以以下两者之中金额较高者为限：（1）人民币5000元；或（2）导致该等实际损失的事件发生前12个月内您为使用对应服务所支付的金额。<br>
                    </span>
                    
                    <br><span class="zijifuze">十三、法律适用与管辖</span><br>
                    （一）本协议的订立、变更、执行和解释，以及与本协议有关的争议解决，均应适用中华人民共和国法律。如与本协议有关的某一特定事项没有法律规定或规定不明确，则应参照通用的国际商业惯例和行业惯例。<br>
                    （二）如因本协议或珊瑚课堂服务所引起或与其有关的任何争议应向浙江葫芦娃网络集团有限公司所在地人民法院起诉。<br><br>
                    
                    <span class="zijifuze">十四、其他</span><br>
                    （一）本协议自您同意勾选并成功注册为本网站用户之日起生效，除非本网站终止本服务协议或者用户丧失本网站用户资格，否则本服务协议始终有效。本服务协议终止并不免除用户根据本服务协议或其他有关协议、规则所应承担的义务和责任。<br>
                    （二）本服务协议部分条款被认定为无效时，不影响本服务协议其他条款的效力。<br>
                    （三）本协议中的标题仅为方便而设，在解释本协议时应被忽略。<br>
                    （四）珊瑚课堂对本服务协议享有最终的解释权。<br>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "AgrEement",
    data() {
        return {
            zhilist: 0
        }
    },
    created() {
        console.log('222222222', this.$route.query.zhilist)
        this.zhilist = this.$route.query.zhilist
    },
    methods: {
        fanhui() {
            this.$router.go(-1)
        },
    }
}
</script>
<style scoped>
.zijifuze {
    font-weight: 600;
}
.xiaotan {
    margin-left: 20px;
    margin-right: 20px;
}
.hetongguanl {
    font-size: 17px;
    font-family: PingFang SC, PingFang SC-700;
    font-weight: 700;
    color: #202020;
    margin-left: 56px;
    line-height: 44px;
}
.fanhui {
    margin-left: 17px;
    line-height: 44px;
}
.hetongclass {
    width: 100%;
    height: 44px;
    background: #fff;
    margin-top: 20px;
}
* {
    margin: 0;
    padding: 0;
}
</style>