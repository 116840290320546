<template>
    <div class='swiper-image-previewer'>
        <div v-if="component.sub_list.length === 0" class="default-swiper">
            <img class="item-default-icon" src="@/assets/images/photo.svg" />
            <span class="item-default-text">轮播图</span>
        </div>
        <van-swipe class="my-swipe" :autoplay="3000" height="147" indicator-color="white" v-else>
            <van-swipe-item v-for="item in component.sub_list" :key="item.id">
                <img :src="item.img" alt="" srcset="" width="100%" height="100%" @click="gotoDetail(item)" />
            </van-swipe-item>
        </van-swipe>
    </div>
</template>
<script>
    import { gotoPageByConfig } from '@/utils'

    export default {
        name: 'swiperImagePreviewer',
        props: ['component'],
        methods: {
            gotoDetail(info) {
                if(this.component.show_type === '1') {
                    return
                }
                gotoPageByConfig({
                    ...info,
                    isLive: info.is_live
                })
            }
        }
    }
</script>
<style lang='less' scoped>
.swiper-image-previewer {
    // width: 343px;
    // background: #ffffff;
    margin: 10px 12.5px;
    // padding: 0 12.5px;
    border-radius: 10px;
    overflow: hidden;
    .default-swiper{
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 147px;
        align-items: center;
        justify-content: center;
        background: #f1f3f7;
        font-size: 14px;
        .item-default-icon{
            width: 82px;
            height: 67px;
            margin-top: 8px;
            color: #b2b2b2;
            font-size: 14px;
        }
    }
    ::v-deep img {
        border-radius: 10px;
    }
}
</style>