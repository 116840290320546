<template>
  <div class="attachment-list">
    <!-- <div class="attachment-item" v-for="(item,index) of question.file" :key="index">
      <img class="file-icon" src="@/assets/img/file.png" />
      <div class="upload-status">
        <div class="attachment-name">{{ item.title }}</div>
        <div class="progress">
          <span>大小：{{item.size}}M</span>
          <img class="close-icon" src="@/assets/images/download.png" @click="downloadFile(item.url)" />
        </div>
      </div>
    </div> -->
    <div class="attachment-item" v-for="(item,index) of file" :key="index" @click="downloadFile(item.url)" >
      <img class="file-icon" src="@/assets/img/file.png" />
      <div class="upload-status">
        <div class="attachment-name">{{ item.title }}</div>
        <div class="progress" v-if="item.size">
          <span>{{item.size}}M</span>
        </div>
        <div class="progress" v-else-if="item.progress">
            <span>{{(item.progress.total/(1024*1024)).toFixed(2)}}M</span>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
export default {
  props: {
    file: {
			type: Array,
			default: () => ([])
		},
   },
  data () {
    return { }
  },
  computed: { },
  watch: { },
  methods: {
    downloadFile(url){
      window.open(url)
    }
   },
  mounted() { }
}
</script>

<style lang="scss" scoped>
.attachment-list{
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0 10px;
  .attachment-item{
    // height: 64px;
    background: #E7E9ED;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 5px 16px 5px 6px;
    margin-bottom: 10px;
    border-radius: 4px;
    width: 100%;
    .attachment-name{
      max-width: 177px;
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap
    }
    .file-icon{
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }
    .upload-status{
      display: flex;
      flex:1;
      align-items: center;
      .progress{
        display: flex;
        margin-top: 6px;
        align-items: center;
        margin-left: auto;
        ::v-deep .el-progress-bar__outer{
          background-color:#ffffff;
        }
        span{
          font-size: 12px;
          color: #888888;
        }
      }
    }
    .close-icon{
      width: 16px;
      height: 16px;
      margin-left: auto;
      cursor: pointer;
    }
  }
}
</style>
