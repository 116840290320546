<template>
    <div style="min-width: 340px;">
         <!-- 视频播放区 -->
    <div class="videoplay center">
        <video src=""></video>
        <div class="videoone">
            <a href=""></a>
            <a href=""></a>
            <a href=""></a>
        </div>
        <!-- 免费试听 -->
        <div class="videotwo">
            <a href="">免费试听</a>
        </div>
        <!-- 咨询微信 -->
        <!-- <div class="zxwx" id="Wxzx" style="display: none;">
            <h5>请添加老师微信咨询课程资料</h5>
            <div class="wx">
                <img src="../../assets/img/ico_weixin.png" alt="">
                <span>tengface2021</span>
            </div>
            <div class="qx">
                <a href="#" id="quxiao">取消</a>
                <a href="">复制微信</a>
            </div>
        </div> -->
        <!-- 点击背景色 -->
        <div class="beijing" id="bj" style="display: none;"></div>
    </div>
    <!-- 人教版 -->
    <div class="information center">
        <span>人教版 ｜ 帮数学 方法多</span>
        <div>
            <p>直播时间：10月12日 20:00</p>
            <p>74人学习</p>
            <img src="../../assets/img/ico_guankan.png" alt="">
        </div>

    </div>
    <!-- 课程目录 -->
    <div class="catalog center">
        <div class="catalog-title">
            <span>课程目录</span>
        </div>
        <ul>
            <li>
                <div class="catalogone">
                    <span>01</span>
                    <span>回放</span>
                    <span>初识PS与基础操作</span>
                </div>
                <div class="catalogtwo">
                    <span>2021-07-18</span>
                    <span>20:00~22:00</span>
                </div>
            </li>
            <li>
                <div class="catalogone">
                    <span>02</span>
                    <span>回放</span>
                    <span>图文排版编辑</span>
                </div>
                <div class="catalogtwo">
                    <span>2021-07-18</span>
                    <span>20:00~22:00</span>
                </div>
            </li>
            <li>
                <div class="catalogone">
                    <span>03</span>
                    <span>回放</span>
                    <span>色彩模式和画笔工具</span>
                </div>
                <div class="catalogtwo">
                    <span>2021-07-18</span>
                    <span>20:00~22:00</span>
                </div>
            </li>
            <li>
                <div class="catalogone">
                    <span>04</span>
                    <span style="background: #e6f6f0;color: #10a675;">直播</span>
                    <span>认识选区和图层样式</span>
                </div>
                <div class="catalogtwo">
                    <span>2021-07-18</span>
                    <span>20:00~22:00</span>
                </div>
            </li>
            <li>
                <div class="catalogone">
                    <span>05</span>
                    <span style="background: #e6f6f0;color: #10a675;">直播</span>
                    <span>抠图与钢笔工具</span>
                </div>
                <div class="catalogtwo">
                    <span>2021-07-18</span>
                    <span>20:00~22:00</span>
                </div>
            </li>
        </ul>
        <!-- 查看更多 -->
        <div class="examine">
            <span>查看更多目录</span>
            <img src="../../assets/img/ico_xiangxiazhankai.png" alt="">
        </div>
    </div>
    <!-- 课程咨询 -->
    <el-button class="counsel center" type="text" @click="dialogVisible = true">
         <div>
            <img src="../../assets/img/ico_zixunq.png" alt="">
            <p>课程咨询</p>
        </div>
        <a href="#" id="ljzx">立即咨询</a>
      </el-button>
       <el-dialog
       style="text-align: center;"
        title="提示"
        :visible.sync="dialogVisible"
        width="80%"
        :before-close="handleClose">
           <h5>请添加老师微信咨询课程资料</h5>
            <div class="wx">
                <img src="../../assets/img/ico_weixin.png" alt="">
                <span>tengface2021</span>
            </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="quxiao" @click="dialogVisible = false">取 消</el-button>
          <el-button class="boda" type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
</template>
<script>
export default {
  data () {
    return {
      dialogVisible: false
    }
  },
  methods: {
    handleClose (done) {
    }
  }
}
</script>
<style scoped>
.quxiao{
    width: 100px;
    line-height: 32px;
    text-align: center;
    background: #efefef;
    border-radius: 17px;
    font-size: 14px;
    font-weight: 400;
    color: #101010;
}
.boda{
    width: 100px;
    line-height: 32px;
    text-align: center;
    background: #efefef;
    border-radius: 17px;
    font-size: 14px;
    font-weight: 400;
    color: #101010;
    color: #ffffff;
    background: linear-gradient(95deg,#61d1ac 4%, #10a675 95%);
}
    /* 初始化 */
* {
    padding: 0;
    margin: 0;
}
/* 公共类 */
.center {
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
}
a {
    text-decoration: none;
}
body {
    background-color: #f6f6f6;
    font-family: PingFangSC, PingFangSC-Semibold;
}
li {
    list-style: none;
}

/* 视频播放区域 */
.videoplay {
    background-color: #fff;
    height: 200px;
    width: 100%;
    position: relative;/* 相对定位 */
}
.videoplay video {
    width: 100%;
}
.videoplay .videoone a {
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    opacity: 0.5;/* 设置 div 元素的不透明级别 */
    border-radius: 50%;
}
.videoplay .videoone a:nth-child(1) {
    top: 13px;
    left: 13px;
    background:  #7a7a7a url(../../assets/img/ico_fanhui.png) no-repeat center;
}
.videoplay .videoone a:nth-child(2) {
    top: 13px;
    right: 59px;
    background:  #7a7a7a url(../../assets/img/heart-o.png) no-repeat center;
}
.videoplay .videoone a:nth-child(3) {
    top: 13px;
    right: 11px;
    background:  #7a7a7a url(../../assets/img/ico_share.png) no-repeat center;
}
.videoplay .videotwo a {
    display: block;
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -54.5px;
    width: 109px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    opacity: 0.5;
    background: #000000;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
}
/* 人教版 */
.information {
    background-color: #fff;
    height: 77px;
    margin-bottom: 10px;
}
.information span {
    margin-left: 16px;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: #101010;
}
.information div p:first-child {
    display: block;
    float: left;
    margin-left: 16px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: #888888;
}
.information div p:nth-child(2) {
    display: block;
    float: right;
    font-size: 12px;
    font-weight: 400;
    color: #10a675;
    margin-right: 16px;
    margin-left: 5px;
}
.information div img {
    float: right;
}
/* 课程目录 */
.catalog {
    background-color: #fff;
}
.catalog .catalog-title {
    height: 44px;
    line-height: 44px;
}
.catalog .catalog-title span {
    margin-left: 16px;
    font-size: 18px;
    font-weight: 600;
    color: #101010;
}
.catalog ul li {
    margin-top: 10px;
    margin-bottom: 10px;
}
.catalog ul li .catalogone {
    height: 28px;
    line-height: 28px;
    padding-left: 16px;
    font-size: 0;
}
.catalog ul li .catalogone span:first-child {
    display: block;
    float: left;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #20242f;
    line-height: 28px;
    margin-right: 17px;
}
.catalog ul li .catalogone span:nth-child(2) {
    display: block;
    float: left;
    width: 30px;
    height: 16px;
    background: #d9e8fe;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 400;
    color: #3f8ef8;
    line-height: 16px;
    text-align: center;
    margin-right: 6px;
    margin-top: 6px;
}
.catalog ul li .catalogone span:nth-child(3) {
    display: block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #101010;;
}
.catalog ul li .catalogtwo {
    height: 20px;
}
.catalog ul li .catalogtwo span {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #888888;
}
.catalog ul li .catalogtwo span:first-child {
    margin-left: 49px;
    margin-right: 10px;
}
.examine {
    height: 44px;
    line-height: 44px;
    display: flex;
    align-items: center;
}
.examine span{
    margin-left: 42%;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #10a675;
}
.examine img {
    margin-left: 6px;
}
/* 课程咨询 */
.counsel {
    height: 58px;
}
.counsel div {
    margin-left: 20px;
    width: 48px;
    float: left;
    text-align: center;
}
.counsel div img {
    margin-top: 6px;
}
.counsel div p {
font-size: 12px;
font-weight: 400;
text-align: center;
color: #20242f;
}
.counsel a {
    display: block;
    float: right;
    width: 69%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: linear-gradient(90deg,#fdb56b, #fc6d2b);
    border-radius: 20px;
    margin-top: 10px;
    margin-right: 16px;
    color: #fff;
}

/* 咨询微信 */
.zxwx {
    width: 280px;
    height: 190px;
    position: absolute;
    top: 267px;
    left: 50%;
    margin-left: -140px;
    background: #ffffff;
    opacity: 1;
    border-radius: 10px;
    z-index: 999;
}
.zxwx h5 {
    display: block;
    width: 180px;
    height: 50px;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #101010;
    line-height: 25px;
    margin-top: 30px;
}
.zxwx .wx {
    width: 123px;
    height: 22px;
    line-height: 22px;
    margin: 0 auto;
    text-align: center;
    margin-top: 12px;

}
.zxwx .wx img {
    float: left;
    margin-right: 2px;
    margin-top: 2px;
    width: 16px;
}
.zxwx .wx span {
    font-size: 16px;
    color: #101010;
}
.zxwx .qx {
    width: 220px;
    height: 32px;
    margin: 0 auto;
    margin-top: 20px;
}
.zxwx .qx a {
    display: block;
    float: left;
    width: 100px;
    line-height: 32px;
    text-align: center;
    background: #efefef;
    border-radius: 17px;
    font-size: 14px;
    font-weight: 400;
    color: #101010;
}
.zxwx .qx a:last-child {
    margin-left: 20px;
    color: #ffffff;
    background: linear-gradient(95deg,#61d1ac 4%, #10a675 95%);
}

.beijing {
    position: absolute;
    top: 0;
    width: 100%;
    height: 733px;
    opacity: 0.4;
    background-color: #000000;
}
</style>
