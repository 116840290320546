<template>
  <div class="person-sign-wrapper">
    <!-- 签署区域 -->
    <section class="board-content">
      <canvas id="myCanvas"></canvas>
    </section>
    <!-- 左侧工具栏 -->
    <section class="tools-content">
      <ul class="dot-list">
        <li class="color" v-for="(c, index) in colors" :key="index" :style="{ border: `1px solid ${isActive === index ? c : 'transparent'}` }" @click="colorChannge(index)">
          <span class="dot" :style="{ backgroundColor: c }"></span>
        </li>
      </ul>
      <div class="auto-sign">
        <span class="text">一键落章</span>
        <van-switch v-model="autoSigned" size="22px" />
      </div>
      <div class="operate-btn">
        <p class="primary-btn danger" :class="{ disabled : !hasContent }" @click="clearCanvas">清除签名</p>
        <p class="primary-btn" @click="submitSign">确认签名</p>
      </div>
    </section>
    <!-- 右侧标题和返回 -->
    <section class="header">
      <van-icon name="arrow-up" class="back" @click="goBack" />
      <div class="title">手绘签名</div>
    </section>
    <!-- 保存时翻转画板，保证保存的签名是横屏方向 -->
    <canvas id="userSign"></canvas>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { mapGetters } from 'vuex'
import uploadFile from '@/utils/cosUpload'
import { FileUpladType } from '@/utils/constant'
import { dataURLtoBlob, blobToFile, getSystemDate } from '@/utils/index'

export default {
  data() {
    return {
      isActive: 0,
      colors: ['#000000', '#e93325', '#1872ed'],
      autoSigned: true,
      canvasElm: null,
      canvasCxt: null,
      mousePressed: false,
      hasContent: false,
      finallyX: 0,
      finallyY: 0,
    }
  },
  computed: {
    ...mapGetters({
      widgetSetting: 'contract/getWidgetSetting'
		})
  },
  mounted() {
    this.init()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    colorChannge(index) {
      this.isActive = index
    },
    init() {
      this.canvasElm = document.querySelector('#myCanvas')
      this.canvasCxt = this.canvasElm.getContext('2d')
      const boardContent = document.querySelector('.board-content')
      this.canvasElm.width = boardContent.clientWidth
      this.canvasElm.height = boardContent.clientHeight
      this.canvasElm.addEventListener('touchstart', this.handleTouchStart, false)
      this.canvasElm.addEventListener('touchmove', this.handleTouchMove, false)
      this.canvasElm.addEventListener('touchend', this.handleTouchEnd, false)
      this.canvasElm.addEventListener('mousedown', this.handleMouseDown, false)
      this.canvasElm.addEventListener('mousemove', this.handleMouseMove, false)
      this.canvasElm.addEventListener('mouseup', this.handleMouseUp, false)
    },
    /**
     * 描述：
    **/
    handleTouchStart(event) {
      if(event.targetTouches.length === 1) {
        // 阻止浏览器默认事件
        event.preventDefault()
        const touch = event.targetTouches[0]
        this.mousePressed = true
        this.computedPosition(touch, false)
      }
    },
    /**
     * 描述：
    **/
    handleTouchMove(event) {
      if(event.targetTouches.length === 1) {
        // 阻止浏览器默认事件
        event.preventDefault()
        this.hasContent = true
        const touch = event.targetTouches[0]
        if(this.mousePressed) {
          this.computedPosition(touch, true)
        }
      }
    },
    handleTouchEnd(event) {
      if (event.targetTouches.length == 1) {
        // 阻止浏览器默认事件
        event.preventDefault()
        event.mousePressed = false
      }
    },
    handleMouseDown(event) {
      event.mousePressed = true
      this.computedPosition(event, false)
    },
    handleMouseMove(event) {
      if (event.mousePressed) {
        this.hasContent = true
        this.computedPosition(event, true)
      }
    },
    handleMouseUp(event) {
      event.mousePressed = false
    },
    computedPosition({ pageX, pageY }, flag) {
      const left = pageX - this.canvasElm.offsetLeft
      const top = pageY - this.canvasElm.offsetTop
      this.draw(left, top, flag)
    },
    draw(x, y, isDown) {
      if (isDown) {
        this.canvasCxt.beginPath()
         // 颜色
        this.canvasCxt.strokeStyle = this.colors[this.isActive]
        // 线宽
        this.canvasCxt.lineWidth = 3
        this.canvasCxt.lineJoin = "round"
        // 设置画笔最大线宽
        this.canvasCxt.lineMax = 10
        // 设置画笔最小线宽
        this.canvasCxt.lineMin = 3
        // 设置画笔笔触压力
        this.canvasCxt.linePressure = 1.2
        // 设置画笔笔触大小变化的平滑度
        this.canvasCxt.smoothness = 30
        this.canvasCxt.moveTo(this.finallyX, this.finallyY)
        this.canvasCxt.lineTo(x, y)
        this.canvasCxt.closePath()
        this.canvasCxt.stroke()
      }
      this.finallyX = x
      this.finallyY = y
    },
    clearCanvas() {
      this.hasContent = false
      this.canvasCxt.setTransform(1, 0, 0, 1, 0, 0)
      this.canvasCxt.clearRect(0, 0, this.canvasCxt.canvas.width, this.canvasCxt.canvas.height);
    },
    submitSign() {
      if(this.checkEmpty()) {
        this.$toast.fail('请在签名区域签名后再次确认')
        return
      }
      this.createCanvasImg()
    },
    /**
     * 描述：验证canvas画布是否为空函数
    **/
    checkEmpty() {
      const blank = document.createElement('canvas')
      blank.width = this.canvasElm.width
      blank.height = this.canvasElm.height
      return this.canvasElm.toDataURL() == blank.toDataURL()
    },
    /**
     * 描述：将canvans的签名生成一张图片
    **/
    createCanvasImg() {
      const imageUrl = this.canvasElm.toDataURL('image/png')
      const imgElm = new Image()
      imgElm.src = imageUrl
      imgElm.onload = async () => {
        // 创建一个新的canvas，将当前横屏的签名翻转为竖屏
        const signCanvasElm = document.querySelector('#userSign')
        signCanvasElm.width = this.canvasElm.height
        signCanvasElm.height = this.canvasElm.width
        const copyCtx = signCanvasElm.getContext('2d')
        // 旋转270度
        copyCtx.rotate((-90 * Math.PI) / 180)
        copyCtx.drawImage(imgElm, -imgElm.width, 0, imgElm.width, imgElm.height)
        const imageData = signCanvasElm.toDataURL('image/png')
        const blob = dataURLtoBlob(imageData)
        const file = blobToFile(blob, '个人签名.png')
        const ossUrl = await uploadFile(file, FileUpladType.CONTRACT)
        this.saveSign(ossUrl)
      }
    },
    async saveSign(url) {
      if(this.isSaveing) {
        return
      }
      this.isSaveing = true
      try {
        const { data: { code } } = await this.$api.post('/user/app/addSignature', {
          address: url
        })
        if(code === 200) {
          Toast('保存成功')
        } else {
          Toast('保存失败') 
        }
        // 如果开启了一键落章，需要存储当前章的地址到组件的value中
        if(this.autoSigned) {
          this.updateSignUrlToWidget(url)
          // this.updateSignUrlToWidget('http://tengface-live-user-1308721187.cos.accelerate.myqcloud.com/1491/contract/2024/11/01/1730443945126_%E4%B8%AA%E4%BA%BA%E7%AD%BE%E5%90%8D.png')
        }
        this.$router.replace({ name: 'ViewSigning', query: { id: this.$route.query.contractId, sign_id: this.$route.query.signId } })
      } finally {
        this.isSaveing = false
      }
    },
    updateSignUrlToWidget(url) {
      const widgetId = this.$route.query.id
      const initiator = this.$route.query.initiator
      const widgets = this.widgetSetting.map(item => {
        if(Number(item.id) === Number(widgetId) ) {
          item.value = url
          item.default = 1
        }
        // 落章后更新个人日期组件时间
        if(item.widget_type === 2 && item.label !== initiator) {
          item.value = getSystemDate()
        }
        return item
      })
      this.$store.dispatch('contract/setWidgetSetting', widgets)
    }
  }
}
</script>

<style lang="scss">
.person-sign-wrapper {
  box-sizing: border-box;
  height: 100vh;
  padding: 20px 50px;
  background-color: #f6f6f6;
  .board-content {
    height: 100%;
    margin: auto;
    border-radius: 10px;
    background-color: #ffffff;
    #myCanvas {
      width: 100%;
      height: 100%;
    }
  }
  .tools-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
    text-align: center;
    padding: 20px 0;
    box-sizing: border-box;
  }
  .dot-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    .color {
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid;
      border-radius: 50%;
      margin-bottom: 20px;
      .dot {
        display: inline-block;
        padding: 7px;
        border-radius: 50%;
      }
    }
  }
  .auto-sign {
    .text, .van-switch{
      transform: rotate(90deg);
    }
    .text {
      display: inline-block
    }
    .van-switch {
      margin-top: 30px;
    }
  }
  .operate-btn {
    position: relative;
    height: 200px;
    .primary-btn {
      position: absolute;
      bottom: 25px;
      left: -14px;
      width: 80px;
      height: 30px;
      font-size: 14px;
      color: #cccccc;
      background: #ffffff;
      border: 1px solid #b7b5b5;
      border-radius: 2px;
      text-align: center;
      line-height: 30px;
      color: #1872ed;
      border-color: #1872ed;
      transform: rotate(90deg);
      &.danger {
        top: 40px;
        color: #f56c6c;
        border-color: #f56c6c;
        &.disabled {
          color: #cccccc;
          border-color: #b7b5b5;
        }
      }
    }
  }
  .header {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    text-align: center;
    padding: 20px 0;
    .back {
      font-size: 26px;
      color: #1872ed;
    }
    .title {
      width: 70px;
      transform: rotate(90deg);
      font-size: 16px;
      color: #1872ed;
      margin-top: calc(50vh - 100px);
      margin-left: -8px;
    }
  }
  #userSign {
    position: absolute; 
    bottom: 0;
    display: none;
    z-index: 0;
  }
}
</style>