<template>
  <div class="padding_top component-container" :style="[childStyle]" v-if="showTitle">
    <div  v-loading="isLoading" v-if="isLoading">

    </div>
    <div class="living-wrap"  v-else>
      <div class="living-header">
        <div class="title">
          {{ title }}
          <!-- <div class="title-line"></div> -->
        </div>
        <!-- <div class="check-more" v-if="showCheckMore" @click="toCoursePage">
                    <div class="text" >查看更多</div>
                    <van-icon class="right" name="arrow" />
                </div> -->
      </div>
      <!-- 一行两个 -->
      <div
        class="living-body-small"
        v-if="arrange_type === '1'"
        :style="[gridRowGap]"
      >
        <div
          class="course-item"
          v-for="(item,index) of course_list"
          :key="item.id"
          @click="gotoDetail(item)"
          v-if="showCourse[index]"
        >
          <div class="cover">
            <img :src="item.coverpic || item.pic_url || item.cover" />
            <div class="live-status" v-if="item.isLiving === 2">
              <img src="@/assets/images/mofang/live_icon.gif" width="20">直播中
            </div>
          </div>
          <div class="course-body">
            <div
              class="course-title"
              :class="course_title_arrange === '1' ? 'one-line' : 'two-line'"
              :style="[titleFontSize, titleFontWeight]"
            >
              {{ item.name }}
            </div>
            <div class="open-time" v-if="component.class_time_show !== '0'">{{ item.create_time }} 开课</div>
            <div v-if="show_course_label" class="label">{{ label }}</div>
            <div class="line"></div>
            <div class="course-item-bottom">
              <div class="price">{{ item.price ? `￥${item.discount_price || item.price}` : '' }}</div>
              <div class="check-btn" :style="{ background: btnColor(item) }">
                {{ btnText(item) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 一行一个 -->
      <div class="living-body-large" v-else>
        <div
          class="course-item"
          v-for="(item,index) of course_list"
          :key="item.id"
          :style="marginBottom"
          @click="gotoDetail(item)"
          v-if="showCourse[index]"
        >
          <div class="cover">
            <img :src="item.coverpic || item.pic_url || item.cover" />
            <!-- <div class="label" v-if="show_course_label">
              <span>{{ label }}</span>
              <div class="label-cover"></div>
            </div> -->
            <div class="live-status" v-if="item.isLiving === 2">
              <img src="@/assets/images/mofang/live_icon.gif" width="20">直播中
            </div>
          </div>
          <div class="course-body">
            <div
              class="course-title"
              :class="course_title_arrange === '1' ? 'one-line' : 'two-line'"
              :style="[titleFontSize, titleFontWeight]"
            >
              {{ item.name }}
            </div>
            <div class="open-time" v-if="component.class_time_show !== '0'">{{ item.create_time }} 开课</div>
            <div v-if="show_course_label" class="label">{{ label }}</div>
            <div class="course-item-bottom">
              <div class="price">{{ item.price ? `￥${item.discount_price || item.price}` : '' }}</div>
              <div class="check-btn" :style="{ background: btnColor(item) }">
                  {{ btnText(item) }}
              </div>
              <!-- <div class="check-btn" :style="{ background: btnColor(item) }">
                {{ btnText(item) }}
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="look-more" v-if="showCheckMore" @click="toCoursePage">
        <div class="btn">
          查看更多
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deepClone, gotoPageByConfig } from '@/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'livingPreviewer',
  props: ['component'],
  data() {
    return {
      showTitle:true,
      showCourse: [],
      course_list: [],
      isLoading:true,
      defaultList: [
        {
          id: Math.random(),
          coverpic:
            'https://img2.baidu.com/it/u=1361506290,4036378790&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500',
          name: '此处显示课程名称此处显示课程名称此处显示课程名称此处显示课程名称此处显示课程名称此处显示课程名称',
          create_time: '文件大小:20M'
        },
        {
          id: Math.random(),
          coverpic:
            'https://img2.baidu.com/it/u=1361506290,4036378790&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500',
          name: '此处显示课程名称',
          create_time: '文件大小:20M'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      org_id:'user/getOrgId'
    }),
    childStyle() {
      if (this.component.bg_type === '2') {
        console.log(this.component.bg_value)
        return {
          backgroundColor: this.component.bg_value
        }
      } else {
        return ''
      }
    },
    arrange_type() {
      return this.component.arrange_type
    },
    label() {
      return this.component.label
    },
    title() {
      return this.component.title
    },
    course_title_arrange() {
      return this.component.course_title_arrange
    },
    show_course_label() {
      return this.component.show_course_label
    },
    titleFontSize() {
      return {
        fontSize: this.component.title_font_size + 'px'
      }
    },
    titleFontWeight() {
      return {
        fontWeight: this.component.font_weight === '1' ? 400 : 700,
        height: this.component.title_font_size >= 14 ? '39px' : '34px'
      }
    },
    gridRowGap() {
      return {
        gap: this.component.margin_bottom + 'px'
      }
    },
    marginBottom() {
      return {
        marginBottom: this.component.margin_bottom + 'px'
      }
    },
    courseShowType() {
      return this.component.course_show_type
    },
    courseShowNum() {
      return this.component.course_show_num
    },
    showCheckMore() {
      if (
        this.courseShowType === '1' &&
        this.component.course_list.length < 51
      ) {
        return false
      } else if (
        this.courseShowType === '1' &&
        this.component.course_list.length > 51
      ) {
        return true
      } else if (
        this.courseShowType === '2' &&
        this.component.course_list.length > this.course_list.length
      ) {
        return true
      } else {
        return false
      }
    },
    btnText() {
      return function (item) {
        if (!item) return '查看详情'
        if (item.live_type === '5') return '立即购买'
        if (item.direct_status === 'not_started' || item.status === 1) {
            return '直播未开始'
        }
        if (item.direct_status === 'running' || item.status === 2 || item.isLiving === 2) {
            return '正在直播'
        }
        if (item.direct_status === 'end' || item.status === 3) {
            return '直播结束'
        }
        return '查看详情'
      }
    },
    btnColor() {
      return function (item) {
        if (!item) return ''
        if (item.live_type === '5') return ''
        if (item.isLiving === 2) {
          return ''
        }
        if (item.direct_status === 'not_started' || item.status === 1) {
          return 'linear-gradient(125deg,#78d4b6 7%, #0bb67e 92%)'
        }
        if (item.direct_status === 'running' || item.status === 2) {
          return ''
        }
        if (item.direct_status === 'end' || item.status === 3) {
          return '#C5C5C5'
        }
        return ''
      }
    }
  },
  mounted() {
    this.initList()
    this.getLivingStatus()
    setTimeout(() => {
            this.isLoading = false;
        }, 1000);
    // this.component.course_list.forEach((item, index) => {
    //         this.showCourse[index] = false; // 初始状态为 false
    //         this.showCom(item, index)
    //     });
  },  
  methods: {
    initList() {
      let list = []
      if (
        this.courseShowType === '1' &&
        this.component.course_list.length < 51
      ) {
        list = this.component.course_list
      } else if (
        this.courseShowType === '1' &&
        this.component.course_list.length > 51
      ) {
        list = this.component.course_list.slice(0, 50)
      } else {
        list = this.component.course_list.slice(0, this.courseShowNum)
      }
      if (list.length) {
        this.course_list = list.map(item => {
          return {
            ...item,
            isLiving: 1
          }
        })
      } else {
        this.course_list= this.defaultList
      }
    },  
    gotoDetail({ isLive, id, ds_id, courseType, live_type, course_id }) {
      gotoPageByConfig({
        id,
        type: courseType,
        ds_id,
        isLive,
        course_id,
        live_type,
        detail_id: ds_id || id
      })
    },
    toCoursePage() {
      const newList = this.component.course_list.map(item => {
        return { isLiving: 1, ...item, }
      })
      this.$store.dispatch('course/setSubTitle', this.title)
      this.$store.dispatch('course/setType', this.arrange_type)
      this.$store.dispatch('course/setClassNameTye', this.course_title_arrange)
      this.$store.dispatch('course/setClassTagType', this.show_course_label)
      this.$store.dispatch('course/setClassTagName', this.label)
      this.$store.dispatch('course/setCourseList', newList)
      this.$router.push({ name: 'MoreCourse' })
    },
    /**
     * 描述：获取直播状态
    **/
    getLivingStatus() {
        this.course_list.forEach(async({ live_type, id, ds_id }) => {
            let options = {
                org_id: this.org_id,
                ids: JSON.stringify([id || ds_id])
            }
            // live_type: 1：直播 2：班课直播间 3：伪直播  4：班课课程 5：商品
            // type: 1 直播 2 伪直播 3 课程
            if(live_type === '1' || live_type === '2') {
                options.type = 1
            } else if(live_type === '3') {
                options.type = 2
            }
            const { data: { code, data:resData } } = await this.$api.post('/stu/getLiveStatus', options)
            // const { data: { code, data:resData } } = await this.$api.post('https://online.tenghuiketang.com/onlineapi/stu/getLiveStatus', options)
            if(code === 200) {
                this.updateLiveItem(resData)
            }
        })
    },
    updateLiveItem(data) {
      for(const attr in data) {
          const updateItem = this.course_list.find(item => item.id === +attr || item.ds_id === +attr)
          updateItem.isLiving = data[attr]
      }
    },
    showCom(item, index) {
        let paramsNew = {};

        if (item.live_type == 1 || item.live_type == 2) {
            paramsNew = {
            id: item.ds_id,
            type: 1
            };
        } else if (item.live_type == 3) {
            paramsNew = {
            id: item.id,
            type: 3
            };
        } else if (item.live_type == 4) {
            paramsNew = {
            id: item.id,
            type: 4
            };
        } else {
            this.$set(this.showCourse, index, true)
            return
        }

        this.$liveApi.get('/liveapi/customer/page/checkMsg', { params: paramsNew })
            .then(response => {
                if (response.data.code == 200) {
                this.$set(this.showCourse, index, response.data.data == 2)
                } else {
                this.$set(this.showCourse, index, false)
                }
                const allFalse = this.showCourse.every(value => value == false);
                if (allFalse) {
                    this.showTitle=false
                }
                
            })
            .catch(error => {
                this.$set(this.showCourse, index, false)
            });
        }

  },
  watch: {
        component: {
            handler(newValue, oldValue) {
                this.showCourse=[]
                newValue.course_list.forEach((item, index) => {
                    this.showCourse[index] = true; // 初始状态为 false
                    this.showCom(item, index)
                });
                
            },
            deep: true, // 监听对象内部的变化
            immediate: true, // 立即执行一次
        },
  },
}
</script>

<style lang="less" scoped>
.padding_top {
  padding-top: 10px;
}
.component-container {
  padding-left: 12.5px;
  padding-right: 12.5px;
}
.living-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .title {
    text-align: center;
    position: relative;
    color: #1872ed;
    font-weight: 600;
    font-size: 16px;
    padding: 10px 0;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: calc(50% - 16px);
        width: 32px;
        height: 4px;
        background: #1872ed;
        border-radius: 10px;
    }
  }
  // .title-line {
  //   position: absolute;
  //   width: 90%;
  //   height: 4px;
  //   left: 5%;
  //   bottom: 3px;
  //   opacity: 0.2;
  //   background: linear-gradient(116deg, #0053ff, #73aaff 83%);
  // }
  .check-more {
    margin-left: auto;
    display: flex;
    align-items: center;
    color: #60667e;
    font-size: 13px;
    .right {
      color: #60667e;
      margin-left: 6px;
    }
  }
}
.living-body-small .course-item .cover, .living-body-large .course-item .cover{
  .live-status {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 56px;
    height: 18px;
    top: 0;
    left: 0;
    background: url(../../../assets/images/mofang/live_status_bg.png) no-repeat;
    background-size: 100% 100%;
    font-size: 12px;
    color: #ffffff;
    img {
      width: 10px;
      margin-right: 4px;
    }
  }
}
.living-body-small {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .course-item {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 1px 2px 10px #ddd;
    .cover {
      position: relative;
      width: 100%;
      height: 80px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .course-body {
      box-sizing: border-box;
      padding: 0 8px;
      padding-top: 10px;
      background: #fff;
      .course-title {
        margin-bottom: 10px;
        max-width: 150px;
      }
      .course-title.one-line {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .course-title.two-line {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal !important;
        word-wrap: break-word;
      }
      .open-time {
        font-size: 12px;
        color: #a3a3a3;
        margin-bottom: 10px;
      }
      .label {
          font-size: 12px;
          text-align: left;
          color: #f33f27;
      }
      .line {
        width: 100%;
        height: 1px;
        background: #f4f6f8;
        margin-left: -8px;
        margin-right: -8px;
      }
      .course-item-bottom {
        display: flex;
        align-items: center;
        padding: 10px 0;
        .price {
            font-size: 12px;
            color: #f33f27;
        }
        .check-btn {
          width: 78px;
          height: 22px;
          background: linear-gradient(125deg, #73aaff 7%, #0053ff 92%);
          border-radius: 11px;
          text-align: center;
          color: #fff;
          font-size: 12px;
          line-height: 22px;
          margin-left: auto;
        }
      }
    }
  }
}
.living-body-large {
  .course-item {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 1px 2px 10px #ddd;
    .cover {
      position: relative;
      width: 100%;
      height: 176px;
      img {
        width: 100%;
        height: 100%;
      }
      .label {
        font-size: 12px;
        padding: 4px 8px;
        text-align: left;
        color: #fff;
        position: absolute;
        bottom: 8px;
        right: 8px;
        span {
          position: relative;
          z-index: 10;
        }
      }
      .label-cover {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #202020;
        position: absolute;
        opacity: 0.5;
        z-index: 1;
        border-radius: 4px;
      }
    }
    .course-body {
      box-sizing: border-box;
      padding: 0 8px;
      padding-top: 10px;
      background: #fff;
      .course-title {
        margin-bottom: 10px;
        width: 100%;
      }
      .course-title.one-line {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .course-title.two-line {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal !important;
        word-wrap: break-word;
      }
      .line {
        width: 100%;
        height: 1px;
        background: #f4f6f8;
        margin-left: -8px;
        margin-right: -8px;
      }
      .open-time {
          font-size: 12px;
          color: #a3a3a3;
      }
      .label {
          font-size: 12px;
          text-align: left;
          color: #f33f27;
          margin-top: 5px;
      }
      .course-item-bottom {
        display: flex;
        align-items: center;
        padding: 10px 0;
        .price {
          color: #f33f27;
          font-size: 12px;
        }
        .open-time {
          font-size: 12px;
          color: #a3a3a3;
        }
        .check-btn {
          width: 78px;
          height: 22px;
          background: linear-gradient(125deg, #73aaff 7%, #0053ff 92%);
          border-radius: 11px;
          text-align: center;
          color: #fff;
          font-size: 12px;
          line-height: 22px;
          margin-left: auto;
        }
      }
    }
  }
  .course-item:last-child {
    margin-bottom: 0 !important;
  }
}
.look-more {
  margin-top: 10px;
  .btn {
    width: 120px;
    height: 32px;
    border: 1px solid #0f72f9;
    border-radius: 16px;
    text-align: center;
    color: #0f72f9;
    line-height: 32px;
    margin: auto;
    font-size: 12px;
    i {
      margin-left: -3px;
    }
  }
}
</style>
