var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tit"},[_vm._m(0),_c('div',{staticClass:"course",staticStyle:{"height":"800px"}},[_c('div',{staticClass:"one"},[_c('img',{attrs:{"src":require("../../assets/images/2.png"),"alt":""}}),_c('p',[_vm._v("LOGO造型基础与设计语言")]),_vm._m(1),_c('router-link',{attrs:{"to":{path:'zhibozhonga',query:{setid:1231231}}}},[_c('div',[_c('a',{staticClass:"f_a",attrs:{"href":"#"}},[_vm._v("进入直播")])])])],1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit tit_fh"},[_c('div',{staticClass:"img",staticStyle:{"float":"left","width":"22px","height":"22px","padding":"15px 22px 0"}},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../../assets/images/ico_fanhui.png"),"alt":""}})])]),_c('h1',{staticStyle:{"width":"85px","margin":"-10px 160px"}},[_vm._v("直播公开课")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"f",staticStyle:{"margin-top":"30px","color":"#10a675"}},[_c('img',{staticStyle:{"margin-top":"-3px"},attrs:{"src":require("../../assets/images/ico_bofangzhong.png"),"alt":""}}),_vm._v(" 直播中 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one"},[_c('img',{attrs:{"src":require("../../assets/images/2.png"),"alt":""}}),_c('p',[_vm._v("LOGO造型基础与设计语言")]),_c('span',{staticClass:"f",staticStyle:{"margin-top":"30px","color":"#10a675"}},[_c('img',{staticStyle:{"margin-top":"-3px"},attrs:{"src":require("../../assets/images/ico_bofangzhong.png"),"alt":""}}),_vm._v(" 直播中 ")]),_c('div',{staticClass:"f_d"},[_c('a',{staticClass:"f_a",attrs:{"href":"#"}},[_vm._v("进入直播")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one"},[_c('img',{attrs:{"src":require("../../assets/images/2.png"),"alt":""}}),_c('p',[_vm._v("LOGO造型基础与设计语言")]),_c('span',{staticStyle:{"color":"#10a675"}},[_vm._v("10月12日 20:00 直播")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one"},[_c('img',{attrs:{"src":require("../../assets/images/2.png"),"alt":""}}),_c('p',[_vm._v("LOGO造型基础与设计语言")]),_c('span',{staticStyle:{"color":"#10a675"}},[_vm._v("10月12日 20:00 直播")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one"},[_c('img',{attrs:{"src":require("../../assets/images/2.png"),"alt":""}}),_c('p',[_vm._v("LOGO造型基础与设计语言")]),_c('span',{staticStyle:{"color":"#10a675"}},[_vm._v("10月12日 20:00 直播")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one"},[_c('img',{attrs:{"src":require("../../assets/images/2.png"),"alt":""}}),_c('p',[_vm._v("LOGO造型基础与设计语言")]),_c('span',{staticStyle:{"color":"#10a675"}},[_vm._v("10月12日 20:00 直播")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one"},[_c('img',{attrs:{"src":require("../../assets/images/2.png"),"alt":""}}),_c('p',[_vm._v("LOGO造型基础与设计语言")]),_c('span',{staticStyle:{"color":"#10a675"}},[_vm._v("10月12日 20:00 直播")])])
}]

export { render, staticRenderFns }