<template>
    <div class="padding_top component-container">
        <div class="img-text-wrap" :style="[childStyle]">
            <!-- <div v-for="item of component.nav_item" :key="item.uid"  :class="['img-text-item', component.arrange_type === '1' ? 'item-4' : 'item-5']">
                <img
                    v-if="item.img_url"
                    :src="item.img_url"
                    :style="[iconSize]"
                    :class="component.arrange_type === '1'?'four':'five'"
                    @click="gotoDetail(item)" />
                <img
                    v-else
                    src="@/assets/images/mofang/img-pre-small.png"
                    :style="[iconSize]"
                    @click="gotoDetail(item)"
                    :class="component.arrange_type === '1'?'four':'five'" />
                <div :style="[textFontSize,textColor,textFontWeight]">{{ item.text }}</div>
            </div> -->
            <div class="row-container" v-for="row in textNavList">
                <div v-for="item of row" :key="item.uid" class="img-text-item">
                    <img
                        v-if="item.img_url"
                        :src="item.img_url"
                        :style="[iconSize]"
                        @click="gotoDetail(item)" />
                    <img
                        v-else
                        src="@/assets/images/mofang/img-pre-small.png"
                        :style="[iconSize]"
                        @click="gotoDetail(item)"
                    />
                    <div :style="[textFontSize,textColor,textFontWeight]">{{ item.text }}</div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import { gotoPageByConfig } from '@/utils'

export default {
    name: 'imageTextNavPreviewer',
    props: ['component'],
    data() {
        return {
            textNavList: []
        }
    },
    computed: {
        img_url(){
            return this.component.img_url
        },
        textFontSize(){
            return {
                fontSize:this.component.font_size + 'px',
            }
        },
        textColor(){
            return {
                color:this.component.font_color
            }
        },
        textFontWeight(){
            return {
                fontWeight:this.component.font_weight === '1' ? 400 : 700
            }
        },
        iconSize(){
            if(this.component.icon_size === '2' && this.component.arrange_type === '1'){
                return {
                    width: '36px',
                    height: '36px'
                }
            }else if(this.component.icon_size === '2' && this.component.arrange_type === '2'){
                return {
                    width:'34px',
                    height:'34px'
                }
            }else if(this.component.icon_size === '1' && this.component.arrange_type === '1'){
                return {
                    width:'45px',
                    height:'45px'
                }
            }else if(this.component.icon_size === '1' && this.component.arrange_type === '2'){
                return {
                    width:'40px',
                    height:'40px'
                }
            }
            return ''
        },
        childStyle() {
            if(this.component.bg_type === '2'){
                console.log(this.component.bg_value)
                return {
                    backgroundColor: this.component.bg_value
                }
            }else{
                return ''
            }

        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        initData() {
            // textNavList
            // this.component.nav_item
            // component.arrange_type === '1' ? 'item-4
            let rowData = []
            const rowSize = this.component.arrange_type === '1' ? 4 : 5
            this.component.nav_item.map((item, index) => {
                rowData.push(item)
                if((index + 1) % rowSize === 0) {
                    this.textNavList.push(rowData)
                    rowData = []
                }
            })
            console.log('this.textNavList: ', this.textNavList);
        },
        gotoDetail({ jump_info }) {
            const { type, isLive, live_type, content: { id, ds_id, link, type: material_type, url } } = jump_info
            gotoPageByConfig({
                id,
                type,
                ds_id,
                isLive,
                live_type,
                url:type === '3' ? url : link,
                detail_id: ds_id || id,
                material_type
            })
        }
    }
}
</script>
<style lang='scss' scoped>
.padding_top {
    padding-top: 10px;
}
.component-container {
    // padding-left: 12.5px;
    // padding-right: 12.5px;
}
.img-text-wrap {
    // display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 19.5px 16.5px 18.5px 17.5px;
    margin: 0 10px;
    border-radius: 10px;
    .row-container {
        display: flex;
        justify-content: space-between;
    }
    .img-text-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 50px;
        margin-top: 5px;
        &.item-4 {
            margin-right: 40px;
            &:nth-child(4n) {
                margin-right: 0;
            }
        }
        &.item-5 {
            margin-right: 30px;
            &:nth-child(5n) {
                margin-right: 0;
            }
        }
        img {
            width: 45px;
            height: 45px;
            margin-bottom: 10px;
        }
        .four {
            width: 45px;
            height: 45px;
        }
        .five {
            width: 36px;
            height: 36px;
        }
        div {
            width: 50px;
            text-align: center;
            color: #333;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

</style>
