<template>
    <div class="box">
        <div class="shibait" v-if="status==2">
            <img src="../../../assets/images/rightchengg.png" alt="">
                <p class="renzhengsb">认证成功</p>
                <!-- <p class="shibaiyy">即将自动跳转...</p> -->
        </div>
        <div class="shibait" v-if="status==3">
            <img src="../../../assets/images/smrzsb.png" alt="">
                <p class="renzhengsb">认证失败</p>
                <p class="shibaiyy">失败原因：姓名与手机号实名信息不匹配</p>
        </div>
    </div>
</template>
<script>
export default {
    name:"Face",
    data() {
        return {
             status :1
        }
    },
    created() {
    this.companyIdentify(this.$route.query.id,this.$route.query.org_id)
    //    if (this.status!==2&&this.status!==3) {
    //         const id=this.$route.query.id
    //         const org_id=this.$route.query.org_id
    //         this.face(id,org_id)
    //     }
        
    },
    mounted() {
    },
    methods: {
        companyIdentify(id,org_id) {
            this.$api.post('/contract/queryStatusNew', {
                id: id,
                org_id:org_id
            }).then((res) => {
                console.log(res);
                if (res.data.code == 200) {
                    console.log(11111111111111);
                    if (res.data.data.status === 2) {
                        this.status = 2
                    } else if (res.data.data.status === 3) {
                        this.status = 3
                    }else{
                        this.face(id,org_id)
                    }
                }
            })
        },
        face(id,org_id){
             this.$api.post('/covenant/obtainFace', {
                    id: id,
                    org_id: org_id   
                }).then((res) => {
                    if (res.data.code == 200) {
                        window.location.href = res.data.data.face
                    } else {
                    }
                })
        }
    }
}
</script>
<style>
.box{
    display: flex;
    align-items: center;
    justify-content: center;
}
.tuichurz {
    font-size: 16px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    text-align: center;
    color: #666666;
    width: 343px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    line-height: 42px;
    margin: 0 auto;
    margin-top: 11px;
}
.zaishiyici {
    width: 343px;
    height: 42px;
    background: #1872ed;
    border-radius: 4px;
    margin: 0 auto;
    font-size: 16px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 42px;
    margin-top: 21px;
}
.zhengshixm {
    font-size: 12px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #666666;
    display: flex;
    justify-content: space-between;
}
.shibaixx {
    width: 343px;
    height: 107px;
    background: #f7faff;
    border-radius: 2px;
    margin: 0 auto;
    margin-top: 34px;
}
.shibaiyy {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #999999;
    margin-top: 8px;
}
.renzhengsb {
    font-size: 20px;
    font-family: Noto Sans SC, Noto Sans SC-500;
    font-weight: 600;
    color: #202020;
    margin-top: 16px;
}
.shibait {
    text-align: center;
    margin-top: 100px;

}
* {
    margin: 0;
    padding: 0;
}
</style>