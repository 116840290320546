<template>
    <div>
        <div class="beijing">
            <div style="display: flex;">
                <van-icon class="fanhui" name="arrow-left" color="#fff" size="28" @click="fanhui" />
                <div class="hetongguanl">合同管理</div>
            </div>
            <div class="qiandingshu">
                <router-link :to="{path:'Allcontracts',query:{setid:1}}">
                    <div class="qianzijz">
                        <div class="shuliang">{{suoyuhtlsit.treat_count}}</div>
                        <div class="zhuangtai">待我签 </div>
                    </div>
                </router-link>
                <router-link :to="{path:'Allcontracts',query:{setid:2}}">
                    <div class="qianzijz">
                        <div class="shuliang">{{suoyuhtlsit.complete_count}}</div>
                        <div class="zhuangtai">已完成</div>
                    </div>
                </router-link>
                <router-link :to="{path:'Allcontracts',query:{setid:3}}">
                    <div class="qianzijz">
                        <div class="shuliang">{{suoyuhtlsit.whole_count}}</div>
                        <div class="zhuangtai">全部</div>
                    </div>
                </router-link>
            </div>
            <div class="dqswj">
                <p class="daiqianlist" style="margin-top: 16px;">待签署文件</p>
                <router-link :to="{path:'Allcontracts',query:{setid:123123}}">
                   <p class="daiqianlist" style="margin-top: 16px;">查看全部</p>
                </router-link>
            </div>
            <div class="wenjianhezi">
                    <div class="wenjiano" v-for="(item,index) in suoyuhtlsit.contract" :key="index">
                        <router-link :to="{path:'FileDetails',query:{id:item.c_id,sign_id: item.id,shuzulist: item}}">
                            <div class="hetongbh">
                                <p class="shijianbh" style="margin-top: 10px;margin-left: 10px;">合同编号：{{item.number}}</p>
                                <p class="shijianbh" style="margin-top: 10px;margin-right: 10px;">{{item.create_time}}</p>
                            </div>
                            <div class="gekaixian"></div>
                            <div class="xieyilist">
                            <p class="shanghai">{{item.name}}</p>
                            <div class="shdaita">待我签</div>
                            </div>
                            <p class="makabaka" style="margin-top: 8px;margin-left: 10px;">发起人：{{item.real_name}}</p>
                            <p class="makabaka" style="margin-top: 8px;margin-left: 10px;padding-bottom: 16px;">接收人：{{item.nick_name}}</p>
                        </router-link>
                    </div>
            </div>
        </div>
        <van-overlay :show="show" :duration='0'>
            <div class="wrapper" @click.stop>
                <div class="block">
                    <router-link :to="{path:'Personalauthentication',query:{setid:123123}}">
                        <p class="fayuyq">根据法律要求，合法合规的合同签署需申请数字证书，请您先进行实名认证。</p>
                        <div class="renzhengan">好的，去认证</div>
                    </router-link>
                </div>
                <!-- <img class="quxiao" @click="show = false" src="../../assets/images/quxiaoan.png" alt=""> -->
            </div>
        </van-overlay>
    </div>
</template>
<script>
export default {
    name: "contraCtmanagement",
    data() {
        return {
            show: false,
            suoyuhtlsit: [],
            authentication: 1
        }
    },
    created () {
        document.title = ''
        this.contractList()
        this.getUserInfo()
    },
    mounted() {
        setTimeout(() => {
            document.title = ''
        }, 1000)
    },
    methods: {
        async getUserInfo(){
            // 查询用户实名
            const res = await this.$api.post('/user/index/getUserInfo')
            if(res.data.code === 200){
                // this.authentication = res.data.data.authentication
                if(res.data.data.authentication == 2) {
                    this.show = false
                } else {
                    this.show = true
                }
            }
        },
        contractList(){
            const data = {
                // token: 'wx_35a7610c10d7b1ccaca6f560745f88e6_2_1491_22506957',
                status: 1
            }
            this.$api.post('/user/app/contractList', data)
                .then(res=>{
                    if(res.data.code == 200) {
                        // console.log('333333-------', res.data.data)
                        this.suoyuhtlsit = res.data.data
                    }
                })
        },
        fanhui() {
            this.$router.push({
                    path: "/my"
                })
        }
    }
}
</script>
<style scoped>
.quxiao {
    position: absolute;
    top: 490px;
}
.renzhengan {
    width: 176px;
    height: 44px;
    font-size: 15px;
    font-family: Noto Sans SC, Noto Sans SC-500;
    font-weight: 500;
    text-align: center;
    line-height: 44px;
    color: #ffffff;
    background: #1872ed;
    border-radius: 24px;
    margin: 0 auto;
    box-shadow: 0px 6px 14px 0px rgba(255,255,255,0.30) inset, 0px -6px 14px 0px rgba(255,255,255,0.30) inset, 0px 5px 8px 0px rgba(2,90,211,0.16); 
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;
  }
.fayuyq {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    text-align: center;
    color: #202020;
    margin: 0 21px;
    margin-top: 122.13px;
    margin-bottom: 40px;
}
  .block {
    width: 318px;
    height: 271px;
    /* background-color: #fff; */
    background-image: url(../../assets/images/renzhengtk.png);
    background-size: 100% 100%;
  }
.wenjianhezi {
    background: rgb(246, 246, 246);
}
.makabaka {
    font-size: 12px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
}
.shdaita {
    width: 40px;
    height: 18px;
    border: 0.5px solid #1872ed;
    border-radius: 2px;
    text-align: center;
    line-height: 18px;
    font-size: 10px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #1872ed;
    margin-top: 10px;
    margin-left: 6px;
    }
.shanghai {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #202020;
    margin-top: 10px;
    margin-left: 10px;
}
.xieyilist {
    display: flex;
}
.gekaixian {
    width: 100%;
    height: 0px;
    border: 0.6px solid #f0f4f8;
    margin-top: 10px;
}
.shijianbh {
    font-size: 10px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #999999;
}
.hetongbh {
    display: flex;
    justify-content: space-between;
}
.wenjiano {
    /* width: 343px; */
    background: #ffffff;
    border-radius: 10px;
    margin: 0 16px;
    margin-top: 12px;
}
.daiqianlist {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #666666;
}
.dqswj {
    /* width: 343px; */
    margin: 0 16px;
    display: flex;
    justify-content: space-between;
}
* {
    margin: 0;
    padding: 0;
}
.qianzijz {
    text-align: center;
}
.zhuangtai {
    font-size: 14px;
    font-family: Noto Sans SC, Noto Sans SC-400;
    font-weight: 400;
    color: #202020;
    margin-top: 8px;
}
.shuliang {
    font-size: 22px;
    font-family: Noto Sans SC, Noto Sans SC-700;
    font-weight: 700;
    color: #202020;
}
.qiandingshu {
    height: 118px;
    background: #ffffff;
    border-radius: 10px;
    margin: 0 16px;
    margin-top: 28px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.hetongguanl {
    font-size: 17px;
    font-family: PingFang SC, PingFang SC-700;
    font-weight: 700;
    color: #ffffff;
    margin-top: 29px;
    margin-left: 118px;
}
.fanhui {
    margin-left: 17px;
    margin-top: 29px;
}
.beijing {
    background: rgb(246, 246, 246);
    width: 100%;
    height: 812px;
    background-image: url(../../assets/images/htbeijing.png);
    background-size: 100% 100%;
}
</style>