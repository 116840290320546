/**
 * 描述：合同相关缓存数据
**/
const state = {
    widgetSetting: localStorage.getItem('widgetSetting') ? JSON.parse(localStorage.getItem('widgetSetting')) : [],
    contractImgs: []
}

const getters = {
    getWidgetSetting() {
        return state.widgetSetting
    },
    geContractImgs() {
        return state.contractImgs
    }
}

const actions = {
    setWidgetSetting: ({ commit }, value) => {
        commit('SET_WIDGET_SETTING', value)
    },
    setContractImgs: ({ commit }, value) => {
        commit('SET_CONTRACT_IMGS', value)
    }
}

const mutations = {
    'SET_WIDGET_SETTING': (state, val) => {
        state.widgetSetting = val
        localStorage.setItem('widgetSetting', JSON.stringify(val))
    },
    'SET_CONTRACT_IMGS': (state, val) => {
        state.contractImgs = val
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}