<template>
  <div class="downloadApp">
    <div class="tips">
      <span>该课程仅支持app以及客户端查看，请选择下方观看方式扫码进行下载观看</span>
    </div>
    <div class="download_tips">
      <span>请扫描下方二维码下载</span>
    </div>
    <div class="ewm_box">
      <div>
        <div class="ewm">
          <img src="../assets/img/An1.png" alt="">
        </div>
        <span>安卓下载链接</span>
      </div>
      <div>
        <div class="ewm">
          <img src="../assets/img/An2.png" alt="">
        </div>
        <span>安卓备用链接</span>
      </div>
      <div>
        <div class="ewm">
          <img src="../assets/img/ios.png" alt="">
        </div>
        <span>iOS下载链接</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name:"downloadApp",
      data(){
        return{
          
        }

      }
  }
</script>
<style lang="scss" scoped>
.downloadApp{
  height: 100%;
  background: url(../assets/img/downLoad_bg.png) no-repeat;
  background-size: 100% 100%;
  padding: 0 40px;
  .tips{
    margin: 0 auto;
    margin-top: 40px;
    width: 270px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #202020;
  }
  .download_tips{
    width: 140px;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #434755;
    margin: 60px auto 20px;
  }
  .ewm_box{
    display: flex;
    justify-content: space-between;
    text-align: center;
    .ewm{
      width: 64px;
      border-radius: 8px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>